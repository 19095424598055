import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'c-popup-container',
  templateUrl: './popup-container.component.html',
  styleUrls: ['./popup-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupContainerComponent {
  @Input() width = '450px';
}
