import { createReducer, on } from '@ngrx/store';
import * as fromActions from './trip-editor-itinerary.actions';
import { GetCharterTripItineraryResponse } from '@rootTypes/api';
import { WpError } from '@rootTypes';
import { createNewTripSuccess, initializeNewTrip } from '../create-trip/create-trip.actions';
import { clearStore } from '../../../../store/common/clear-store.actions';

export interface TripEditorItineraryState {
  isLoading: boolean;
  data?: GetCharterTripItineraryResponse;
  error?: WpError;
}

const createInitialState = (): TripEditorItineraryState => {
  return { isLoading: false };
};

export const tripEditorItineraryReducer = createReducer(
  createInitialState(),
  on(initializeNewTrip, createNewTripSuccess, clearStore, createInitialState),
  on(fromActions.tripEditorGetTripItineraryRequested, (): TripEditorItineraryState => {
    return { isLoading: true };
  }),
  on(fromActions.tripEditorGetTripItinerarySuccess, (state, { response }): TripEditorItineraryState => {
    return {
      isLoading: false,
      data: response,
    };
  }),
  on(fromActions.tripEditorGetTripItineraryFailed, (state, { error }): TripEditorItineraryState => {
    return {
      isLoading: false,
      error,
    };
  }),
);
