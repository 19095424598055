import { TripEditorLeg, TripEditorLegStop } from '../entities';
import { generateTripEditorStopId } from './generate-trip-editor-stop-id';
import { isTripEditorStopValid } from './is-trip-editor-stop-valid';

export const addTripEditorStop = (leg: TripEditorLeg): TripEditorLeg => {
  const newLeg: TripEditorLeg = {
    legName: leg.legName,
    isReturnLeg: leg.isReturnLeg,
    isReadyForDirectionRequest: false,
    summary: { isLoading: false },
    stops: {},
  };

  Object.values(leg.stops).forEach((stop) => {
    if (!stop.isLast) {
      newLeg.stops[stop.stopId] = stop;
      return;
    }

    const newStop: TripEditorLegStop = {
      stopId: generateTripEditorStopId(),
      stopIndex: stop.stopIndex,
      isFirst: false,
      isLast: false,
      isValid: false,
      data: {
        address: null,
        arrivalDate: null,
        arrivalTime: null,
        departureTime: null,
      },
    };
    newLeg.stops[newStop.stopId] = newStop;

    const lastStop: TripEditorLegStop = {
      stopId: stop.stopId,
      stopIndex: stop.stopIndex + 1,
      isFirst: false,
      isLast: true,
      isValid: undefined,
      data: {
        address: stop.data.address || null,
        arrivalDate: null,
        arrivalTime: null,
        departureTime: null,
      },
    };
    lastStop.isValid = isTripEditorStopValid(lastStop);
    newLeg.stops[lastStop.stopId] = lastStop;
  });

  return newLeg;
};
