import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'distanceMiles',
})
export class DistanceMilesPipe implements PipeTransform {
  public transform(distanceMiles?: number): string {
    if (distanceMiles) {
      return `${distanceMiles} mi`;
    }
    return '--';
  }
}
