import { createAction, props } from '@ngrx/store';
import {
  GetCharterCatalogResponse,
  GetCharterContractRequest,
  GetCharterContractResponse,
  GetCharterRideResponse,
  GetCharterTripInvoiceRequest,
  GetCharterTripInvoiceResponse,
  GetCharterTripItineraryResponse,
  GetCharterTripResponse,
  UpdateCharterTripDetailsRequest,
  UpdateCharterTripQuoteRequest,
} from '@rootTypes/api';
import { WpError } from '@rootTypes';
import { GetCharterRideLoadOptions, GetCharterTripLoadOptions, GetTripItineraryLoadOptions } from './types';
import { Driver } from '@rootTypes/entities/driver';
import { Vehicle } from '@rootTypes/entities/vehicles';
import { CancelTripFormValue } from '../../features/trip-details/models/cancel-trip-form';

/**
 * Get charter trip
 */
export const getCharterTripRequested = createAction(
  '[TripData] API: getCharterTrip requested',
  props<{ tripId: string; options?: GetCharterTripLoadOptions }>(),
);
export const getCharterTripSuccess = createAction(
  '[TripData] API: getCharterTrip success',
  props<{ trip: GetCharterTripResponse }>(),
);
export const getCharterTripFailed = createAction(
  '[TripData] API: getCharterTrip failed',
  props<{ tripId: string; error: WpError }>(),
);
/**
 * Update charter trip details
 */
export const updateCharterTripDetailsRequested = createAction(
  '[TripDetails] API: updateCharterTripDetails requested',
  props<{ request: UpdateCharterTripDetailsRequest }>(),
);
export const updateCharterTripDetailsSuccess = createAction(
  '[TripDetails] API: updateCharterTripDetails success',
  props<{ trip: GetCharterTripResponse }>(),
);
export const updateCharterTripDetailsFailed = createAction(
  '[TripDetails] API: updateCharterTripDetails failed',
  props<{ error: WpError }>(),
);

/**
 * Get charter ride
 */
export const getCharterRideRequested = createAction(
  '[TripData] API: getCharterRide requested',
  props<{ charterRideId: string; options?: GetCharterRideLoadOptions }>(),
);
export const getCharterRideSuccess = createAction(
  '[TripData] API: getCharterRide success',
  props<{ charterRide: GetCharterRideResponse }>(),
);
export const getCharterRideFailed = createAction(
  '[TripData] API: getCharterRide failed',
  props<{ charterRideId: string; error: WpError }>(),
);

/**
 * Get charter itinerary
 */
export const getCharterTripItineraryRequested = createAction(
  '[TripData] API: getCharterTripItinerary requested',
  props<{ charterTripItineraryId: string; options?: GetTripItineraryLoadOptions }>(),
);
export const getCharterTripItinerarySuccess = createAction(
  '[TripData] API: getCharterTripItinerary success',
  props<{ charterTripItinerary: GetCharterTripItineraryResponse }>(),
);
export const getCharterTripItineraryFailed = createAction(
  '[TripData] API: getCharterTripItinerary failed',
  props<{ charterTripItineraryId: string; error: WpError }>(),
);

/**
 * Get charter catalog
 */
export const getCharterCatalogRequested = createAction(
  '[TripData] API: getCharterCatalog requested',
  props<{ catalogId: string }>(),
);
export const getCharterCatalogSuccess = createAction(
  '[TripData] API: getCharterCatalog success',
  props<{ catalogId: string; catalog: GetCharterCatalogResponse }>(),
);
export const getCharterCatalogFailed = createAction(
  '[TripData] API: getCharterCatalog failed',
  props<{ catalogId: string; error: WpError }>(),
);

/**
 * getDriver
 */
export const getDriverRequested = createAction('[TripData] API: getDriver requested', props<{ driverId: string }>());
export const getDriverSuccess = createAction(
  '[TripData] API: getDriver success',
  props<{ driverId: string; driver: Driver }>(),
);
export const getDriverFailed = createAction(
  '[TripData] API: getDriver failed',
  props<{ driverId: string; error: WpError }>(),
);

/**
 * getVehicle
 */
export const getVehicleRequested = createAction('[TripData] API: getVehicle requested', props<{ vehicleId: string }>());
export const getVehicleSuccess = createAction(
  '[TripData] API: getVehicle success',
  props<{ vehicleId: string; vehicle: Vehicle }>(),
);
export const getVehicleFailed = createAction(
  '[TripData] API: getVehicle failed',
  props<{ vehicleId: string; error: WpError }>(),
);

/**
 * getCharterTripInvoice
 */
export const getTripInvoiceRequested = createAction(
  '[TripData]: API: getCharterTripInvoice requested',
  props<{ request: GetCharterTripInvoiceRequest }>(),
);
export const getTripInvoiceSuccess = createAction(
  '[TripData]: API: getCharterTripInvoice success',
  props<{ tripId: string; invoice: GetCharterTripInvoiceResponse }>(),
);
export const getTripInvoiceFailed = createAction(
  '[TripData]: API: getCharterTripInvoice failed',
  props<{ tripId: string; error: WpError }>(),
);
/**
 * Update invoice
 */
export const updateTripQuoteRequested = createAction(
  '[TripDetails]: API: updateCharterTripQuote requested',
  props<{ request: UpdateCharterTripQuoteRequest }>(),
);
export const updateTripQuoteSuccess = createAction(
  '[TripDetails]: API: updateCharterTripQuote success',
  props<{ tripId: string; invoice: GetCharterTripInvoiceResponse }>(),
);
export const updateTripQuoteFailed = createAction(
  '[TripDetails]: API: updateCharterTripQuote failed',
  props<{ tripId: string; error: WpError }>(),
);

/**
 * getCharterContract
 */
export const getContractRequested = createAction(
  '[TripData]: API: getCharterContract requested',
  props<{ request: GetCharterContractRequest }>(),
);
export const getContractSuccess = createAction(
  '[TripData]: API: getCharterContract success',
  props<{ contractId: string; contract: GetCharterContractResponse }>(),
);
export const getContractFailed = createAction(
  '[TripData]: API: getCharterContract failed',
  props<{ contractId: string; error: WpError }>(),
);
