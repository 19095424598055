import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';

import { defaultSnackbarDurationMs } from './snackbar.module';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(private snackbar: MatSnackBar) {}

  public success(message: string, durationMs?: number): MatSnackBarRef<any> {
    return this.openSnackbar(message, 'wp-snackbar-success', durationMs);
  }

  public error(message: string, durationMs?: number): MatSnackBarRef<any> {
    return this.openSnackbar(message, 'wp-snackbar-error', durationMs);
  }

  public warn(message: string, durationMs?: number): MatSnackBarRef<any> {
    return this.openSnackbar(message, 'wp-snackbar-warn', durationMs);
  }

  public info(message: string, durationMs?: number): MatSnackBarRef<any> {
    return this.openSnackbar(message, '', durationMs);
  }

  // Dismisses the currently-visible snack bar.
  public dismiss(): void {
    this.snackbar.dismiss();
  }

  private openSnackbar(message: string, panelClass: string, durationMs?: number): MatSnackBarRef<any> {
    return this.snackbar.open(message, undefined, {
      panelClass, // see src/app/styles/material/_snackbar.scss
      duration: durationMs || defaultSnackbarDurationMs,
    });
  }
}
