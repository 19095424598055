import { createAction, props } from '@ngrx/store';
import { UserRole, WpError } from '@rootTypes';
import { GetAccountResponse } from '@rootTypes/api/get-account';
import { GetCharterConfigResponse } from '@rootTypes/api';

export const loginWithTokenRequested = createAction(
  '[Login]: Login with token requested',
  props<{ token: string; target?: string }>(),
);
export const loginTokenNotFound = createAction('[AuthEffects]: Login token not found');
export const loginWithTokenSuccess = createAction(
  '[AuthEffects]: Login with token success',
  props<{ userRole: UserRole; target?: string }>(),
);
export const loginWithTokenFailed = createAction('[AuthEffects]: Login with token failed', props<{ error: WpError }>());

export const checkAuthRequested = createAction('[HomeGuard]: Check auth requested');
export const checkAuthSuccess = createAction(
  '[AuthEffects]: Check auth success',
  props<{ account: GetAccountResponse }>(),
);
export const checkAuthFirebaseUserNotAuthorized = createAction(
  '[AuthEffects]: Firebase user is not authorized',
  props<{ error: WpError }>(),
);
export const checkAuthGetAccountFailed = createAction(
  '[AuthEffects]: Get user account failed',
  props<{ error: WpError }>(),
);

export const homeInitialized = createAction('[Home]: Initialized');
export const homeDestroyed = createAction('[Home]: Destroyed');

export const redirectToLoginOnRefreshSessionFailed = createAction(
  '[SessionService]: Redirect to login on refresh session failed',
);

export const getCharterConfigRequested = createAction('[AuthEffects]: API getCharterConfig request');
export const getCharterConfigSuccess = createAction(
  '[AuthEffects]: API getCharterConfig success',
  props<{ data: GetCharterConfigResponse }>(),
);
export const getCharterConfigFailed = createAction(
  '[AuthEffects]: API getCharterConfig failed',
  props<{ error: WpError }>(),
);
