import * as moment from 'moment-timezone';
import { BaseDate, TimeFormat, TimezoneString } from './base-date';
import { YYYYMMDDString } from './year-month-day';

/**
 * A number, VISUALLY resembling a unix timestamp (but not being the same thing), which encodes
 * a date, hours and minutes (seconds and millis are usually set to 0),
 *
 * These is NO information about timezone which this date and time belong to.
 *
 * Simply speaking, this value just encodes a string like "March 12, 2020 11:37PM" as a number (1584056220000).
 *
 * See @class DisplayDateTime
 */
export type DisplayDateTimeStamp = number;

export class DisplayDateTime extends BaseDate {
  private tz: TimezoneString;

  public static get startOfCurrentYear(): DisplayDateTimeStamp {
    const d = moment().startOf('year').toDate();
    return DisplayDateTime.fromDate(d);
  }

  public static get endOfCurrentYear(): DisplayDateTimeStamp {
    const d = moment().endOf('year').toDate();
    return DisplayDateTime.fromDate(d);
  }

  public static get userTimezone(): string {
    return moment.tz.guess(true);
  }

  public static fromDate(d: Date): DisplayDateTimeStamp {
    const target = new Date();
    target.setUTCFullYear(d.getFullYear(), d.getMonth(), d.getDate());
    target.setUTCHours(d.getHours(), d.getMinutes(), d.getSeconds(), d.getMilliseconds());
    return target.getTime();
  }

  constructor(value: DisplayDateTimeStamp, tz?: TimezoneString) {
    super(value, TimeFormat.DISPLAY_DATE_TIME);
    this.tz = tz || moment.tz.guess();
  }

  toMoment(tz: string = this.tz): moment.Moment {
    const utcDateTimeStr = moment.tz(this.value, 'Etc/UTC').utc().format('YYYY-MM-DD HH:mm:ss');
    return moment.tz(utcDateTimeStr, tz);
  }

  public getDisplayDateStr(format = 'MMMM D YYYY'): string {
    return this.toMoment().format(format);
  }

  public toUTCTimestamp(): number {
    return this.toMoment().utc().valueOf();
  }

  public toYearMonthDay(): YYYYMMDDString {
    return this.toMoment().format('YYYYMMDD');
  }

  public getDisplayTimeStr(format = 'h:mm A z'): string {
    return this.toMoment().format(format);
  }
}
