import { createAction, props } from '@ngrx/store';
import { PortalEntity, PortalEntityType, WpError } from '@rootTypes';

export const saveEntityData = createAction('[EntitiesData]: Save entity data', props<{ entity: PortalEntity }>());

export const getPortalEntityRequestedIfAbsent = createAction(
  '[EntitiesData]: API Get portal entity requested if absent',
  props<{ entityId: string; entityType: PortalEntityType }>(),
);

export const getPortalEntityError = createAction(
  '[EntitiesData]: API Get portal entity error',
  props<{ entityId: string; entityType: PortalEntityType; error: WpError }>(),
);
