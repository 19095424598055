import { Injectable } from '@angular/core';
import { QueryParamsHandling } from '@angular/router';
import { CharterRoute, NavigationRequest, RouterStateUrl } from '../types';
import { RouterService } from '../router-service';

export type UnauthorizedRouteData = {};

const routePath = 'unauthorized';

export const unauthorizedRoute: CharterRoute<UnauthorizedRouteData> = {
  path: routePath,
  isOnRoute(state: RouterStateUrl): boolean {
    return state.url.startsWith(`/${routePath}`);
  },
  parse(state: RouterStateUrl): UnauthorizedRouteData {
    return {};
  },
  request(
    data?: UnauthorizedRouteData,
    queryParamStrategy?: QueryParamsHandling,
    replaceUrl?: boolean,
  ): NavigationRequest {
    return {
      path: [`/${routePath}`],
    };
  },
  getHref(data?: UnauthorizedRouteData): string {
    return `/${routePath}`;
  },
};

@Injectable({ providedIn: 'root' })
export class UnauthorizedRouterService extends RouterService<UnauthorizedRouteData> {
  route = unauthorizedRoute;
}
