import { fromEvent, Observable, throttleTime } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';

const calculateMaxHeight = (): string => {
  const windowHeight = window.innerHeight;
  if (windowHeight >= 970) {
    return '700px';
  }
  if (windowHeight >= 830) {
    return '550px';
  }
  if (windowHeight >= 680) {
    return '400px';
  }
  if (windowHeight >= 500) {
    return '250px';
  }
  return '150px';
};

export const getPopupBodyMaxHeight = (): Observable<string> => {
  return fromEvent(window, 'resize').pipe(
    throttleTime(300, undefined, { leading: false, trailing: true }),
    map(() => calculateMaxHeight()),
    distinctUntilChanged(),
    startWith(calculateMaxHeight()),
  );
};
