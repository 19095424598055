import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageHeaderComponent } from './page-header/page-header.component';
import { ComponentsModule } from '../components/components.module';
import { TypographyModule } from '../typography/typography.module';

@NgModule({
  declarations: [PageHeaderComponent],
  exports: [PageHeaderComponent],
  imports: [CommonModule, ComponentsModule, TypographyModule],
})
export class PageHeaderModule {}
