import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconCloseComponent } from './icon-close/icon-close.component';

@NgModule({
  declarations: [IconCloseComponent],
  imports: [CommonModule],
  exports: [IconCloseComponent],
})
export class IconCloseModule {}
