import { Injectable } from '@angular/core';

import { CharterRoute, NavigationRequest, RouterStateUrl } from '../types';
import { createTripPath } from './create-trip-path';
import { QueryParamsHandling } from '@angular/router';
import { RouterService } from '../router-service';

export interface CreateTripVehiclesRouteData {
  tripItineraryId: string;
  isStopsShown?: boolean;
}

const routePath = 'vehicles';

export const createTripVehiclesRoute: CharterRoute<CreateTripVehiclesRouteData> = {
  path: `${routePath}/:tripItineraryId`,
  isOnRoute(state: RouterStateUrl): boolean {
    return state.url.startsWith(`/${createTripPath}/${routePath}`);
  },
  parse(state: RouterStateUrl): CreateTripVehiclesRouteData {
    return {
      isStopsShown: state.queryParams.tripShown === 'true',
      tripItineraryId: state.params.tripItineraryId,
    };
  },
  request(
    data: CreateTripVehiclesRouteData,
    queryParamStrategy?: QueryParamsHandling,
    replaceUrl?: boolean,
  ): NavigationRequest {
    const request: NavigationRequest = {
      path: [`/${createTripPath}`, routePath, data.tripItineraryId],
    };
    request.query = { tripShown: data?.isStopsShown ? 'true' : null };
    if (replaceUrl) {
      request.extras = {
        replaceUrl: true,
      };
    }
    return request;
  },
  getHref(data: CreateTripVehiclesRouteData): string {
    return `/${createTripPath}/${routePath}/${data.tripItineraryId}`;
  },
};

@Injectable({ providedIn: 'root' })
export class CreateTripVehiclesRouterService extends RouterService<CreateTripVehiclesRouteData> {
  route = createTripVehiclesRoute;
}
