<div
  class="c-popup__contents"
  [ngStyle]="{ zIndex: contentZIndex$ | async }"
  [ngClass]="{ open: isOpen$ | async }"
  (click)="onClose()"
>
  <div
    class="c-popup__contents__box"
    [ngClass]="{ open: isOpen$ | async }"
    (click)="$event.stopPropagation()"
  >
    <ng-container #container></ng-container>
  </div>
</div>

<div
  *ngIf="isOpen$ | async"
  [ngStyle]="{ zIndex: pageMaskZIndex$ | async }"
  class="c-popup__page-mask"
  (click)="onClose()"
></div>
