import { GetCharterTripPurposeResponse } from './index';

export const getCharterTripPurposeMockResponse: GetCharterTripPurposeResponse = {
  tripPurposes: [
    {
      tripPurpose: { label: 'Purpose 1' },
      isActivityDescriptionDisabled: true,
      isActivityDescriptionRequired: false,
    },
    {
      tripPurpose: { label: 'Purpose 2' },
      isActivityDescriptionDisabled: false,
      isActivityDescriptionRequired: true,
    },
    {
      tripPurpose: { label: 'Purpose 3' },
      isActivityDescriptionDisabled: false,
      isActivityDescriptionRequired: true,
    },
  ],
  activityDescriptionMaxLength: 25,
};
