import { PortalEntityType, EntitySearchType } from '@rootTypes';

export const dbEntityTypeToPortalEntityType = (source: EntitySearchType): PortalEntityType => {
  switch (source) {
    case EntitySearchType.CAMPUS:
      return PortalEntityType.CAMPUS;
    case EntitySearchType.DISTRICT:
      return PortalEntityType.DISTRICT;
    case EntitySearchType.DRIVER:
      return PortalEntityType.DRIVER;
    case EntitySearchType.PARENT:
      return PortalEntityType.PARENT;
    case EntitySearchType.SCHOOL:
      return PortalEntityType.SCHOOL;
    case EntitySearchType.STUDENT:
      return PortalEntityType.STUDENT;
    case EntitySearchType.VENDOR:
      return PortalEntityType.VENDOR;
    case EntitySearchType.VEHICLE:
      return PortalEntityType.VEHICLE;
    case EntitySearchType.YARD:
      return PortalEntityType.YARD;
    case EntitySearchType.ZUM_EMPLOYEE:
      return PortalEntityType.ZUM_EMPLOYEE;
    case EntitySearchType.VENDOR_EMPLOYEE:
      return PortalEntityType.VENDOR_EMPLOYEE;
    case EntitySearchType.SCHOOL_EMPLOYEE:
      return PortalEntityType.SCHOOL_EMPLOYEE;
    case EntitySearchType.RIDE:
      return PortalEntityType.RIDE;
    case EntitySearchType.ROUTE:
      return PortalEntityType.ROUTE;
    case EntitySearchType.CHARTER_TRIPS:
      return PortalEntityType.CHARTER_TRIPS;
    default: {
      console.warn('Unknown entity type received: ', source);
      return null;
    }
  }
};
