import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { getRegionApiOptions } from '../../store/app-config.selectors';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'displayRegion',
})
export class DisplayRegionPipe implements PipeTransform {
  constructor(private store: Store) {}

  transform(regionId: string): Observable<string> {
    return this.store.select(getRegionApiOptions).pipe(
      map((options) => {
        if (options) {
          return options.find((o) => o.id === regionId)?.label;
        }
        return '--';
      }),
    );
  }
}
