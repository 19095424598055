import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'wp-section-error',
  templateUrl: './section-error.component.html',
  styleUrls: ['./section-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionErrorComponent implements OnInit {
  @Input() public sectionHeightStr = '100px';

  constructor() {}

  ngOnInit(): void {}
}
