import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'wp-dropdown-option',
  templateUrl: './dropdown-option.component.html',
  styleUrls: ['./dropdown-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownOptionComponent implements OnInit {
  @Input() public disabled = false;
  @Input() public selected = false;
  @Input() public value: any;
  @Input() public isDefaultOption = false;
  @Input() public height = '44px';
  @Output() public selectedWithKeyboard: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('optionElement') private optionElement: ElementRef;

  public highlighted: boolean;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit(): void {}

  public setOptionHighlighted(highlighted: boolean): void {
    this.highlighted = highlighted;
    if (this.highlighted) {
      this.scrollOptionIntoView();
    }
    this.cd.detectChanges();
  }

  private scrollOptionIntoView(): void {
    if (this.optionElement) {
      const optionDiv: HTMLDivElement = this.optionElement.nativeElement;
      optionDiv.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
