<c-page-header></c-page-header>
<c-home-content>
  <div class="content">
    <div class="instructions">
      Please
      <wp-link-btn [fontSize]="'16px'" (clicked)="signInToPortal()"
        >sign in to Portal
      </wp-link-btn>
      to access this site.
    </div>
  </div>
</c-home-content>
<c-page-footer></c-page-footer>
