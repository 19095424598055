import { createReducer, on } from '@ngrx/store';
import { TripCustomerSelectValue } from '@rootTypes';

import { initTripCustomerFromStorage, tripCustomerChanged } from './trip-editor-customer.actions';
import { clearStore } from '../../../../store/common/clear-store.actions';
import {
  createNewTripSuccess,
  initializeNewTrip,
  initializeNewTripReservation,
  initializeNewTripReview,
  initializeNewTripVehicles,
} from '../create-trip/create-trip.actions';

export interface TripCustomerState {
  value?: TripCustomerSelectValue;
  isDisabled: boolean;
  isDistrictDisabled: boolean;
  isSelectCampusTooltipShown: boolean;
}

const createInitialState = (): TripCustomerState => {
  return {
    isDisabled: false,
    isDistrictDisabled: false,
    isSelectCampusTooltipShown: false,
  };
};

export const tripEditorCustomerReducer = createReducer(
  createInitialState(),
  on(clearStore, createInitialState),
  on(initializeNewTrip, (state, action): TripCustomerState => {
    const { defaultDistrict, defaultCampus, generalContractDistrictId } = action;
    let value: TripCustomerSelectValue;
    if (defaultDistrict) {
      value = {
        organization: {
          id: defaultDistrict.id,
          label: defaultDistrict.name,
        },
      };
    }
    if (value && defaultCampus) {
      value.branch = {
        id: defaultCampus.id,
        label: defaultCampus.name,
      };
    }
    return {
      ...state,
      value,
      isDisabled: false,
      isSelectCampusTooltipShown:
        !!defaultDistrict?.id && defaultDistrict.id !== generalContractDistrictId && !defaultCampus?.id,
    };
  }),
  on(
    initializeNewTripVehicles,
    initializeNewTripReview,
    initializeNewTripReservation,
    createNewTripSuccess,
    (state): TripCustomerState => {
      return {
        ...state,
        isDisabled: true,
      };
    },
  ),
  on(initTripCustomerFromStorage, (state, { value }): TripCustomerState => {
    if (value) {
      return {
        ...state,
        value,
      };
    }
    return createInitialState();
  }),
  on(tripCustomerChanged, (state, { value }): TripCustomerState => {
    if (value) {
      return {
        ...state,
        value: {
          ...value,
        },
        isSelectCampusTooltipShown: false,
      };
    }
    return createInitialState();
  }),
);
