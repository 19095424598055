import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'wp-icon-arrow-right',
  templateUrl: './icon-arrow-right.component.html',
  styleUrls: ['./icon-arrow-right.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconArrowRightComponent implements OnInit {
  @Input() public rotateDown: boolean;
  @Input() public fillColor = '#959597';

  constructor() {}

  ngOnInit(): void {}
}
