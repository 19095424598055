import { YYYYMMDDString } from '@rootTypes';
import { TripEditorLeg, TripEditorLegStop, TripEditorStopRef, TripEditorStopsState } from '../entities';
import { isTripEditorStopValid } from './is-trip-editor-stop-valid';
import { updateTripEditorLegOnStopChanged } from './update-trip-editor-leg-on-stop-changed';

export const onTripEditorArrivalDateChanged = (
  state: TripEditorStopsState,
  ref: TripEditorStopRef,
  value?: YYYYMMDDString,
): TripEditorStopsState => {
  const leg: TripEditorLeg = state.form[ref.legName];
  const stop = leg.stops[ref.stopId];
  const updatedStop: TripEditorLegStop = {
    ...stop,
    data: {
      ...stop.data,
      arrivalDate: value || null,
    },
  };
  updatedStop.isValid = isTripEditorStopValid(updatedStop);

  return {
    ...state,
    form: {
      ...state.form,
      [ref.legName]: updateTripEditorLegOnStopChanged(leg, updatedStop),
    },
  };
};
