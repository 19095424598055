<div
  class="wp-date-range-picker"
  (mousedown)="onMouseDown()"
  (mouseup)="onMouseUp()"
  [ngClass]="{ 'single-month': isSingleMonth$ | async }"
>
  <div
    class="control-arrow backwards"
    (click)="$event.stopPropagation(); onBackwardClick()"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="11"
      viewBox="0 0 14 11"
    >
      <g fill="none" fill-rule="evenodd">
        <path
          fill="#7C7C7E"
          d="M-2 6.5L12 6.5 12 7.5 -2 7.5z"
          transform="rotate(-90 5.25 5.25) rotate(90 5 7)"
        />
        <path
          stroke="#7C7C7E"
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M9.412 5.005L5 0C3.45 1.569 1.95 3.237.502 5.005"
          transform="rotate(-90 5.25 5.25)"
        />
      </g>
    </svg>
  </div>
  <div
    class="control-arrow forwards"
    (click)="$event.stopPropagation(); onForwardClick()"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="11"
      viewBox="0 0 14 11"
    >
      <g fill="none" fill-rule="evenodd">
        <path
          fill="#7C7C7E"
          d="M-2 6.5L12 6.5 12 7.5 -2 7.5z"
          transform="matrix(0 -1 -1 0 14 10.5) rotate(90 5 7)"
        />
        <path
          stroke="#7C7C7E"
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M9.412 5.005L5 0C3.45 1.569 1.95 3.237.502 5.005"
          transform="matrix(0 -1 -1 0 14 10.5)"
        />
      </g>
    </svg>
  </div>
  <div
    *ngIf="(currentView$ | async) === viewTypes.DAY; else decadeView"
    class="months"
  >
    <div
      *ngFor="let month of months$ | async; trackBy: trackByFn;"
      class="month"
    >
      <wp-dp-month [month]="month" [readonly]="readonly$ | async"></wp-dp-month>
    </div>
  </div>
  <ng-template #decadeView>
    <div class="months">
      <div *ngFor="let decade of decades$ | async" class="month">
        <wp-dp-decade [decade]="decade"></wp-dp-decade>
      </div>
    </div>
  </ng-template>
</div>
