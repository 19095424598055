<div class="c-confirm-popup" [ngStyle]="{'width': popupWidth}">
  <wp-icon-close *ngIf="isHeaderCloseBtn" class="close-btn" (click)="cancel()">
  </wp-icon-close>

  <div class="header" *ngIf="headerText">{{ headerText }}</div>

  <div *ngIf="bodyText">{{ bodyText }}</div>
  <div *ngIf="bodyHtml" [innerHTML]="bodyHtml"></div>

  <div class="footer">
    <wp-btn-secondary
      *ngIf="cancelBtnText"
      [width]="cancelBtnWidth"
      (clicked)="cancel()"
      >{{cancelBtnText}}
    </wp-btn-secondary>
    <wp-btn-primary
      class="confirm-btn"
      *ngIf="confirmBtnText"
      [width]="confirmBtnWidth"
      [color]="confirmBtnColor"
      (clicked)="confirm()"
      >{{confirmBtnText}}
    </wp-btn-primary>
  </div>
</div>
