import { Injectable } from '@angular/core';
import { firstValueFrom, of } from 'rxjs';
import { delay } from 'rxjs/operators';

import { BaseApiService } from './base-api.service';
import { GetCharterCatalogRequest, GetCharterCatalogResponse } from '@rootTypes/api';
import { getCarterCatalogMockResponse } from '@rootTypes/api/get-charter-catalog/mock-response';

@Injectable({
  providedIn: 'root',
})
export class CharterCatalogApiService {
  private cache: { [catalogId: string]: Promise<GetCharterCatalogResponse> } = {};

  constructor(private baseApi: BaseApiService) {}

  public getCharterCatalog(request: GetCharterCatalogRequest): Promise<GetCharterCatalogResponse> {
    if (this.cache[request.catalogId]) {
      return this.cache[request.catalogId];
    }
    if (cEnvironment.mockApiEnabled) {
      this.cache[request.catalogId] = firstValueFrom(of(getCarterCatalogMockResponse).pipe(delay(1000)));
    } else {
      this.cache[request.catalogId] = firstValueFrom(this.baseApi.post('getCharterCatalog', { ...request }));
    }
    return this.cache[request.catalogId];
  }
}
