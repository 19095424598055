import { EntityState } from '@rootTypes';
import { GetAccountResponse } from '@rootTypes/api/get-account';
import { createReducer, on } from '@ngrx/store';
import {
  checkAuthFirebaseUserNotAuthorized,
  checkAuthGetAccountFailed,
  checkAuthRequested,
  checkAuthSuccess,
  homeDestroyed,
  homeInitialized,
  loginTokenNotFound,
  loginWithTokenFailed,
  loginWithTokenRequested,
  getCharterConfigFailed,
  getCharterConfigRequested,
  getCharterConfigSuccess,
} from './auth.actions';
import { GetCharterConfigResponse } from '@rootTypes/api';

export interface AuthState {
  isHomeInitialized: boolean;
  account: EntityState<GetAccountResponse>;
  appConfig: EntityState<GetCharterConfigResponse>;
}

export const getInitialAuthState = (): AuthState => {
  return {
    isHomeInitialized: false,
    account: { isLoading: false },
    appConfig: { isLoading: false },
  };
};

export const authReducer = createReducer<AuthState>(
  getInitialAuthState(),
  on(loginWithTokenRequested, checkAuthRequested, (state): AuthState => {
    return {
      ...state,
      account: { isLoading: true },
    };
  }),
  on(loginTokenNotFound, (state): AuthState => {
    return {
      ...state,
      account: { isLoading: false },
    };
  }),
  on(loginWithTokenFailed, (state, action): AuthState => {
    return {
      ...state,
      account: {
        isLoading: false,
        error: action.error,
      },
    };
  }),
  on(checkAuthSuccess, (state, { account }): AuthState => {
    return {
      ...state,
      account: {
        isLoading: false,
        entity: account,
      },
    };
  }),
  on(checkAuthFirebaseUserNotAuthorized, checkAuthGetAccountFailed, (state, { error }): AuthState => {
    return {
      ...state,
      account: {
        isLoading: false,
        error,
      },
    };
  }),
  on(getCharterConfigRequested, (state): AuthState => {
    return {
      ...state,
      appConfig: {
        isLoading: true,
      },
    };
  }),
  on(getCharterConfigSuccess, (state, action): AuthState => {
    return {
      ...state,
      appConfig: {
        isLoading: false,
        entity: action.data,
      },
    };
  }),
  on(getCharterConfigFailed, (state, action): AuthState => {
    return {
      ...state,
      appConfig: {
        isLoading: false,
        error: action.error,
      },
    };
  }),
  on(homeInitialized, (state): AuthState => {
    return {
      ...state,
      isHomeInitialized: true,
    };
  }),
  on(homeDestroyed, (state): AuthState => {
    return {
      ...state,
      isHomeInitialized: false,
    };
  }),
);
