import { GetCharterTripItineraryResponse } from '@rootTypes/api';
import { TripEditorLeg, TripEditorLegName, TripEditorLegStop, TripEditorLegSummary } from '../entities';
import { createEntityState } from '@rootTypes/utils';
import { generateTripEditorStopId } from './generate-trip-editor-stop-id';

export const createTripEditorLegFromItinerary = (
  itinerary: GetCharterTripItineraryResponse,
  legName: TripEditorLegName,
): TripEditorLeg => {
  const source = legName === TripEditorLegName.OUTBOUND ? itinerary.outbound : itinerary.return;

  const lastStop = source.stops[source.stops.length - 1];
  const summary: TripEditorLegSummary = {
    arrivalDate: lastStop.arrivalDate,
    arrivalTime: lastStop.arrivalTime,
    totalMiles: source.summary.totalMiles,
    polyline: source.summary.polyline,
  };

  const stops: { [stopId: string]: TripEditorLegStop } = {};
  source.stops.forEach((stop, index) => {
    const stopId = generateTripEditorStopId();
    stops[stopId] = {
      stopId,
      stopIndex: index,
      isFirst: index === 0,
      isLast: index === source.stops.length - 1,
      isValid: true,
      data: {
        address: { ...stop.address },
        arrivalDate: stop.arrivalDate,
        arrivalTime: stop.arrivalTime,
        departureTime: stop.departureTime || null,
      },
    };
  });

  return {
    legName,
    isReturnLeg: legName === TripEditorLegName.RETURN,
    isReadyForDirectionRequest: true,
    summary: createEntityState(summary),
    stops,
  };
};
