import { Injectable } from '@angular/core';

import { CharterRoute, NavigationRequest, RouterStateUrl } from '../types';
import { createTripPath } from './create-trip-path';
import { QueryParamsHandling } from '@angular/router';
import { RouterService } from '../router-service';

export interface CreateTripStopsChangeRouteData {
  tripItineraryId: string;
}

const routePath = 'change-itinerary';

export const createTripStopsChangeRoute: CharterRoute<CreateTripStopsChangeRouteData> = {
  path: `${routePath}/:tripItineraryId`,
  isOnRoute(state: RouterStateUrl): boolean {
    return state.url.startsWith(`/${createTripPath}/${routePath}`);
  },
  parse(state: RouterStateUrl): CreateTripStopsChangeRouteData {
    return {
      tripItineraryId: state.params.tripItineraryId,
    };
  },
  request(
    data: CreateTripStopsChangeRouteData,
    queryParamStrategy?: QueryParamsHandling,
    replaceUrl?: boolean,
  ): NavigationRequest {
    const request: NavigationRequest = {
      path: [`/${createTripPath}`, routePath, data.tripItineraryId],
      extras: {
        replaceUrl: true,
      },
    };
    return request;
  },
  getHref(data: CreateTripStopsChangeRouteData): string {
    return `/${createTripPath}/${routePath}/${data.tripItineraryId}`;
  },
};

@Injectable({ providedIn: 'root' })
export class CreateTripStopsChangeRouterService extends RouterService<CreateTripStopsChangeRouteData> {
  route = createTripStopsChangeRoute;
}
