<header
  [ngStyle]="{padding: padding, fontSize: fontSize, fontWeight: fontWeight}"
>
  <div>
    <ng-content></ng-content>
  </div>
  <wp-icon-close
    *ngIf="isCloseBtn"
    class="close-btn"
    (click)="close()"
  ></wp-icon-close>
</header>

<div
  *ngIf="isHorizontalLine"
  class="horizontal-line"
  [ngStyle]="{margin: horizontalLineMargin}"
></div>
