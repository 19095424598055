<div class="label-side-value-wrap">
  <div class="label">
    <wp-grey-label>{{ label }}</wp-grey-label>
  </div>
  <div class="value" [ngStyle]="{'max-width': valueMaxWidth}">
    <wp-field-value>
      <span *ngIf="!isHardCoded">
        <span [innerHTML]="valueHTML"></span>
      </span>
      <span *ngIf="isHardCoded" wpHardCoded>
        <span [innerHTML]="valueHTML"></span>
      </span>
    </wp-field-value>
  </div>
</div>
