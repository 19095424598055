import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'c-label-rectangle',
  template: `
    <div class="label" [ngStyle]="{ backgroundColor: bgColor }">
      <ng-content></ng-content>
    </div>
  `,
  styles: [
    `
      :host {
        display: inline-block;
      }
      .label {
        display: inline-block;
        padding: 4px 12px;
        border-radius: 6px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelRectangleComponent implements OnInit {
  @Input() public bgColor = 'white';
  constructor() {}

  ngOnInit(): void {}
}
