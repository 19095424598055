import { EntityState } from '@rootTypes';
import {
  GetCharterCatalogResponse,
  GetCharterContractResponse,
  GetCharterRideResponse,
  GetCharterTripInvoiceResponse,
  GetCharterTripItineraryResponse,
  GetCharterTripResponse,
} from '@rootTypes/api';
import { createReducer, on } from '@ngrx/store';
import {
  getCharterCatalogFailed,
  getCharterCatalogRequested,
  getCharterCatalogSuccess,
  getCharterRideFailed,
  getCharterRideRequested,
  getCharterRideSuccess,
  getCharterTripFailed,
  getCharterTripItineraryFailed,
  getCharterTripItineraryRequested,
  getCharterTripItinerarySuccess,
  getCharterTripRequested,
  getCharterTripSuccess,
  getContractFailed,
  getContractRequested,
  getContractSuccess,
  getDriverFailed,
  getDriverRequested,
  getDriverSuccess,
  getTripInvoiceFailed,
  getTripInvoiceRequested,
  getTripInvoiceSuccess,
  getVehicleFailed,
  getVehicleRequested,
  getVehicleSuccess,
  updateTripQuoteFailed,
  updateTripQuoteRequested,
  updateTripQuoteSuccess,
  updateCharterTripDetailsRequested,
  updateCharterTripDetailsSuccess,
  updateCharterTripDetailsFailed,
} from './actions';
import { Driver } from '@rootTypes/entities/driver';
import { Vehicle } from '@rootTypes/entities/vehicles';
import { createEntityState } from '@rootTypes/utils';

export type TripDataState = {
  trips: {
    [tripId: string]: EntityState<GetCharterTripResponse>;
  };
  charterRides: {
    [charterRideId: string]: EntityState<GetCharterRideResponse>;
  };
  itineraries: {
    [charterTripItineraryId: string]: EntityState<GetCharterTripItineraryResponse>;
  };
  catalogs: {
    [catalogId: string]: EntityState<GetCharterCatalogResponse>;
  };
  drivers: {
    [driverId: string]: EntityState<Driver>;
  };
  vehicles: {
    [vehicleId: string]: EntityState<Vehicle>;
  };
  invoicesByTrips: {
    [tripId: string]: EntityState<GetCharterTripInvoiceResponse>;
  };
  contracts: {
    [contractId: string]: EntityState<GetCharterContractResponse>;
  };
  tripDetailsUpdate: EntityState<{ isSuccess: true }>;
};

const getInitialTripDataState = (): TripDataState => {
  return {
    trips: {},
    charterRides: {},
    itineraries: {},
    catalogs: {},
    drivers: {},
    vehicles: {},
    invoicesByTrips: {},
    contracts: {},
    tripDetailsUpdate: { isLoading: false },
  };
};

export const tripDataReducer = createReducer<TripDataState>(
  getInitialTripDataState(),
  /**
   * Get charter trip
   */
  on(getCharterTripRequested, (state, action): TripDataState => {
    return {
      ...state,
      trips: {
        ...state.trips,
        [action.tripId]: {
          isLoading: true,
        },
      },
    };
  }),
  on(getCharterTripSuccess, (state, action): TripDataState => {
    const { trip } = action;
    return {
      ...state,
      trips: {
        ...state.trips,
        [trip.tripId]: {
          isLoading: false,
          entity: trip,
        },
      },
    };
  }),
  on(getCharterTripFailed, (state, action): TripDataState => {
    const { tripId, error } = action;
    return {
      ...state,
      trips: {
        ...state.trips,
        [tripId]: {
          isLoading: false,
          error,
        },
      },
    };
  }),
  /**
   * Update charter trip details
   */
  on(updateCharterTripDetailsRequested, (state): TripDataState => {
    return {
      ...state,
      tripDetailsUpdate: { isLoading: true },
    };
  }),
  on(updateCharterTripDetailsSuccess, (state, { trip }): TripDataState => {
    return {
      ...state,
      tripDetailsUpdate: createEntityState({ isSuccess: true }),
      trips: {
        ...state.trips,
        [trip.tripId]: createEntityState(trip),
      },
    };
  }),
  on(updateCharterTripDetailsFailed, (state, { error }): TripDataState => {
    return {
      ...state,
      tripDetailsUpdate: createEntityState(undefined, error),
    };
  }),
  /**
   * Get charter ride
   */
  on(getCharterRideRequested, (state, action): TripDataState => {
    const { charterRideId } = action;
    return {
      ...state,
      charterRides: {
        ...state.charterRides,
        [charterRideId]: {
          isLoading: true,
        },
      },
    };
  }),
  on(getCharterRideSuccess, (state, action): TripDataState => {
    const { charterRide } = action;
    return {
      ...state,
      charterRides: {
        ...state.charterRides,
        [charterRide.ride_id]: {
          isLoading: false,
          entity: charterRide,
        },
      },
    };
  }),
  on(getCharterRideFailed, (state, action): TripDataState => {
    const { charterRideId, error } = action;
    return {
      ...state,
      charterRides: {
        ...state.charterRides,
        [charterRideId]: {
          isLoading: false,
          error,
        },
      },
    };
  }),
  /**
   * Get charter trip itinerary
   */
  on(getCharterTripItineraryRequested, (state, action): TripDataState => {
    const { charterTripItineraryId } = action;
    return {
      ...state,
      itineraries: {
        ...state.itineraries,
        [charterTripItineraryId]: {
          ...(state.itineraries[charterTripItineraryId] || {}),
          isLoading: true,
        },
      },
    };
  }),
  on(getCharterTripItinerarySuccess, (state, action): TripDataState => {
    const { charterTripItinerary } = action;
    const { tripItineraryId: charterTripItineraryId } = charterTripItinerary;
    return {
      ...state,
      itineraries: {
        ...state.itineraries,
        [charterTripItinerary.tripItineraryId]: {
          ...(state.itineraries[charterTripItineraryId] || {}),
          isLoading: false,
          entity: charterTripItinerary,
        },
      },
    };
  }),
  on(getCharterTripItineraryFailed, (state, action): TripDataState => {
    const { charterTripItineraryId, error } = action;
    return {
      ...state,
      itineraries: {
        ...state.itineraries,
        [charterTripItineraryId]: {
          ...(state.itineraries[charterTripItineraryId] || {}),
          isLoading: false,
          error,
        },
      },
    };
  }),
  /**
   * Get charter catalog
   */
  on(getCharterCatalogRequested, (state, action): TripDataState => {
    const { catalogId } = action;
    return {
      ...state,
      catalogs: {
        ...state.catalogs,
        [catalogId]: {
          ...(state.catalogs[catalogId] || {}),
          isLoading: true,
        },
      },
    };
  }),
  on(getCharterCatalogSuccess, (state, action): TripDataState => {
    const { catalog, catalogId } = action;
    return {
      ...state,
      catalogs: {
        ...state.catalogs,
        [catalogId]: {
          ...(state.catalogs[catalogId] || {}),
          isLoading: false,
          entity: catalog,
        },
      },
    };
  }),
  on(getCharterCatalogFailed, (state, action): TripDataState => {
    const { catalogId, error } = action;
    return {
      ...state,
      catalogs: {
        ...state.catalogs,
        [catalogId]: {
          ...(state.catalogs[catalogId] || {}),
          isLoading: false,
          error,
        },
      },
    };
  }),
  /**
   * Get driver
   */
  on(getDriverRequested, (state, action): TripDataState => {
    const { driverId } = action;
    return {
      ...state,
      drivers: {
        ...state.drivers,
        [driverId]: {
          isLoading: true,
        },
      },
    };
  }),
  on(getDriverSuccess, (state, action): TripDataState => {
    const { driver, driverId } = action;
    return {
      ...state,
      drivers: {
        ...state.drivers,
        [driverId]: {
          ...(state.drivers[driverId] || {}),
          isLoading: false,
          entity: driver,
        },
      },
    };
  }),
  on(getDriverFailed, (state, action): TripDataState => {
    const { driverId, error } = action;
    return {
      ...state,
      drivers: {
        ...state.drivers,
        [driverId]: {
          ...(state.drivers[driverId] || {}),
          isLoading: false,
          error,
        },
      },
    };
  }),
  /**
   * Get vehicle
   */
  on(getVehicleRequested, (state, action): TripDataState => {
    const { vehicleId } = action;
    return {
      ...state,
      vehicles: {
        ...state.vehicles,
        [vehicleId]: {
          isLoading: true,
        },
      },
    };
  }),
  on(getVehicleSuccess, (state, action): TripDataState => {
    const { vehicle, vehicleId } = action;
    return {
      ...state,
      vehicles: {
        ...state.vehicles,
        [vehicleId]: {
          ...(state.vehicles[vehicleId] || {}),
          isLoading: false,
          entity: vehicle,
        },
      },
    };
  }),
  on(getVehicleFailed, (state, action): TripDataState => {
    const { vehicleId, error } = action;
    return {
      ...state,
      vehicles: {
        ...state.vehicles,
        [vehicleId]: {
          ...(state.vehicles[vehicleId] || {}),
          isLoading: false,
          error,
        },
      },
    };
  }),
  /**
   * Get/update invoice
   */
  on(getTripInvoiceRequested, updateTripQuoteRequested, (state, action): TripDataState => {
    const { request } = action;
    return {
      ...state,
      invoicesByTrips: {
        ...state.invoicesByTrips,
        [request.tripId]: {
          isLoading: true,
        },
      },
    };
  }),
  on(updateTripQuoteRequested, (state, action): TripDataState => {
    const { request } = action;
    return {
      ...state,
      invoicesByTrips: {
        ...state.invoicesByTrips,
        [request.tripId]: {
          ...state.invoicesByTrips[request.tripId],
          isLoading: true,
        },
      },
    };
  }),
  on(getTripInvoiceSuccess, updateTripQuoteSuccess, (state, action): TripDataState => {
    const { invoice, tripId } = action;
    return {
      ...state,
      invoicesByTrips: {
        ...state.invoicesByTrips,
        [tripId]: {
          ...(state.invoicesByTrips[tripId] || {}),
          isLoading: false,
          entity: invoice,
        },
      },
    };
  }),
  on(getTripInvoiceFailed, (state, action): TripDataState => {
    const { tripId, error } = action;
    return {
      ...state,
      invoicesByTrips: {
        ...state.invoicesByTrips,
        [tripId]: {
          ...(state.invoicesByTrips[tripId] || {}),
          isLoading: false,
          error,
        },
      },
    };
  }),
  on(updateTripQuoteFailed, (state, action): TripDataState => {
    const { tripId } = action;
    return {
      ...state,
      invoicesByTrips: {
        ...state.invoicesByTrips,
        [tripId]: {
          ...(state.invoicesByTrips[tripId] || {}),
          isLoading: false,
        },
      },
    };
  }),
  /**
   * Get contract
   */
  on(getContractRequested, (state, action): TripDataState => {
    const { request } = action;
    return {
      ...state,
      contracts: {
        ...state.contracts,
        [request.contractId]: {
          isLoading: true,
        },
      },
    };
  }),
  on(getContractSuccess, (state, action): TripDataState => {
    const { contract, contractId } = action;
    return {
      ...state,
      contracts: {
        ...state.contracts,
        [contractId]: {
          ...(state.contracts[contractId] || {}),
          isLoading: false,
          entity: contract,
        },
      },
    };
  }),
  on(getContractFailed, (state, action): TripDataState => {
    const { contractId, error } = action;
    return {
      ...state,
      contracts: {
        ...state.contracts,
        [contractId]: {
          ...(state.contracts[contractId] || {}),
          isLoading: false,
          error,
        },
      },
    };
  }),
);
