import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { iconPaths } from '@rootTypes/utils';
import { HomeRouterService, TripListRouterService } from '../../../routes';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectSupportLink } from '../../../store/app-config.selectors';
import { getBannerLabel } from '../../../store/auth/auth.selectors';

@Component({
  selector: 'c-home-header',
  templateUrl: './home-header.component.html',
  styleUrls: ['./home-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeHeaderComponent implements OnInit {
  public logo = iconPaths.ZUM_LOGO_HORIZONTAL;
  public supportLink$: Observable<string>;
  public banner$: Observable<string | undefined>;

  constructor(
    private homeRouter: HomeRouterService,
    private tripsRouter: TripListRouterService,
    private store: Store,
  ) {}

  public ngOnInit(): void {
    this.supportLink$ = this.store.select(selectSupportLink);
    this.banner$ = this.store.select(getBannerLabel);
  }

  public goHome(): void {
    this.homeRouter.navigate();
  }

  public goTrips(): void {
    this.tripsRouter.navigate();
  }
}
