import { PortalEntityType, YYYYMMDDString } from '@rootTypes';
import {
  PortalListDateFilter,
  PortalListDateRangeFilter,
  PortalListEntityFilter,
} from '@rootTypes/utils/list-store-utils/portal-list-filter';

export const getEntityPortalListFilter = (
  entityType: PortalEntityType,
  entityId: string,
  order?: number,
): PortalListEntityFilter => {
  return {
    id: `entity,${entityType},${entityId}`,
    type: 'entity',
    order,
    payload: {
      entityId,
      type: entityType,
    },
  };
};

export const getDatePortalListFilter = (dateYYYYMMDD: YYYYMMDDString, order?: number): PortalListDateFilter => {
  return {
    id: `date,${dateYYYYMMDD}`,
    type: 'date',
    payload: dateYYYYMMDD,
    order,
  };
};

export const getDateRangePortalListFilter = (
  from: YYYYMMDDString,
  to: YYYYMMDDString,
  order?: number,
): PortalListDateRangeFilter => {
  return {
    id: `date-range,${from || ''},${to || ''}`,
    type: 'date-range',
    payload: {
      startDate: from,
      endDate: to,
    },
    order,
  };
};
