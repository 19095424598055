import { map, distinctUntilChanged, startWith, skip } from 'rxjs/operators';
import { Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { SelectOption } from '..';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';

interface DropdownState {
  isOpen: boolean;
  currentOption: SelectOption | null;
}

const initialDropdownState: DropdownState = {
  isOpen: false,
  currentOption: null,
};

export abstract class AbstractDropdown {
  public abstract disabled: boolean;
  public abstract label: string;
  public abstract control: UntypedFormControl;
  public abstract options: SelectOption[];

  public displayInputControl = new UntypedFormControl();

  public isOpen$!: Observable<boolean>;
  public displayText$!: Observable<string | null>;
  public selectedOption$!: Observable<SelectOption | null>;

  private state$: BehaviorSubject<DropdownState> = new BehaviorSubject<DropdownState>(initialDropdownState);
  private subcriptions: Subscription;

  public onOptionClick(o: SelectOption): void {
    this.state$.next({
      ...this.state$.value,
      currentOption: o,
    });
    this.closeDropdown();
  }

  public openDropdown(): void {
    document.body.click();
    if (this.disabled) {
      return;
    }
    this.emitDropdownOpened();
  }

  public emitDropdownOpened(): void {
    this.state$.next({
      ...this.state$.value,
      isOpen: true,
    });
  }

  public closeDropdown(): void {
    this.state$.next({
      ...this.state$.value,
      isOpen: false,
    });
  }

  protected onNgInit(): void {
    // this.state$.asObservable()
    //   .subscribe(val => console.log(val));
    this.subcriptions = new Subscription();
    this.isOpen$ = this.state$.asObservable().pipe(map((state) => state.isOpen));
    this.selectedOption$ = this.state$.asObservable().pipe(
      map((state) => state.currentOption),
      distinctUntilChanged(),
    );
    this.displayText$ = this.state$.asObservable().pipe(
      map((state) => state.currentOption),
      map((option) => (option ? option.displayLabel : null)),
    );
    const sub1 = this.displayText$
      .pipe(distinctUntilChanged())
      .subscribe((text) => this.displayInputControl.setValue(text));

    if (!this.control) {
      return;
    }

    const sub2 = this.control.valueChanges
      .pipe(startWith(this.control.value), distinctUntilChanged())
      .subscribe((val) => {
        const selectedOption = this.options.find((o) => o.value === val) || null;
        this.state$.next({
          ...this.state$.value,
          currentOption: selectedOption,
        });
      });
    const sub3 = this.state$
      .asObservable()
      .pipe(
        map((state) => state.currentOption),
        map((option) => (option ? option.value : null)),
        distinctUntilChanged(),
        skip(1),
      )
      .subscribe((value) => {
        if (this.control.value !== value) {
          this.control.setValue(value);
        }
      });
    this.subcriptions.add(sub1);
    this.subcriptions.add(sub2);
    this.subcriptions.add(sub3);
  }

  protected onNgOnDestroy() {
    if (this.subcriptions) {
      this.subcriptions.unsubscribe();
    }
  }
}
