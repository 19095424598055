export const iconPaths = {
  ARROW_UP_GREY: '/assets/icons/arrow-up-grey.svg',
  ARROW_LEFT_GREY: '/assets/icons/arrow-left-gray.svg',
  ARROW_DOWN_GREY: '/assets/icons/arrow-down-grey.svg',
  CHECKED_CIRCLE_LARGE: '/assets/icons/checked-circle-large.svg',
  CHECK_CIRCLE_LINE: '/assets/icons/check-circle-line.svg',
  CHECK_SMALL_STROKE: '/assets/icons/check-small-stroke.svg',
  COPY_TO_CLIPBOARD: '/assets/icons/ic-copy.svg',
  CREATE_TRIP_BACKGROUND_LEFT: '/assets/icons/create-trip-background-left.svg',
  CREATE_TRIP_BACKGROUND_RIGHT: '/assets/icons/create-trip-background-right.svg',
  CROSS_CIRCLED_GREY: '/assets/icons/cross-circled-grey.svg',
  EMPLOYEE_GREEN: '/assets/icons/employee-green.svg',
  EXCLAMATION_SIGN_CIRCLED_ORANGE: '/assets/icons/exclamation-sign-circled-orange.svg',
  EXCLAMATION_SIGN_ORANGE_BOLD: '/assets/icons/exclamation-sign-orange.svg',
  FILTER_GREEN: '/assets/icons/filter.svg',
  INFO_CIRCLE: '/assets/icons/info-circle.svg',
  INFO_CIRCLE_GREY: '/assets/icons/info-circle-grey.svg',
  INFO_CIRCLE_GREEN: '/assets/icons/info-circle-green.svg',
  MAP_PREVIEW: '/assets/icons/map-preview.png',
  PERSON_GREEN: '/assets/icons/person-green.svg',
  PLUS_CIRCLED_GREEN: '/assets/icons/plus-circled-green.svg',
  SNOWFLAKE: '/assets/icons/snowflake.svg',
  WHEELCHAIR: '/assets/icons/wheelchair.svg',
  WIRELESS: '/assets/icons/wireless.svg',
  ZUM_LOGO_HORIZONTAL: '/assets/icons/zum-logo-horizontal.png',
};
