import { Component, ChangeDetectionStrategy, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'wp-list-result-bar',
  templateUrl: './list-result-bar.component.html',
  styleUrls: ['./list-result-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListResultBarComponent {
  @Input() public viewType: 'full' | 'reduced' = 'full';
  @Input() entityName = 'item';
  @Input() public customPluralForm: string;
  @Input() maxQuantity = 1000000;
  @Input() public totalFound: number;
  @Input() public pageSize: number;
  @Input() public currentPage: number;
  @Input() public itemsCountLoading: boolean;
  @Input() public centerContent?: TemplateRef<any>;
  @Input() public customTotalFoundHtml?: string;
}
