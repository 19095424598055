import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EntitiesDataState } from './reducer';
import { entitiesDataFeatureKey } from './feature-key';
import { PortalEntity, PortalEntityType } from '@rootTypes';

export const getEntityDataFeature = createFeatureSelector<EntitiesDataState>(entitiesDataFeatureKey);

export function getEntity<T extends PortalEntity>(type: PortalEntityType, entityId: string) {
  return createSelector(getEntityDataFeature, (state): T | undefined => {
    if (state[type]) {
      return state[type][entityId] as T;
    }
    return undefined;
  });
}
