<div class="wp-dp-month">
  <div class="month-label" (click)="onLabelClick()">
    {{ decade.label }}
    <span class="arrow-up"></span>
  </div>
  <div class="vertical-line"></div>
  <div class="days">
    <div *ngFor="let year of yearsForDecade$ | async" class="day">
      <wp-dp-year [year]="year"></wp-dp-year>
    </div>
  </div>
</div>
