import { Injectable } from '@angular/core';
import { QueryParamsHandling } from '@angular/router';
import { CharterRoute, NavigationRequest, RouterStateUrl } from '../types';
import { RouterService } from '../router-service';

export type NoPermissionsRouteData = {};

const routePath = 'no-permissions';

export const noPermissionsRoute: CharterRoute<NoPermissionsRouteData> = {
  path: routePath,
  isOnRoute(state: RouterStateUrl): boolean {
    return state.url.startsWith(`/${routePath}`);
  },
  parse(state: RouterStateUrl): NoPermissionsRouteData {
    return {};
  },
  request(
    data?: NoPermissionsRouteData,
    queryParamStrategy?: QueryParamsHandling,
    replaceUrl?: boolean,
  ): NavigationRequest {
    return {
      path: [`/${routePath}`],
    };
  },
  getHref(data?: NoPermissionsRouteData): string {
    return `/${routePath}`;
  },
};

@Injectable({ providedIn: 'root' })
export class NoPermissionsRouterService extends RouterService<NoPermissionsRouteData> {
  route = noPermissionsRoute;
}
