<div class="day-wrap" #dayWrap>
  <div
    [matTooltip]="day.tooltip"
    [matTooltipDisabled]="!day.tooltip"
    [matTooltipPosition]="'above'"
  >
    <!-- Disabled mask -->
    <div
      class="disabled"
      [ngClass]="{ 'shown': readonly || day.isDisabled }"
    ></div>
    <!-- Actual Day -->
    <div class="day {{ day.css }}" (mousedown)="onDayClick()">
      {{ day.label }}
    </div>
  </div>
</div>
