import { createAction, props } from '@ngrx/store';
import { GetCharterTripItineraryResponse } from '@rootTypes/api';
import { WpError } from '@rootTypes';

const effects = '[TripEditorItineraryEffects]';
export const tripEditorGetTripItineraryRequested = createAction(
  `${effects} Get trip itinerary requested`,
  props<{ tripItineraryId: string }>(),
);
export const tripEditorGetTripItinerarySuccess = createAction(
  `${effects} Get trip itinerary success`,
  props<{ response: GetCharterTripItineraryResponse }>(),
);
export const tripEditorGetTripItineraryFailed = createAction(
  `${effects} Get trip itinerary failed`,
  props<{ error: WpError }>(),
);
