import { createAction, props } from '@ngrx/store';
import { WpError } from '@rootTypes';
import { GetCharterContractResponse } from '@rootTypes/api';

const effects = '[TripEditorContractEffects]:';
export const tripEditorGetContractRequested = createAction(
  `${effects} Get contract requested`,
  props<{ contractId: string }>(),
);
export const tripEditorGetContractSuccess = createAction(
  `${effects} Get contract success`,
  props<{ response: GetCharterContractResponse }>(),
);
export const tripEditorGetContractFailed = createAction(`${effects} Get contract failed`, props<{ error: WpError }>());
