import { RollbarFingerprintLike } from './rollbar-fingerprint-like';
import { HttpResponseStatus } from 'src/app/core/services/api/models/http-response-status';

export class RollbarApiError extends Error implements RollbarFingerprintLike {
  public fingerprint: string;

  constructor(apiName: string, status: HttpResponseStatus) {
    super(`${apiName}, STATUS_${status}`);
    // see: https://stackoverflow.com/a/41429145
    Object.setPrototypeOf(this, RollbarApiError.prototype);

    this.name = 'ApiError';
    this.fingerprint = `api-${status}-${apiName}`;
  }
}
