import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { DirectivesModule } from '../directives/directives.module';
import { FormControlsModule } from '../form-controls/form-controls.module';
import { ComponentsModule } from '../components/components.module';
import { PipesModule } from '../pipes/pipes.module';
import { IconCloseModule } from '../icons/icon-close/icon-close.module';
import { SearchIconModule } from '../icons/search-icon/search-icon.module';

import { SearchOptionComponent } from './search-option/search-option.component';
import { EntityFilterComponent } from './entity-filter/entity-filter.component';

@NgModule({
  declarations: [EntityFilterComponent, SearchOptionComponent],
  providers: [],
  imports: [
    CommonModule,
    InfiniteScrollModule,
    DirectivesModule,
    FormControlsModule,
    PipesModule,
    ComponentsModule,
    IconCloseModule,
    SearchIconModule,
  ],
  exports: [EntityFilterComponent],
})
export class EntityFilterModule {}
