import { GetCharterTripResponse } from './index';
import { TripStatus } from '@rootTypes';

export const getCharterTripMockResponse: GetCharterTripResponse = {
  tripId: 'tripId',
  tripItineraryId: 'tripItineraryId',
  tripDisplayId: '987136459',
  customerId: 'customerId',
  status: TripStatus.RESERVED,
  catalogs: [
    {
      count: 1,
      catalogId: 'categoryId1',
      serviceLocationId: 'serviceLocationId',
    },
    {
      count: 2,
      catalogId: 'categoryId2',
      serviceLocationId: 'serviceLocationId',
    },
  ],
  contacts: [],
  totalCents: 97129,
  note: 'Additional notes and instructions',
  billingOrganization: {
    contact: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
    },
  },
  isCardPayment: false,
  isCheckPayment: false,
  customFieldValues: [],
};
