import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { iconPaths } from '@rootTypes/utils/common';
import { SelectOption } from '../index';

@Component({
  selector: 'wp-quick-filters-select',
  templateUrl: './quick-filters-select.component.html',
  styleUrls: ['./quick-filters-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuickFiltersSelectComponent implements OnInit, OnChanges {
  @Input() public options: SelectOption[] = [];
  @Input() public label = 'Quick filters';
  @Input() public selectedId: string;
  @Input() public defaultId: string;
  @Input() public disabled: boolean;
  @Input() public alignDropdown: 'left' | 'right' | 'center' | 'full-width';
  @Input() public dropdownWidthPx?: number;
  @Output() public selectedChanged = new EventEmitter<string>();

  public isDropdownOpen: boolean;
  public isSelected: boolean;
  iconPaths = iconPaths;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedId || changes.defaultId) {
      this.isSelected = !!this.selectedId && this.selectedId !== this.defaultId;
    }
  }

  public onTriggerClick(): void {
    this.isDropdownOpen = true;
  }

  public onDropdownClose(): void {
    this.isDropdownOpen = false;
  }

  public onOptionClick(value: string): void {
    this.selectedChanged.emit(value);
  }
}
