import {
  CampusPortalEntity,
  EntitySearchResult,
  EntitySearchResultVehicle,
  EntitySearchType,
  PortalEntity,
  SchoolEmployeePortalEntity,
  VehiclePortalEntity,
} from '@rootTypes';
import { getEntityId } from './get-entity-id';
import { dbEntityTypeToPortalEntityType } from './db-entity-type-to-portal-entity-type';

const vehicleResultToPortalEntity = (source: EntitySearchResultVehicle): VehiclePortalEntity => {
  let label = `${source.make} ${source.model}`;
  if (source.vehicleDisplayId) {
    label += `, ${source.vehicleDisplayId}`;
  }
  const entity: VehiclePortalEntity = {
    entityId: source.vehicleId,
    type: dbEntityTypeToPortalEntityType(source.type),
    label,
    makeAndModel: `${source.make} ${source.model}`,
    licenseStateAndNumber: `${source.license.state}${source.license.number}`,
    assignedDriverText: 'Unassigned',
  };
  const assignedDriverId = source.assignedDriverId || source.assignedDriver?.id;
  if (assignedDriverId) {
    entity.assignedDriverId = assignedDriverId;
    entity.assignedDriverText = 'Assigned';
  }
  if (source.imagePath) {
    entity.imagePath = source.imagePath;
  }
  if (source.vehicleDisplayId) {
    entity.vehicleDisplayId = source.vehicleDisplayId;
  }
  return entity;
};

export const getPortalEntityFromDb = (source: EntitySearchResult): PortalEntity => {
  if (source.type === EntitySearchType.VEHICLE) {
    return vehicleResultToPortalEntity(source);
  }
  if (source.type === EntitySearchType.CAMPUS) {
    return {
      entityId: getEntityId(source),
      type: dbEntityTypeToPortalEntityType(source.type),
      label: source.label,
      districtId: source.districtId,
    } as CampusPortalEntity;
  }
  if (source.type === EntitySearchType.SCHOOL_EMPLOYEE) {
    const employee: SchoolEmployeePortalEntity = {
      entityId: getEntityId(source),
      type: dbEntityTypeToPortalEntityType(source.type),
      label: source.label,
      email: source.email,
    };
    if (source.organizationName) {
      employee.organizationName = source.organizationName;
    }
    return employee;
  }
  return {
    entityId: getEntityId(source),
    type: dbEntityTypeToPortalEntityType(source.type),
    label: source.label,
  };
};
