import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'wp-btn-increment-plus',
  templateUrl: './btn-increment-plus.component.html',
  styleUrls: ['./btn-increment-plus.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BtnIncrementPlusComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
