import { AnimationBuilder, AnimationPlayer } from '@angular/animations';
import { AfterViewInit, Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

/**
 * https://stackoverflow.com/questions/42878197/angular-2-how-to-set-animations-on-a-directive
 */
@Directive({
  selector: '[wpFadeInOut]',
})
export class FadeInOutDirective implements AfterViewInit, OnDestroy {
  player: AnimationPlayer;
  private isShown$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * Fades in, if set to true, fades out if set to false
   */
  @Input()
  set show(show: boolean) {
    this.isShown$.next(show);
  }

  private subscription: Subscription = new Subscription();

  constructor(private builder: AnimationBuilder, private el: ElementRef) {}

  ngAfterViewInit(): void {
    (this.el.nativeElement as HTMLDivElement).style.transition = 'opacity 300ms ease-in';
    (this.el.nativeElement as HTMLDivElement).style.opacity = '0';
    const sub = this.isShown$.pipe().subscribe((isShown) => {
      if (isShown) {
        this.fadeIn();
      } else {
        this.fadeOut();
      }
    });
    this.subscription.add(sub);
  }

  private fadeIn(): void {
    (this.el.nativeElement as HTMLDivElement).style.opacity = '1';
  }

  private fadeOut(): void {
    (this.el.nativeElement as HTMLDivElement).style.opacity = '0';
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
