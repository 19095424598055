import { createReducer, on } from '@ngrx/store';

import { clearStore } from '../../../../store/common/clear-store.actions';
import * as fromActions from './trip-editor-quote.actions';
import { TripEditorQuoteState, TripEditorVehicleCatalog, createInitialTripEditorQuoteState } from './types';
import { VehicleCatalogSelected } from '@rootTypes';
import {
  createNewTripSuccess,
  initializeNewTrip,
  initializeNewTripReservation,
  initializeNewTripReview,
  initializeNewTripVehicles,
} from '../create-trip/create-trip.actions';
import { createEntityState } from '@rootTypes/utils';

export const tripEditorQuoteReducer = createReducer(
  createInitialTripEditorQuoteState(),
  on(
    initializeNewTrip,
    initializeNewTripVehicles,
    initializeNewTripReview,
    initializeNewTripReservation,
    createNewTripSuccess,
    clearStore,
    () => createInitialTripEditorQuoteState(),
  ),
  on(fromActions.tripEditorQuoteFindCatalogsRequested, (state): TripEditorQuoteState => {
    return {
      ...state,
      catalogs: { isLoading: true },
    };
  }),
  on(fromActions.tripEditorQuoteFindCatalogsSuccess, (state, { response, tripItineraryId }): TripEditorQuoteState => {
    const options: { [id: string]: TripEditorVehicleCatalog } = {};
    response.catalogs.forEach((catalog, index) => {
      options[catalog.catalogId] = {
        index,
        data: {
          ...catalog,
        },
      };
    });
    return {
      ...state,
      tripItineraryId,
      catalogs: createEntityState(options),
    };
  }),
  on(fromActions.tripEditorQuoteFindCatalogsFailed, (state, { error }): TripEditorQuoteState => {
    return {
      ...state,
      catalogs: createEntityState(undefined, error),
    };
  }),
  on(fromActions.tripEditorQuoteInitStateFromStorage, (state, action): TripEditorQuoteState => {
    return {
      ...action.state,
    };
  }),
  on(fromActions.addVehicleToTripValid, (state, { selection }): TripEditorQuoteState => {
    const { catalogId, count, serviceLocationId } = selection;
    const currentCount = state.selectedCatalogs[catalogId]?.count || 0;
    return {
      ...state,
      selectedCatalogs: {
        ...state.selectedCatalogs,
        [catalogId]: {
          catalogId,
          serviceLocationId,
          count: currentCount + count,
        },
      },
    };
  }),
  on(fromActions.vehicleCountChanged, (state, { selection }): TripEditorQuoteState => {
    return {
      ...state,
      selectedCatalogs: {
        ...state.selectedCatalogs,
        [selection.catalogId]: {
          ...selection,
        },
      },
    };
  }),
  on(fromActions.removeVehicleFromTrip, (state, { catalogId }): TripEditorQuoteState => {
    const selectedCatalogs = { ...state.selectedCatalogs };
    delete selectedCatalogs[catalogId];
    return {
      ...state,
      selectedCatalogs,
    };
  }),
  on(
    fromActions.getTripQuoteRequested,
    fromActions.tripEditorReservationEditPriceRequested,
    (state): TripEditorQuoteState => {
      return {
        ...state,
        tripQuote: {
          entity: state.tripQuote.entity,
          error: undefined,
          isLoading: true,
        },
      };
    },
  ),
  on(
    fromActions.getTripQuoteSuccess,
    fromActions.tripEditorReservationEditPriceSuccess,
    (state, { tripQuote, tripItineraryId }): TripEditorQuoteState => {
      const selectedCatalogs: { [id: string]: VehicleCatalogSelected } = {};
      tripQuote.catalogsItemizedQuote.forEach(({ catalogId, count, serviceLocationId }) => {
        selectedCatalogs[catalogId] = {
          catalogId,
          count,
          serviceLocationId,
        };
      });
      return {
        ...state,
        tripItineraryId,
        selectedCatalogs,
        tripQuote: {
          isLoading: false,
          entity: tripQuote,
        },
      };
    },
  ),
  on(fromActions.getTripQuoteFailed, (state, { error }): TripEditorQuoteState => {
    return {
      ...state,
      tripQuote: {
        isLoading: false,
        error,
      },
    };
  }),
  on(fromActions.tripEditorReservationEditPriceFailed, (state, { error }): TripEditorQuoteState => {
    return {
      ...state,
      tripQuote: {
        ...state.tripQuote, // keep the current value
        isLoading: false,
        error,
      },
    };
  }),
);
