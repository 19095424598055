<div class="add-btn" (click)="onClick()">
  <div class="icon">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        class="fill"
        d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"
        stroke="#81BE5A"
        stroke-width="2"
      />
      <path
        class="fill"
        d="M12 7v10M7 12h10"
        stroke="#81BE5A"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>

  <div class="label">
    <ng-content></ng-content>
  </div>
</div>
