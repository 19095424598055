import { TripPaymentStatus } from '@rootTypes/entities/trip/trip-payment-status';
import { TripListPaymentStatusFilter } from '../types/trip-list';

export const getTripListPaymentStatusFilter = (status: TripPaymentStatus): TripListPaymentStatusFilter => {
  return {
    id: `payment-status,${status}`,
    type: 'payment-status',
    payload: status,
  };
};

export const deserializePaymentStatusFilter = (s: string): TripListPaymentStatusFilter | undefined => {
  if (s && s.startsWith('payment-status')) {
    const value = s.split(',')[1];
    return getTripListPaymentStatusFilter(value as TripPaymentStatus);
  }
  return undefined;
};
