import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'wp-placeholder-box',
  templateUrl: './placeholder-box.component.html',
  styleUrls: ['./placeholder-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlaceholderBoxComponent {
  @Input() height = '16px';
}
