<c-page-header></c-page-header>
<c-home-content>
  <div class="content">
    <div class="title">Failed to load charter config</div>
    <wp-link [href]="homeLink" [isUnderlined]="true" [fontSize]="'16px'">
      Try again
    </wp-link>
  </div>
</c-home-content>
<c-page-footer></c-page-footer>
