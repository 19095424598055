import * as moment from 'moment-timezone';
import { HHMMTimeString } from '../../../entities';

export const hhmmTimeToDisplay = (source: HHMMTimeString): string => {
  if (!source) {
    return null;
  }
  const m = moment(source, ['HHmm']);
  return m.format('hh:mm A');
};
