import { createSelector } from '@ngrx/store';
import { getAccount, isAccountLoading } from './auth/auth.selectors';
import { getAppConfig, isAppConfigLoading } from './app-config.selectors';

export const selectIsHomeGuardResolved = createSelector(getAccount, getAppConfig, (account, appConfig) => {
  return !!account && !!appConfig;
});

export const selectIsHomeGuardLoading = createSelector(isAccountLoading, isAppConfigLoading, (...loadings) => {
  return loadings.some((l) => !!l);
});
