import { TripEditorItineraryEffects } from './trip-editor-itinerary/trip-editor-itinerary.effects';
import { TripEditorContractEffects } from './trip-editor-contract/trip-editor-contract.effects';
import { TripEditorStopsEffects } from './trip-editor-stops/trip-editor-stops.effects';
import { TripEditorQuoteEffects } from './trip-editor-quote/trip-editor-quote.effects';
import { TripEditorBookingContactEffects } from './trip-editor-booking-contact/trip-editor-booking-contact.effects';
import { TripEditorPaymentEffects } from './trip-editor-payment/trip-editor-payment.effects';
import { TripEditorCustomerEffects } from './trip-editor-customer/trip-editor-customer.effects';

export const tripEditorFeatureEffects = [
  TripEditorItineraryEffects,
  TripEditorContractEffects,
  TripEditorStopsEffects,
  TripEditorQuoteEffects,
  TripEditorCustomerEffects,
  TripEditorBookingContactEffects,
  TripEditorPaymentEffects,
];
