import * as moment from 'moment-timezone';
import { DayConfigs, RangePickerDay } from '../entities';
import { RangePickerConfig } from '../entities/range-picker-config';

export enum ViewType {
  YEAR = 'YEARS',
  DAY = 'DAYS',
}

export interface RangePickerState {
  config: {
    entity: RangePickerConfig;
    readonly: boolean;
    updatedAt: number;
  };
  isMouseDown: boolean;
  dayConfigs: {
    entity: DayConfigs;
    updatedAt: number;
  };
  hovered: {
    dates: {
      [dayId: string]: moment.Moment;
    };
    updatedAt: number;
  };
  currentView: {
    viewType: ViewType;
    date: moment.Moment;
    yearViewDate: moment.Moment; // separate date for year view to support cancel
    updatedAt: number;
  };
  selected: {
    dates: {
      [dayId: string]: moment.Moment;
    };
    updatedAt: number;
    userUpdatedAt: number;
  };
  monthsDaysCache: {
    [monthId: string]: RangePickerDay[]; // days
  };
}

export const initialRangePickerStore: RangePickerState = {
  config: {
    readonly: false,
    entity: {
      isStartWeekFromMonday: true,
      numMonthsDisplayed: 2,
      isSingleSelect: false,
      isWeekSelect: false,
      isStrictDisableWeek: false,
      disableDatesBefore: null,
      disableDatesAfter: null,
    },
    updatedAt: 0,
  },
  isMouseDown: false,
  dayConfigs: {
    entity: {},
    updatedAt: 0,
  },
  hovered: {
    dates: {},
    updatedAt: 0,
  },
  currentView: {
    viewType: ViewType.DAY,
    date: moment(),
    yearViewDate: null,
    updatedAt: 0,
  },
  selected: {
    dates: {},
    updatedAt: 0,
    userUpdatedAt: 0,
  },
  monthsDaysCache: {},
};
