import * as moment from 'moment-timezone';
import { HHMMTimeString } from '../../../entities';

export const displayTimeToHHMM = (source): HHMMTimeString => {
  if (!source) {
    return null;
  }
  const m = moment(source, ['h:mm A']);
  return m.format('HHmm');
};
