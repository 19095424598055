import {
  PortalListDateFilter,
  PortalListDateRangeFilter,
  PortalListEntityFilter,
  PortalListFilter,
} from '@rootTypes/utils/list-store-utils/portal-list-filter';
import { PortalListBaseParams } from '@rootTypes/utils/list-store-utils/portal-list-base-state';
import { getFieldNameWithPrefix } from '@rootTypes/utils/list-store-utils/serialize-base-params-to-url';
import {
  getDatePortalListFilter,
  getDateRangePortalListFilter,
  getEntityPortalListFilter,
} from '@rootTypes/utils/list-store-utils/get-portal-filter';
import { PortalEntityType } from '@rootTypes';

export type FilterDeserializer<Filter> = (s: string) => Filter | undefined;

const defaultFilterDeserializers: FilterDeserializer<PortalListFilter>[] = [
  deserializeDateFilter,
  deserializeEntityFilter,
  deserializeDateRangeFilter,
];

export function deserializeBaseParamsFromUrl<Params extends PortalListBaseParams<PortalListFilter>>(
  urlQueryParams: any,
  prefix?: string,
  customFilterDeserializers?: FilterDeserializer<PortalListFilter>[],
): Partial<Params> {
  const result = {} as Partial<Params>;
  if (!urlQueryParams) {
    return result;
  }
  if (urlQueryParams[getFieldNameWithPrefix('status', prefix)]) {
    const status = urlQueryParams[getFieldNameWithPrefix('status', prefix)];
    result.status = status;
  }
  if (urlQueryParams[getFieldNameWithPrefix('page', prefix)]) {
    const pageStr = urlQueryParams[getFieldNameWithPrefix('page', prefix)];
    const page = parseInt(pageStr, 10) - 1;
    if (!isNaN(page)) {
      result.page = page;
    }
  }
  if (urlQueryParams[getFieldNameWithPrefix('pageSize', prefix)]) {
    const pageSizeStr = urlQueryParams[getFieldNameWithPrefix('pageSize', prefix)];
    const pageSize = parseInt(pageSizeStr, 10);
    if (!isNaN(pageSize)) {
      result.pageSize = pageSize;
    }
  }
  if (urlQueryParams[getFieldNameWithPrefix('filters', prefix)]) {
    const filterStr: string = urlQueryParams[getFieldNameWithPrefix('filters', prefix)];
    const deserializers = [...defaultFilterDeserializers, ...(customFilterDeserializers || [])];
    const filters: PortalListFilter[] = [];
    filterStr.split(';').forEach((segment) => {
      let parsedFilter: PortalListFilter;
      let index = 0;
      while (!parsedFilter && index < deserializers.length) {
        parsedFilter = deserializers[index++](segment);
      }
      if (parsedFilter) {
        filters.push(parsedFilter);
      } else {
        console.warn('Unable to parse filter from string: ' + segment);
      }
    });
    result.filters = filters;
  }
  if (urlQueryParams[getFieldNameWithPrefix('searchQuery', prefix)]) {
    const searchQuery = urlQueryParams[getFieldNameWithPrefix('searchQuery', prefix)];
    result.searchQuery = searchQuery;
  }
  return result;
}

// default deserializers

export function deserializeDateFilter(s: string): PortalListDateFilter | undefined {
  const segments = s.split(',');
  if (segments[0] === 'date') {
    return getDatePortalListFilter(segments[1]);
  }
  return undefined;
}

export function deserializeEntityFilter(s: string): PortalListEntityFilter | undefined {
  const segments = s.split(',');
  if (segments[0] === 'entity' && segments.length === 3) {
    return getEntityPortalListFilter(segments[1] as PortalEntityType, segments[2]);
  }
  return undefined;
}

export function deserializeDateRangeFilter(s: string): PortalListDateRangeFilter | undefined {
  const segments = s.split(',');
  if (segments[0] === 'date-range') {
    return getDateRangePortalListFilter(segments[1], segments[2]);
  }
  return undefined;
}
