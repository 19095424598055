import { GetCharterDirectionsResponse } from './index';
import { Address } from '@rootTypes';

export const getCharterDirectionsMockResponse: GetCharterDirectionsResponse = {
  stops: [
    {
      address: {
        formatted_address: 'Harris High School 2 Main St. San Francisco CA 94116',
        geometry: {
          location: { lat: 37.7534616, lng: -122.4216982 },
        },
      } as any as Address,
      arrivalDate: '20220418',
      arrivalTime: '0930',
      departureTime: '0945',
    },
    {
      address: {
        formatted_address: 'Monroe High School 43 Juniper St. San Francisco CA 94110',
        geometry: {
          location: { lat: 37.617717, lng: -122.408912 },
        },
      } as any as Address,
      arrivalDate: '20220418',
      arrivalTime: '1007',
      departureTime: '1015',
    },
  ],
  summary: {
    mileage: 18,
    durationMinutes: 45,
    polyline: '',
  },
};
