import { createSelector, MemoizedSelector } from '@ngrx/store';

import { WpError, TripStops } from '@rootTypes';
import {
  getInBetweenStops,
  getStopsFromTripEditorLeg,
  isTripEditorLegValid,
  TripEditorLegName,
  TripEditorLegStop,
  TripEditorLeg,
  TripEditorLegSummary,
} from './types';
import { selectTripEditorState } from '../trip-editor-feature.selector';
import { SelectOption } from '../../../form-controls';

export const selectTripEditorStopsState = createSelector(selectTripEditorState, (state) => state.stops);

export const selectTripEditorStopsForm = createSelector(selectTripEditorStopsState, (state) => state.form);
export const selectIsRoundTrip = createSelector(selectTripEditorStopsForm, (state) => state.isRoundTrip);
export const selectPassengersCount = createSelector(selectTripEditorStopsForm, (state) => state.numberOfPassengers);
export const selectIsWheelchair = createSelector(selectTripEditorStopsForm, (state) => state.isWheelChairAccessible);
export const selectIsKeepDriver = createSelector(selectTripEditorStopsForm, (state) => state.isKeepDriverForDuration);

export const isKeepDriverValid = createSelector(
  selectTripEditorStopsForm,
  (state) => !(state.isRoundTrip && state.isKeepDriverForDuration === undefined),
);

const selectTripPurposeForm = createSelector(selectTripEditorStopsForm, (state) => state.tripPurposeForm);
const selectTripPurposeSelection = createSelector(selectTripPurposeForm, (state) => state.selection);
export const selectTripPurpose = createSelector(selectTripPurposeSelection, (state) => state?.tripPurpose);
export const selectTripActivityDescription = createSelector(
  selectTripPurposeSelection,
  (state) => state?.activityDescription,
);

const selectTripPurposeFormDataState = createSelector(selectTripPurposeForm, (state) => state.data);
export const selectIsTripPurposeOptionsLoading = createSelector(
  selectTripPurposeFormDataState,
  (state) => state.isLoading,
);
export const selectTripPurposeOptionsError = createSelector(selectTripPurposeFormDataState, (state) => state.error);

const selectTripPurposeFormData = createSelector(selectTripPurposeFormDataState, (state) => state.entity);
export const selectTripPurposeOptions = createSelector(selectTripPurposeFormData, (state) => {
  if (state?.tripPurposeOptions) {
    return state.tripPurposeOptions.map((label): SelectOption<string> => {
      return {
        value: label,
        displayLabel: label,
      };
    });
  }
  return undefined;
});

const selectActivityDescriptionConfigs = createSelector(
  selectTripPurposeFormData,
  (state) => state?.activityDescriptionByTripPurpose,
);

export const selectActivityDescriptionConfigByTripPurpose = createSelector(
  selectActivityDescriptionConfigs,
  selectTripPurpose,
  (configsByTripPurpose, tripPurpose) => {
    if (tripPurpose && configsByTripPurpose) {
      return configsByTripPurpose[tripPurpose];
    }
    return undefined;
  },
);

export const selectActivityDescriptionMaxLength = createSelector(
  selectTripPurposeFormData,
  (state) => state?.activityDescriptionMaxLength,
);

const selectIsTripPurposeFormValid = createSelector(
  selectTripPurposeSelection,
  selectActivityDescriptionConfigs,
  selectActivityDescriptionMaxLength,
  (selection, activityConfigs, maxLength) => {
    if (!selection?.tripPurpose || !activityConfigs) {
      return false;
    }
    if (maxLength && selection.activityDescription?.length > maxLength) {
      return false;
    }
    const configByTripPurpose = activityConfigs[selection.tripPurpose];
    if (configByTripPurpose.isActivityDescriptionRequired) {
      return !!selection.activityDescription;
    }
    return true;
  },
);

export const selectTripEditorStopsStateValid = createSelector(
  selectTripEditorStopsForm,
  isKeepDriverValid,
  selectIsTripPurposeFormValid,
  (state, isKeepDriverValid, isTripPurposeValid) => {
    const isOutboundLegValid = isTripEditorLegValid(state[TripEditorLegName.OUTBOUND]);
    if (!isOutboundLegValid) {
      return false;
    }
    let isReturnLegValid = true;
    if (state.isRoundTrip) {
      isReturnLegValid = isTripEditorLegValid(state[TripEditorLegName.RETURN]);
    }
    if (!isReturnLegValid) {
      return false;
    }
    return (
      typeof state.numberOfPassengers === 'number' &&
      state.numberOfPassengers > 0 &&
      isTripPurposeValid &&
      isKeepDriverValid
    );
  },
);

export const selectTripEditorStops = createSelector(selectTripEditorStopsForm, (state): TripStops => {
  const outboundStops = getStopsFromTripEditorLeg(state[TripEditorLegName.OUTBOUND]).map((stop) => stop.data);
  if (state.isRoundTrip) {
    return {
      outboundStops,
      returnStops: getStopsFromTripEditorLeg(state[TripEditorLegName.RETURN]).map((stop) => stop.data),
    };
  }
  return { outboundStops };
});

export const selectTripEditorLeg = (legName: TripEditorLegName): MemoizedSelector<any, TripEditorLeg> => {
  return createSelector(selectTripEditorStopsForm, (state) => {
    return state[legName];
  });
};

export const selectTripEditorLegStops = (legName: TripEditorLegName): MemoizedSelector<any, TripEditorLegStop[]> => {
  return createSelector(selectTripEditorLeg(legName), (state) => {
    return getStopsFromTripEditorLeg(state);
  });
};

export const selectTripEditorFirstStop = (legName: TripEditorLegName) =>
  createSelector(selectTripEditorLegStops(legName), (stops) => stops[0]);

export const selectTripEditorLastStop = (legName: TripEditorLegName) =>
  createSelector(selectTripEditorLegStops(legName), (stops) => stops[stops.length - 1]);

export const selectMinSuggestedArrivalTimeForReturnLeg = createSelector(
  selectTripEditorLastStop(TripEditorLegName.OUTBOUND),
  (stop) => stop?.data?.arrivalTime,
);

export const selectTripEditorInBetweenStops = (legName: TripEditorLegName) =>
  createSelector(selectTripEditorLegStops(legName), (stops) => getInBetweenStops(stops));

export const selectTripEditorLegSummary = (legName: TripEditorLegName): MemoizedSelector<any, TripEditorLegSummary> => {
  return createSelector(selectTripEditorLeg(legName), (state) => {
    return state?.summary?.entity;
  });
};

export const selectTripEditorLegSummaryLoading = (legName: TripEditorLegName): MemoizedSelector<any, boolean> => {
  return createSelector(selectTripEditorLeg(legName), (state) => {
    return state?.summary?.isLoading;
  });
};

export const selectTripEditorLegSummaryError = (legName: TripEditorLegName): MemoizedSelector<any, WpError> => {
  return createSelector(selectTripEditorLeg(legName), (state) => {
    return state?.summary?.error;
  });
};

const selectValidateTripItineraryState = createSelector(
  selectTripEditorStopsState,
  (state) => state.validateTripItinerary,
);
export const selectValidateTripItineraryLoading = createSelector(
  selectValidateTripItineraryState,
  (state) => state.isLoading,
);
export const selectValidateTripItineraryError = createSelector(
  selectValidateTripItineraryState,
  (state) => state.error,
);
export const selectValidateTripItineraryResult = createSelector(
  selectValidateTripItineraryState,
  (state) => state.entity,
);
