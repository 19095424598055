import { AfterViewInit, Directive, ElementRef, EventEmitter, OnDestroy, Output } from '@angular/core';
import { fromEvent, interval, Subscription } from 'rxjs';
import { startWith, switchMap, takeUntil, tap } from 'rxjs/operators';

@Directive({
  selector: '[wpLongPress]',
})
export class LongPressDirective implements AfterViewInit, OnDestroy {
  @Output() public longPressed = new EventEmitter<void>();

  private sub = new Subscription();

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    const mousedown$ = fromEvent(this.el.nativeElement, 'mousedown');
    const mouseup$ = fromEvent(this.el.nativeElement, 'mouseup');

    const s = mousedown$
      .pipe(
        switchMap(() => {
          const emitInterval = 2000;
          let iteration = 0;
          return interval(1500).pipe(
            startWith(0),
            switchMap(() => {
              iteration = Math.min(iteration + 3, 24);
              return interval(Math.floor(emitInterval / iteration)).pipe(takeUntil(mouseup$));
            }),
            takeUntil(mouseup$),
          );
        }),
      )
      .subscribe(() => {
        this.longPressed.emit();
      });
    this.sub.add(s);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
