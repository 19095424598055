import * as fromDbRideDetails from '../../entities/ride-details-api';

/**
 * return assigned driver id for the ride
 * @param source ride details obj
 */
export const dbRideDetailsToAssignedDriverId = (source: fromDbRideDetails.RideDetails): string | null => {
  if (source.driver_profile) {
    return source.driver_profile.driver_id;
  }
  for (const driverKey in source.driver_assign) {
    if (
      source.driver_assign[driverKey].status === 'RIDE_ASSIGNED' ||
      source.driver_assign[driverKey].status === 'RIDE_ACCEPTED' ||
      source.driver_assign[driverKey].status === 'COMPLETED'
    ) {
      return driverKey;
    }
  }
  return null;
};
