<mat-form-field
  class="wp-textarea"
  [floatLabel]="floatLabel"
  [hideRequiredMarker]="true"
>
  <mat-label>{{label}}</mat-label>
  <div matPrefix>
    <ng-content select="wp-input-prefix"></ng-content>
  </div>
  <textarea
    matInput
    cdkTextareaAutosize
    [placeholder]="placeholder"
    [formControl]="control"
    [required]="required"
    [maxLength]="maxLength"
    tabindex="{{tabIndex}}"
    (input)="inputEvent.emit($event.target['value'])"
  >
  </textarea>
  <div matSuffix>
    <ng-content select="wp-input-suffix"></ng-content>
  </div>
  <mat-hint *ngIf="!!hintMsg"> {{hintMsg}} </mat-hint>
  <mat-error *ngIf="control.invalid && control.hasError('required')">
    Required field
  </mat-error>
  <mat-error
    *ngIf="customErrorMessageKey && !!control.errors && !!control.errors[customErrorMessageKey]"
  >
    {{ control.errors[customErrorMessageKey] }}
  </mat-error>
</mat-form-field>
