import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  OnInit,
} from '@angular/core';

interface LinkBtnCssClasses {
  disabled: boolean;
  bold: boolean;
  underlined: boolean;
}

/**
 * Looks like <a> but behaves like <button>,
 * it does not provide [src] and opening in new tab behavior
 */
@Component({
  selector: 'wp-link-btn',
  templateUrl: './link-btn.component.html',
  styleUrls: ['./link-btn.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkBtnComponent implements OnInit, OnChanges {
  @Input() disabled = false;
  @Input() isBold = false;
  @Input() fontSize = '14px';
  @Input() isUnderlined = true;

  @Output() clicked = new EventEmitter<MouseEvent>();

  public classes: LinkBtnCssClasses;

  constructor() {}

  public ngOnInit(): void {
    this.setClasses();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.setClasses();
  }

  public onClick(event: MouseEvent): void {
    if (this.disabled) {
      event.stopPropagation();
    } else {
      this.clicked.emit(event);
    }
  }

  private setClasses(): void {
    this.classes = {
      disabled: this.disabled,
      bold: this.isBold,
      underlined: this.isUnderlined,
    };
  }
}
