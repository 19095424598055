import { Injectable } from '@angular/core';
import { Observable, of, from } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';

import { ApiService } from './api.service';
import { FirebaseService } from './firebase.service';
import { SessionService } from './session.service';
import { GetAccountResponse } from '@rootTypes/api/get-account';
import { FirebaseUser, UserRole } from '@rootTypes';
import { LocalStorageService } from '../storage.service';
import { UserRoleService } from './user-role.service';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  constructor(
    private api: ApiService,
    private fb: FirebaseService,
    private session: SessionService,
    private localStorage: LocalStorageService,
    private userRoleService: UserRoleService,
  ) {}

  public signInWithToken(token: string): Observable<UserRole> {
    return from(this.signInWithTokenAndGetUserRole(token)).pipe(
      take(1),
      catchError((err) => {
        this.session.logout();
        throw err;
      }),
    );
  }

  public redirectToPortalLogin(isOpenCurrentPageAfterLogin = false): void {
    const userRole = this.userRoleService.getUserRole() || UserRole.enterprise;
    let domain: string;
    if (userRole === UserRole.enterprise) {
      domain = cEnvironment.schoolPortalUrl;
    } else if (userRole === UserRole.admin) {
      domain = cEnvironment.adminPortalUrl;
    } else {
      domain = cEnvironment.vendorPortalUrl;
    }
    let relayState: string;
    if (isOpenCurrentPageAfterLogin) {
      relayState = encodeURIComponent(
        `${cEnvironment.charterLoginInPortalPath}?target=${location.pathname}${location.search}`,
      );
    } else {
      relayState = encodeURIComponent(cEnvironment.charterLoginInPortalPath);
    }
    const url = `${domain}/login?RelayState=${relayState}`;
    window.location.replace(url);
  }

  public checkFirebaseAuth(): Observable<FirebaseUser | null> {
    return from(this.initialFbAuthResolver()).pipe(
      map((fbUser) => {
        if (fbUser) {
          return { userId: fbUser.uid };
        } else {
          return null;
        }
      }),
      catchError(() => {
        return of(null);
      }),
    );
  }

  public signOut(): Observable<void> {
    return from(this.session.logout());
  }

  public getAccount(): Observable<GetAccountResponse> {
    return this.api.getAccount();
  }

  private initialFbAuthResolver(): Promise<firebase.User | null> {
    return new Promise<firebase.User | null>((resolve) => {
      let off: any;
      function callback(user: firebase.User | null): void {
        off();
        resolve(user);
      }
      // AngularFire approach was used in checking user authentication status.
      // We set an observer on firebase.Auth object
      // and remove it once firebase.Auth has been resolved
      off = this.fb.onAuthStateChanged(callback);
    });
  }

  private async signInWithTokenAndGetUserRole(token: string): Promise<UserRole> {
    await this.session.loginWithToken(token);
    return await this.fb.getUserRole();
  }
}
