import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import * as fromTypes from './types';
import { RangePickerDay } from './types/entities/range-picker-day';
import { map, distinctUntilChanged, shareReplay, tap, switchMap } from 'rxjs/operators';
import * as moment from 'moment-timezone';
import { getMomentId } from './types/utils';
import { getWeekSpanForDate } from '@rootTypes/utils/common/date/get-current-week-span';

@Injectable()
export class DateRangePickerService implements fromTypes.RangePickerStore {
  private hoveredDates$!: Observable<{ [dayId: string]: moment.Moment }>;
  private selectedDatesObj$: Observable<{ [dateId: string]: moment.Moment }>;
  private dayConfigs$: Observable<fromTypes.DayConfigs>;
  private pickerConfig$: Observable<fromTypes.RangePickerConfig>;

  private state$: BehaviorSubject<fromTypes.RangePickerState> = new BehaviorSubject<fromTypes.RangePickerState>(
    fromTypes.initialRangePickerStore,
  );

  constructor() {
    this.hoveredDates$ = this.state$.asObservable().pipe(
      map((state) => state.hovered),
      distinctUntilChanged((prev, curr) => prev.updatedAt === curr.updatedAt),
      map((state) => state.dates),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.selectedDatesObj$ = this.state$.asObservable().pipe(
      map((state) => state.selected),
      distinctUntilChanged(
        (prev: fromTypes.RangePickerState['selected'], curr: fromTypes.RangePickerState['selected']) =>
          prev.updatedAt === curr.updatedAt,
      ),
      map((s) => s.dates),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.dayConfigs$ = this.state$.asObservable().pipe(
      map((state) => state.dayConfigs),
      distinctUntilChanged((prev, curr) => prev.updatedAt === curr.updatedAt),
      map((state) => state.entity),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.pickerConfig$ = this.state$.asObservable().pipe(
      map((state) => state.config),
      distinctUntilChanged((prev, curr) => prev.updatedAt === curr.updatedAt),
      map((state) => state.entity),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    // this.state$.asObservable()
    //     .subscribe(val => {
    //       console.log('Store value:');
    //       console.log(val.config);
    //     })
  }
  setDayConfigs(dayConfigs: fromTypes.DayConfigs): void {
    const prevState = this.state$.value;
    this.state$.next({
      ...prevState,
      dayConfigs: {
        entity: dayConfigs,
        updatedAt: new Date().getTime(),
      },
    });
  }
  getConfigs(): Observable<fromTypes.RangePickerConfig> {
    return this.state$.asObservable().pipe(
      map((state) => state.config),
      distinctUntilChanged((prev, curr) => prev.updatedAt === curr.updatedAt),
      map((state) => state.entity),
    );
  }
  setIsMouseDown(isDown: boolean): void {
    this.state$.next({
      ...this.state$.value,
      isMouseDown: isDown,
    });
  }
  setDisableAfterDate(date: moment.Moment): void {
    const prevState = this.state$.value;
    const { isWeekSelect, isStrictDisableWeek } = prevState.config.entity;
    let disableAfter = date;
    if (date && isWeekSelect && isStrictDisableWeek) {
      disableAfter = moment(disableAfter.startOf('week').subtract(1, 'day'));
    }
    this.state$.next({
      ...prevState,
      config: {
        ...prevState.config,
        entity: {
          ...prevState.config.entity,
          disableDatesAfter: disableAfter,
        },
        updatedAt: new Date().getTime(),
      },
    });
  }
  setDisableBeforeDate(date: moment.Moment): void {
    const prevState = this.state$.value;
    const { isWeekSelect, isStrictDisableWeek } = prevState.config.entity;
    let disableBefore = date;
    if (date && isWeekSelect && isStrictDisableWeek) {
      disableBefore = moment(disableBefore.endOf('week').add(1, 'day'));
    }
    this.state$.next({
      ...prevState,
      config: {
        ...prevState.config,
        entity: {
          ...prevState.config.entity,
          disableDatesBefore: disableBefore,
        },
        updatedAt: new Date().getTime(),
      },
    });
  }
  setIsSingleSelect(value: boolean): void {
    const prevState = this.state$.value;
    this.state$.next({
      ...prevState,
      config: {
        ...prevState.config,
        entity: {
          ...prevState.config.entity,
          isSingleSelect: value,
        },
      },
    });
  }
  setIsWeekSelect(value: boolean): void {
    const prevState = this.state$.value;
    this.state$.next({
      ...prevState,
      config: {
        ...prevState.config,
        entity: {
          ...prevState.config.entity,
          isWeekSelect: value,
        },
      },
    });
  }
  setIsStrictDisableWeek(value: boolean): void {
    const prevState = this.state$.value;
    this.state$.next({
      ...prevState,
      config: {
        ...prevState.config,
        entity: {
          ...prevState.config.entity,
          isStrictDisableWeek: value,
        },
      },
    });
  }
  setIsStartWeekFromMonday(value: boolean): void {
    const prevState = this.state$.value;
    this.state$.next({
      ...prevState,
      config: {
        ...prevState.config,
        entity: {
          ...prevState.config.entity,
          isStartWeekFromMonday: value,
        },
      },
    });
  }
  setSelectedDates(mm: moment.Moment[]): void {
    let newSelectedObj: { [dateId: string]: moment.Moment };
    if (mm) {
      const { isWeekSelect } = this.state$.value.config.entity;
      newSelectedObj = mm.reduce((prev, curr) => {
        const currMap = {};
        // if weekly select, select whole week of the moment
        if (isWeekSelect) {
          const week = this.getMomentsForWeek(curr);
          week.forEach((m) => {
            currMap[fromTypes.utils.getMomentId(m)] = m;
          });
        } else {
          currMap[fromTypes.utils.getMomentId(curr)] = curr;
        }
        return { ...prev, ...currMap };
      }, {});
    } else {
      newSelectedObj = {};
    }
    const state = this.state$.value;
    this.state$.next({
      ...state,
      selected: {
        ...state.selected,
        dates: { ...newSelectedObj },
        updatedAt: new Date().getTime(),
      },
    });
  }

  onYearClicked(year: fromTypes.RangePickerYear): void {
    const prev = this.state$.value;
    this.state$.next({
      ...prev,
      currentView: {
        ...prev.currentView,
        viewType: fromTypes.ViewType.DAY,
        date: year.moment,
        updatedAt: new Date().getTime(),
      },
    });
  }

  selectedChanged$(): Observable<moment.Moment[]> {
    return this.selectedDatesObj$.pipe(map((dateObj) => Object.keys(dateObj).map((id) => dateObj[id])));
  }

  selectedChangedByUserAction$(): Observable<moment.Moment[]> {
    return this.state$.asObservable().pipe(
      map((state) => state.selected),
      distinctUntilChanged((prev, curr) => prev.userUpdatedAt === curr.userUpdatedAt),
      map((state) => state.dates),
      map((dateObj) => Object.values(dateObj)),
    );
  }

  getCurrentViewType$(): Observable<fromTypes.ViewType> {
    return this.state$.asObservable().pipe(
      map((state) => state.currentView.viewType),
      distinctUntilChanged(),
    );
  }

  setViewType(viewType: fromTypes.ViewType): void {
    const prev = this.state$.value;
    this.state$.next({
      ...prev,
      currentView: {
        ...prev.currentView,
        yearViewDate: moment(prev.currentView.date),
        viewType,
        updatedAt: new Date().getTime(),
      },
    });
  }

  dayClicked(m: moment.Moment): void {
    const state = this.state$.value;
    const prevSelected = state.selected.dates;
    const isSingleSelect = state.config.entity.isSingleSelect;
    const isWeekSelect = state.config.entity.isWeekSelect;
    let newSelected;
    if (isWeekSelect) {
      newSelected = this.getSelectedOnDayClickedForWeekSelectMode(m, isSingleSelect, prevSelected);
    } else {
      newSelected = this.getSelectedOnDayClickedForDateSelectMode(m, isSingleSelect, prevSelected);
    }
    this.state$.next({
      ...state,
      selected: {
        ...state.selected,
        dates: { ...newSelected },
        updatedAt: new Date().getTime(),
        userUpdatedAt: new Date().getTime(),
      },
    });
  }

  private getSelectedOnDayClickedForDateSelectMode(
    day: moment.Moment,
    isSingleSelect: boolean,
    prevSelected: { [dayId: string]: moment.Moment },
  ): { [dayId: string]: moment.Moment } {
    const dayId = fromTypes.utils.getMomentId(day);
    const wasSelected = prevSelected[dayId];
    let newSelected;
    if (wasSelected) {
      if (!isSingleSelect) {
        delete prevSelected[dayId];
      }
      newSelected = prevSelected;
    } else {
      if (isSingleSelect) {
        newSelected = { [dayId]: day };
      } else {
        newSelected = { ...prevSelected, [dayId]: day };
      }
    }
    return newSelected;
  }

  private getSelectedOnDayClickedForWeekSelectMode(
    day: moment.Moment,
    isSingleSelect: boolean,
    prevSelected: { [dayId: string]: moment.Moment },
  ): { [dayId: string]: moment.Moment } {
    const dayId = fromTypes.utils.getMomentId(day);
    const wasSelected = prevSelected[dayId];
    const weekMoments = this.getMomentsForWeek(day);
    let newSelected;
    if (isSingleSelect) {
      if (wasSelected) {
        newSelected = {};
      } else {
        newSelected = {};
        weekMoments.forEach((m) => {
          newSelected[fromTypes.utils.getMomentId(m)] = m;
        });
      }
    } else {
      if (wasSelected) {
        newSelected = { ...prevSelected };
        weekMoments.forEach((m) => {
          delete newSelected[fromTypes.utils.getMomentId(m)];
        });
      } else {
        newSelected = { ...prevSelected };
        weekMoments.forEach((m) => {
          newSelected[fromTypes.utils.getMomentId(m)] = m;
        });
      }
    }
    return newSelected;
  }

  weekdays$(): Observable<fromTypes.RangePickerWeekday[]> {
    const isMondayFirst$ = this.state$.pipe(
      map((state) => state.config.entity.isStartWeekFromMonday),
      distinctUntilChanged(),
    );
    return isMondayFirst$.pipe(
      map((isMondayFirst) => {
        const result = getWeekSpanForDate(new Date(), isMondayFirst).map((d) => {
          const m = moment(d);
          return {
            moment: m,
            label: fromTypes.utils.getMomentWeekdayLabel(m),
          };
        });
        return result;
      }),
    );
  }

  decades$(): Observable<fromTypes.RangePickerDecade[]> {
    const numMonthsDisplayed$ = this.state$.asObservable().pipe(
      map((state) => state.config),
      distinctUntilChanged((prev, curr) => prev.updatedAt === curr.updatedAt),
      map((state) => state.entity.numMonthsDisplayed),
    );
    const currentMonth$ = this.state$.asObservable().pipe(
      map((state) => state.currentView),
      distinctUntilChanged((prev, curr) => prev.updatedAt === curr.updatedAt),
      map((state) => state.yearViewDate),
    );
    return combineLatest([currentMonth$, numMonthsDisplayed$]).pipe(
      map(([currMonth, numDisplayed]) => {
        const result = [] as fromTypes.RangePickerDecade[];
        for (let i = 0; i < numDisplayed * fromTypes.yearsInDecadeCount; i += fromTypes.yearsInDecadeCount) {
          const start = moment(moment(currMonth).add(i, 'year'));
          const end = moment(moment(start).add(fromTypes.yearsInDecadeCount, 'year'));
          result.push({
            moment: start,
            label: fromTypes.utils.getMomentDecadeLabel(start, end),
          });
        }
        return result;
      }),
    );
  }

  yearsForDecade$(decade: fromTypes.RangePickerDecade): Observable<fromTypes.RangePickerYear[]> {
    const result: fromTypes.RangePickerYear[] = [];
    for (let i = 0; i < fromTypes.yearsInDecadeCount; i++) {
      const m = moment(moment(decade.moment).add(i, 'year'));
      result.push({
        label: m.format('YYYY'),
        moment: m,
      });
    }
    return of(result);
  }

  public forward(): void {
    const state = this.state$.value;
    if (state.currentView.viewType === fromTypes.ViewType.DAY) {
      this.forwardDayView();
    } else if (state.currentView.viewType === fromTypes.ViewType.YEAR) {
      this.forwardYearView();
    }
  }
  public forwardDayView(): void {
    const state = this.state$.value;
    const currentMonth = state.currentView.date;
    const nextMonth = moment(moment(currentMonth).add(1, 'month'));
    this.state$.next({
      ...state,
      currentView: {
        ...state.currentView,
        date: nextMonth,
        updatedAt: new Date().getTime(),
      },
    });
  }
  public forwardYearView(): void {
    const state = this.state$.value;
    const currentMonth = state.currentView.yearViewDate;
    const nextMonth = moment(
      moment(currentMonth).add(fromTypes.yearsInDecadeCount * state.config.entity.numMonthsDisplayed, 'year'),
    );
    this.state$.next({
      ...state,
      currentView: {
        ...state.currentView,
        yearViewDate: nextMonth,
        updatedAt: new Date().getTime(),
      },
    });
  }
  public backward(): void {
    const state = this.state$.value;
    if (state.currentView.viewType === fromTypes.ViewType.DAY) {
      this.backwardDayView();
    } else if (state.currentView.viewType === fromTypes.ViewType.YEAR) {
      this.backwardYearView();
    }
  }
  public backwardDayView(): void {
    const state = this.state$.value;
    const currentMonth = state.currentView.date;
    const prevMonth = moment(moment(currentMonth).subtract(1, 'month'));
    this.state$.next({
      ...state,
      currentView: {
        ...state.currentView,
        date: prevMonth,
        updatedAt: new Date().getTime(),
      },
    });
  }
  public backwardYearView(): void {
    const state = this.state$.value;
    const currentMonth = state.currentView.yearViewDate;
    const nextMonth = moment(
      moment(currentMonth).subtract(fromTypes.yearsInDecadeCount * state.config.entity.numMonthsDisplayed, 'year'),
    );
    this.state$.next({
      ...state,
      currentView: {
        ...state.currentView,
        yearViewDate: nextMonth,
        updatedAt: new Date().getTime(),
      },
    });
  }
  public months$(): Observable<fromTypes.RangePickerMonth[]> {
    const numMonthsDisplayed$ = this.state$.asObservable().pipe(
      map((state) => state.config),
      distinctUntilChanged((prev, curr) => prev.updatedAt === curr.updatedAt),
      map((state) => state.entity.numMonthsDisplayed),
    );
    const currentMonth$ = this.state$.asObservable().pipe(
      map((state) => state.currentView),
      distinctUntilChanged((prev, curr) => prev.updatedAt === curr.updatedAt),
      map((state) => state.date),
    );
    return combineLatest([currentMonth$, numMonthsDisplayed$]).pipe(
      map(([currMonth, numDisplayed]) => {
        const result = [] as fromTypes.RangePickerMonth[];
        for (let i = 0; i < numDisplayed; i++) {
          const m = moment(moment(currMonth).add(i, 'month'));
          result.push({
            moment: m,
            label: fromTypes.utils.getMomentMonthLabel(m, true),
          });
        }
        return result;
      }),
    );
  }

  public isCurrentlySelectedYear(year: fromTypes.RangePickerYear): Observable<boolean> {
    return this.state$.asObservable().pipe(
      map((state) => state.currentView),
      distinctUntilChanged((prev, curr) => prev.updatedAt === curr.updatedAt),
      map((state) => state.date),
      map((currentDate) => {
        return currentDate.isSame(year.moment, 'year');
      }),
    );
  }

  public daysForMonth$(month: moment.Moment): Observable<RangePickerDay[]> {
    return this.state$.pipe(
      map((state) => state.config.entity.isStartWeekFromMonday),
      distinctUntilChanged(),
      switchMap((isStartFromMonday) => {
        const state = this.state$.value;
        const monthId = fromTypes.utils.getMomentMonthId(month) + isStartFromMonday;
        let resultDays: RangePickerDay[];
        if (state.monthsDaysCache[monthId]) {
          resultDays = state.monthsDaysCache[monthId];
        } else {
          resultDays = fromTypes.utils.getDaysForMonth(month, isStartFromMonday);
          this.state$.next({
            ...state,
            monthsDaysCache: {
              ...state.monthsDaysCache,
              [monthId]: resultDays,
            },
          });
        }
        const daysWithUpdates = resultDays.map((day) => {
          return this.getStateForDate(day, null, state.selected.dates, state.dayConfigs.entity, state.config.entity);
        });
        return of([...daysWithUpdates]);
      }),
    );
  }

  public dayChanges$(source: RangePickerDay): Observable<RangePickerDay> {
    return combineLatest([this.hoveredDates$, this.selectedDatesObj$, this.dayConfigs$, this.pickerConfig$]).pipe(
      map(([hovered, selected, dayConfigs, pickerConfig]) =>
        this.getStateForDate(source, hovered, selected, dayConfigs, pickerConfig),
      ),
    );
  }

  public setCurrentMonth(m: moment.Moment): void {
    this.state$.next({
      ...this.state$.value,
      currentView: {
        ...this.state$.value.currentView,
        date: m,
        updatedAt: new Date().getTime(),
      },
    });
  }

  public setHovered(m: moment.Moment | null): void {
    const state = this.state$.value;
    const hovered = this.getHoveredForDate(m);
    const hoveredMap = hovered.reduce((p, c) => {
      return { ...p, [getMomentId(c)]: c };
    }, {});
    this.state$.next({
      ...state,
      hovered: {
        ...state.hovered,
        dates: hoveredMap,
        updatedAt: new Date().getTime(),
      },
    });
    if (hovered.length) {
      if (state.isMouseDown) {
        this.dayClicked(m);
      }
    }
  }

  public setMonthView(month: number): void {
    const state = this.state$.value;
    this.state$.next({
      ...state,
      config: {
        ...state.config,
        entity: { ...state.config.entity, numMonthsDisplayed: month },
      },
    });
  }

  public setReadonly(isReadonly: boolean): void {
    const state = this.state$.value;
    this.state$.next({
      ...state,
      config: {
        ...state.config,
        readonly: isReadonly,
      },
    });
  }

  public initStore(): void {
    this.state$.next(fromTypes.initialRangePickerStore);
  }

  public isReadOnly(): Observable<boolean> {
    return this.state$.asObservable().pipe(map((state) => state.config.readonly));
  }

  private getStateForDate(
    source: RangePickerDay,
    hovered: { [dateId: string]: moment.Moment },
    selected: { [dateId: string]: moment.Moment },
    dayConfigs: fromTypes.DayConfigs,
    pickerConfig: fromTypes.RangePickerConfig,
  ): RangePickerDay {
    let css = '';
    const today = moment();
    if (today.isSame(source.moment, 'day')) {
      css += ' today';
    }
    if (source.isDisplayed) {
      css += ' displayed ';
    } else {
      return source;
    }
    const currentDateId = fromTypes.utils.getMomentId(source.moment);

    const { disableDatesAfter, disableDatesBefore } = pickerConfig;

    if (this.isDateDisabled(source.moment, disableDatesBefore, disableDatesAfter)) {
      source.isDisabled = true;
      css += ' disabled';
    } else {
      if (hovered && hovered[currentDateId]) {
        css += 'hovered';
      }
    }
    if (selected[currentDateId]) {
      css += ' selected';
    }
    const dayConfigForDate = dayConfigs[currentDateId];
    const isColor = dayConfigForDate ? !!dayConfigForDate.color : false;
    if (isColor) {
      css += ' color ' + ' ' + dayConfigForDate.color;
    }
    const tooltip = dayConfigForDate ? dayConfigForDate.tooltip : null;
    return {
      ...source,
      tooltip,
      css,
    };
  }

  private getHoveredForDate(m: moment.Moment | null): moment.Moment[] {
    if (!m) {
      return [];
    }
    const currState = this.state$.value;
    const config = currState.config.entity;
    const isWeekSelect = config.isWeekSelect;
    if (!isWeekSelect) {
      return m ? [m] : [];
    }
    return this.getMomentsForWeek(m);
  }

  private getMomentsForWeek(m: moment.Moment): moment.Moment[] {
    const { disableDatesBefore, disableDatesAfter, isStartWeekFromMonday } = this.state$.value.config.entity;
    let startOfWeek = isStartWeekFromMonday ? moment(m.startOf('isoWeek')) : moment(m.startOf('week'));
    if (disableDatesBefore && startOfWeek.isBefore(disableDatesBefore)) {
      startOfWeek = moment(disableDatesBefore);
    }
    let endOfWeek = isStartWeekFromMonday ? moment(m.endOf('isoWeek')) : moment(m.endOf('week'));
    if (disableDatesAfter && endOfWeek.isAfter(disableDatesAfter)) {
      endOfWeek = moment(disableDatesAfter);
    }
    const result = [];
    for (let c = moment(startOfWeek); c.isSameOrBefore(endOfWeek); c = moment(c.add(1, 'day'))) {
      result.push(moment(c));
    }
    return result;
  }

  private isDateDisabled(date: moment.Moment, disableBefore: moment.Moment, disableAfter: moment.Moment): boolean {
    if (disableBefore && date.isBefore(disableBefore, 'day')) {
      return true;
    }
    if (disableAfter && date.isAfter(disableAfter, 'day')) {
      return true;
    }
    return false;
  }
}
