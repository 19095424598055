import { Address, TripType } from '@rootTypes';
import { GetCharterTripItineraryResponse } from '@rootTypes/api';

export const getCharterTripItineraryMockResponse: GetCharterTripItineraryResponse = {
  tripItineraryId: 'tripItineraryId',
  districtId: 'districtId',
  campusId: 'campusId',
  regionId: 'SFBayArea',
  regionLabel: 'SF Bay Area',
  contractId: 'contractId',
  outbound: {
    stops: [
      {
        address: {
          formatted_address: 'Harris High School 2 Main St. San Francisco CA 94116',
          geometry: {
            location: { lat: 37.7534616, lng: -122.4216982 },
          },
        } as any as Address,
        arrivalDate: '20220418',
        arrivalTime: '0930',
        departureTime: '0945',
      },
      {
        address: {
          formatted_address: 'Monroe High School 43 Juniper St. San Francisco CA 94110',
          geometry: {
            location: { lat: 37.617717, lng: -122.408912 },
          },
        } as any as Address,
        arrivalDate: '20220418',
        arrivalTime: '1007',
        departureTime: '1015',
      },
      {
        address: {
          formatted_address: '34 Fulton St, San Jose CA 94503',
          geometry: {
            location: { lat: 37.352288, lng: -121.954584 },
          },
        } as any as Address,
        arrivalDate: '20220418',
        arrivalTime: '1115',
      },
    ],
    summary: {
      totalMiles: 18,
      durationMinutes: 45,
      polyline: '',
    },
  },
  return: {
    stops: [
      {
        address: {
          formatted_address: '34 Fulton St, San Jose CA 94503',
          geometry: {
            location: { lat: 37.352288, lng: -121.954584 },
          },
        } as any as Address,
        arrivalDate: '20220418',
        arrivalTime: '1430',
        departureTime: '1445',
      },
      {
        address: {
          formatted_address: 'Monroe High School 43 Juniper St. San Francisco CA 94110',
          geometry: {
            location: { lat: 37.617717, lng: -122.408912 },
          },
        } as any as Address,
        arrivalDate: '20220418',
        arrivalTime: '1540',
        departureTime: '1545',
      },
      {
        address: {
          formatted_address: 'Harris High School 2 Main St. San Francisco CA 94116',
          geometry: {
            location: { lat: 37.7534616, lng: -122.4216982 },
          },
        } as any as Address,
        arrivalDate: '20220418',
        arrivalTime: '1605',
      },
    ],
    summary: {
      totalMiles: 101,
      durationMinutes: 395,
      polyline: '',
    },
  },
  numberOfPassengers: 64,
  tripType: TripType.ROUND_TRIP,
  totalDurationMinutes: 440,
  totalMiles: 119,
  isWheelChairAccessible: true,
  isKeepDriverForDuration: true,
  tripPurpose: 'purpose1',
};
