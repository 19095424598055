import { TripListQuickFilter, TripListQuickFilterType } from '../types/trip-list';
import { FilterDeserializer } from '@rootTypes/utils/list-store-utils/deserialize-base-params-from-url';

export const getTripListQuickFilter = (value: TripListQuickFilterType): TripListQuickFilter => {
  return {
    id: `quick-filter,${value}`,
    type: 'quick-filter',
    payload: value,
  };
};

export const deserializeQuickFilter: FilterDeserializer<TripListQuickFilter> = (
  s: string,
): TripListQuickFilter | undefined => {
  if (s && s.startsWith('quick-filter')) {
    const value = s.split(',')[1];
    return getTripListQuickFilter(value as TripListQuickFilterType);
  }
  return undefined;
};
