import { Pipe, PipeTransform } from '@angular/core';
import { PortalEntityType } from '@rootTypes';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { getEntity } from '../../store/entities-data/selectors';
import { map } from 'rxjs/operators';
import { getPortalEntityRequestedIfAbsent } from '../../store/entities-data/actions';

@Pipe({
  name: 'displayEntityLabel',
})
export class DisplayEntityLabelPipe implements PipeTransform {
  constructor(private store: Store) {}

  transform(entityId: string, entityType: PortalEntityType, placeholder?: string, prefix?: string): Observable<string> {
    // Fix NG0600, https://github.com/ngrx/platform/issues/3892#issuecomment-1542791068
    // TODO: do not use dispatch in pipe
    setTimeout(() => {
      this.store.dispatch(getPortalEntityRequestedIfAbsent({ entityId, entityType }));
    });
    return this.store
      .select(getEntity(entityType, entityId))
      .pipe(map((entity) => (entity?.label ? (prefix || '') + entity?.label : placeholder)));
  }
}
