import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { tripEditorFeatureKey } from './feature-key';
import { tripEditorFeatureReducer } from './trip-editor-feature.reducer';
import { tripEditorFeatureEffects } from './trip-editor-feature.effects';

@NgModule({
  imports: [
    StoreModule.forFeature(tripEditorFeatureKey, tripEditorFeatureReducer),
    EffectsModule.forFeature(tripEditorFeatureEffects),
  ],
})
export class TripEditorStoreModule {}
