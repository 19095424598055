export type PortalEntity = {
  entityId: string;
  type: PortalEntityType;
  label?: string;
};

export enum PortalEntityType {
  DRIVER = 'driver',
  PARENT = 'parent',
  STUDENT = 'student',
  ZUM_EMPLOYEE = 'zum_employee',
  VENDOR_EMPLOYEE = 'vendor_employee',
  SCHOOL_EMPLOYEE = 'school_employee',
  DISTRICT = 'district',
  CAMPUS = 'campus',
  SCHOOL = 'school',
  VENDOR = 'vendor',
  VEHICLE = 'vehicle',
  ROUTE = 'route',
  DRIVER_DOCUMENT = 'driver_document',
  YARD = 'yard',
  STUDENT_CHANGE_REQUEST = 'student_change_request',
  RIDE = 'ride',
  ACTIVITY_TIME_ENTRY = 'ride',
  ROUTE_GROUP = 'route_group',
  USER_ACCESS_POLICY = 'user_access_policy',
  CHARTER_TRIPS = 'charter_trip',
}

export enum EntityStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  ANY = 'ANY',
}

/**
 * We can extend the base portal entity with additional data
 * In order to do this, extend the search result convertor
 * in src/app/api/common/convertors/entity-search-common/get-portal-entity-from-db.ts
 */
export type VendorPortalEntity = PortalEntity;

export interface VehiclePortalEntity extends PortalEntity {
  makeAndModel: string;
  licenseStateAndNumber: string;
  assignedDriverText: 'Assigned' | 'Unassigned';
  assignedDriverId?: string;
  imagePath?: string;
  vehicleDisplayId?: string;
}

export interface CampusPortalEntity extends PortalEntity {
  districtId: string;
  campusLevels?: any[]; // this field should be added by BE.
}

export interface SchoolEmployeePortalEntity extends PortalEntity {
  email: string;
  organizationName?: string;
}
