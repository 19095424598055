import { Pipe, PipeTransform } from '@angular/core';
import { centsToDollars } from '@rootTypes/utils';
import { CurrencyPipe } from '@angular/common';

@Pipe({ name: 'centsToDollars' })
export class CentsToDollarsPipe implements PipeTransform {
  constructor(private currency: CurrencyPipe) {}

  public transform(cents: number, isZeroShown = true): string {
    const dollars = centsToDollars(cents);
    if (isZeroShown) {
      return this.currency.transform(dollars);
    }
    if (dollars) {
      return this.currency.transform(dollars);
    }
    return '';
  }
}
