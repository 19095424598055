import { createSelector } from '@ngrx/store';
import { selectTripEditorState } from '../trip-editor-feature.selector';
import { PaymentMethod } from '@rootTypes';

const selectPaymentState = createSelector(selectTripEditorState, (state) => state.payment);

export const selectTripEditorPaymentOption = createSelector(selectPaymentState, (state) => state.paymentOption);

export const selectTripEditorPaymentMethod = createSelector(selectPaymentState, (state) => state.paymentMethod);

export const selectIsBillNowDisabled = createSelector(selectPaymentState, (state) => state.isBillNowDisabled);

export const selectIsBillLaterDisabled = createSelector(selectPaymentState, (state) => state.isBillLaterDisabled);

export const selectTripEditorIsCardPayment = createSelector(
  selectTripEditorPaymentMethod,
  (paymentMethod) => paymentMethod === PaymentMethod.CARD,
);

export const selectTripEditorIsCheckPayment = createSelector(
  selectTripEditorPaymentMethod,
  (paymentMethod) => paymentMethod === PaymentMethod.CHECK,
);
