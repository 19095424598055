import { createSelector } from '@ngrx/store';
import { selectGeneralContractDistrictId } from '../../../../store/app-config.selectors';
import { selectTripEditorState } from '../trip-editor-feature.selector';

const selectTripCustomerState = createSelector(selectTripEditorState, (state) => state.customer);

export const selectTripCustomer = createSelector(selectTripCustomerState, (state) => state.value);
export const selectTripCustomerDistrictName = createSelector(selectTripCustomer, (state) => state?.organization?.label);
export const selectTripCustomerCampusName = createSelector(selectTripCustomer, (state) => state?.branch?.label);
export const selectTripCustomerDisabled = createSelector(selectTripCustomerState, (state) => state.isDisabled);

export const selectTripCustomerDistrictId = createSelector(selectTripCustomer, (state) => state?.organization?.id);
export const selectIsGeneralContractDistrictSelected = createSelector(
  selectTripCustomerDistrictId,
  selectGeneralContractDistrictId,
  (selectedDistrictId, generalContractDistrictId) => {
    if (selectedDistrictId && generalContractDistrictId) {
      return selectedDistrictId === generalContractDistrictId;
    }
    return false;
  },
);

export const selectIsTripCustomerSelectionValid = createSelector(
  selectIsGeneralContractDistrictSelected,
  selectTripCustomer,
  (isGeneralContractDistrictSelected, customer) => {
    if (!(typeof isGeneralContractDistrictSelected === 'boolean' && !!customer)) {
      return false;
    }
    if (isGeneralContractDistrictSelected) {
      return true;
    }
    return !!customer.organization?.id && !!customer.branch?.id;
  },
);

export const selectIsSelectCampusTooltipShown = createSelector(
  selectTripCustomerState,
  (state) => state.isSelectCampusTooltipShown,
);
