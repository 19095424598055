import { Component, ChangeDetectionStrategy } from '@angular/core';
import { iconPaths } from '@rootTypes/utils';

@Component({
  selector: 'c-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageHeaderComponent {
  public logo = iconPaths.ZUM_LOGO_HORIZONTAL;
}
