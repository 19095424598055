import { GetCharterTripItineraryResponse } from '@rootTypes/api';
import { TripEditorStopsForm, TripEditorLegName } from '../entities';
import { createTripEditorLegFromItinerary } from './create-trip-editor-leg-from-itinerary';
import { TripType } from '@rootTypes';
import { TripPurposeFormData } from '../entities/trip-purpose-form-data';

export const createTripEditorStopsFormFromItinerary = (
  source: GetCharterTripItineraryResponse,
  tripPurposeData?: TripPurposeFormData,
): TripEditorStopsForm => {
  const { numberOfPassengers, isWheelChairAccessible, isKeepDriverForDuration, tripPurpose, activityDescription } =
    source;
  const isRoundTrip = source.tripType === TripType.ROUND_TRIP;

  const form: TripEditorStopsForm = {
    isRoundTrip,
    numberOfPassengers,
    isWheelChairAccessible,
    isKeepDriverForDuration,
    tripPurposeForm: {
      data: tripPurposeData ? { isLoading: false, entity: tripPurposeData } : { isLoading: false },
      selection: { tripPurpose },
    },
    [TripEditorLegName.OUTBOUND]: createTripEditorLegFromItinerary(source, TripEditorLegName.OUTBOUND),
  };
  if (isRoundTrip) {
    form[TripEditorLegName.RETURN] = createTripEditorLegFromItinerary(source, TripEditorLegName.RETURN);
  }
  if (activityDescription) {
    form.tripPurposeForm.selection.activityDescription = activityDescription;
  }
  return form;
};
