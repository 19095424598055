import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'c-add-link-btn',
  templateUrl: './add-link-btn.component.html',
  styleUrls: ['./add-link-btn.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddLinkBtnComponent {
  @Input() disabled = false;

  @Output() clicked = new EventEmitter<void>();

  public onClick(): void {
    if (!this.disabled) {
      this.clicked.emit();
    }
  }
}
