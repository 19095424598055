import { Pipe, PipeTransform } from '@angular/core';
import { YYYYMMDDString } from '@rootTypes';
import { YearMonthDay } from 'src/app/types/utils/common/date';

@Pipe({
  name: 'displayYYYYMMDD',
})
export class DisplayYYYYMMDDPipe implements PipeTransform {
  transform(value: YYYYMMDDString, format?: string): string {
    if (!value) {
      return '--';
    }
    const formatter = new YearMonthDay(value);
    return formatter.formatTo(format || 'ddd, MMM DD YYYY');
  }
}
