import { Address } from '@rootTypes';

export const getDisplayAddress = (source: Address): string => {
  const routeComponent = source.address_components.find((c) => {
    return c.types[0] === 'route';
  });
  if (routeComponent) {
    return routeComponent.long_name;
  }
  const displayComponent = source.address_components.find((c) => {
    return isNaN(parseInt(c.short_name));
  });
  if (displayComponent) {
    return displayComponent.long_name;
  }
  return 'Location';
};
