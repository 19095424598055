import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'c-alert-with-icon-critical',
  template: `
    <div class="alert-with-icon-critical">
      <i class="icon">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="8" cy="8.00098" r="8" fill="#D52504" />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.21538 2.58329V7.46548C9.21538 7.8486 9.19581 8.22858 9.15666 8.60545C9.11752 8.98231 9.06591 9.36646 9.00185 9.75791H7.21353C7.14947 9.36646 7.09787 8.98231 7.05872 8.60545C7.01957 8.22858 7 7.8486 7 7.46548V2.58329C7 2.58329 7.27118 2.27794 7.55388 2.00406C8.05834 1.99939 8.16337 2.00054 8.62249 2.00406C8.95394 2.33206 9.21538 2.58329 9.21538 2.58329Z"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7 12.3396C7 12.1842 7.02832 12.0395 7.08497 11.9056C7.14161 11.7717 7.21923 11.6551 7.31783 11.5558C7.41643 11.4565 7.53287 11.3777 7.66713 11.3194C7.8014 11.2611 7.94825 11.2319 8.10769 11.2319C8.26294 11.2319 8.40769 11.2611 8.54196 11.3194C8.67622 11.3777 8.79371 11.4565 8.89441 11.5558C8.99511 11.6551 9.07378 11.7717 9.13042 11.9056C9.18706 12.0395 9.21538 12.1842 9.21538 12.3396C9.21538 12.4951 9.18706 12.6408 9.13042 12.7769C9.07378 12.9129 8.99511 13.0306 8.89441 13.1299C8.79371 13.2292 8.67622 13.307 8.54196 13.3631C8.40769 13.4192 8.26294 13.4473 8.10769 13.4473C7.94825 13.4473 7.8014 13.4192 7.66713 13.3631C7.53287 13.307 7.41643 13.2292 7.31783 13.1299C7.21923 13.0306 7.14161 12.9129 7.08497 12.7769C7.02832 12.6408 7 12.4951 7 12.3396Z"
            fill="white"
          />
        </svg>
      </i>
      <div>
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styles: [
    `
      .alert-with-icon-critical {
        display: flex;
        align-items: center;
        border-radius: 4px;
        padding: 14px 12px;
        border: 1px solid #d52504;
        background-color: rgba(213, 37, 4, 0.1);
      }

      .icon {
        margin-right: 10px;
        position: relative;
        bottom: -2px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertWithIconCriticalComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
