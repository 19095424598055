import { Component, Input, ChangeDetectionStrategy, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Observable, of } from 'rxjs';

import { getPopupBodyMaxHeight } from '../types';

@Component({
  selector: 'c-popup-body',
  templateUrl: './popup-body.component.html',
  styleUrls: ['./popup-body.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupBodyComponent implements OnInit, OnChanges {
  @Input() public height = 'auto';
  @Input() public maxHeight: 'auto' | string = 'none';
  @Input() public margin = '16px 40px 40px 40px';
  @Input() public isScrollY = false;

  public maxHeight$: Observable<string>;

  public ngOnInit(): void {
    this.setMaxHeight$();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.maxHeight && !changes.maxHeight.isFirstChange()) {
      this.setMaxHeight$();
    }
  }

  private setMaxHeight$(): void {
    if (this.maxHeight === 'auto') {
      this.maxHeight$ = getPopupBodyMaxHeight();
    } else {
      this.maxHeight$ = of(this.maxHeight);
    }
  }
}
