import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GreyLabelComponent } from './grey-label/grey-label.component';
import { FieldValueComponent } from './field-value/field-value.component';
import { LinkComponent } from './link/link.component';
import { SpacedRowComponent } from './spaced-row/spaced-row.component';
import { SpacedBlockComponent } from './spaced-block/spaced-block.component';
import { FieldLabelSideValueComponent } from './field-label-side-value/field-label-side-value.component';
import { FieldLabelDownValueComponent } from './field-label-down-value/field-label-down-value.component';
import { LinkAccordionComponent } from './link-accordion/link-accordion.component';
import { LinkBtnComponent } from './link-btn/link-btn.component';
import { LinkExternalComponent } from './link-external/link-external.component';
import { DirectivesModule } from '../directives/directives.module';
import { ArrowIconsModule } from '../icons/arrow-icons/arrow-icons.module';
import { HeaderOneComponent } from './header-one/header-one.component';
import { HeaderTwoComponent } from './header-two/header-two.component';
import { HeaderThreeComponent } from './header-three/header-three.component';
import { HeaderFourComponent } from './header-four/header-four.component';
import { HeaderFiveComponent } from './header-five/header-five.component';

const components = [
  GreyLabelComponent,
  FieldValueComponent,
  LinkComponent,
  SpacedRowComponent,
  SpacedBlockComponent,
  FieldLabelSideValueComponent,
  FieldLabelDownValueComponent,
  LinkAccordionComponent,
  LinkBtnComponent,
  LinkExternalComponent,
];

@NgModule({
  declarations: [
    ...components,
    HeaderOneComponent,
    HeaderTwoComponent,
    HeaderThreeComponent,
    HeaderFourComponent,
    HeaderFiveComponent,
  ],
  imports: [CommonModule, DirectivesModule, ArrowIconsModule],
  exports: [
    ...components,
    HeaderOneComponent,
    HeaderFourComponent,
    HeaderTwoComponent,
    HeaderThreeComponent,
    HeaderFiveComponent,
  ],
})
export class TypographyModule {}
