<div
  class="wp-btn-copy"
  #tooltip="matTooltip"
  [matTooltip]="tooltipText"
  [matTooltipPosition]="'right'"
  (mouseenter)="disableTooltipOnHover($event)"
  (mouseleave)="disableTooltipOnHover($event)"
  (click)="copyText()"
  (contextmenu)="$event.preventDefault()"
>
  <wp-link-btn class="wp-btn-copy-content">
    <div
      class="btn-inner-content"
      [ngClass]="{
      'icon-before-text': isIconBeforeText
    }"
    >
      <div
        class="wp-btn-copy-label"
        [ngClass]="{ 'with-margin': !isIconBeforeText && isIcon}"
      >
        <ng-content></ng-content>
      </div>
      <i
        *ngIf="isIcon"
        class="wp-btn-copy-icon"
        [ngClass]="{ 'with-margin': isIconBeforeText, 'big-icon': isBigIcon}"
      ></i>
    </div>
  </wp-link-btn>
</div>
