import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { homeRoute } from '../../../routes';

@Component({
  selector: 'c-no-permissions',
  template: `
    <c-page-header></c-page-header>
    <c-home-content>
      <div class="content">
        <div class="title">User has no permissions to access this content</div>
        <div class="instructions">Please contact your administrator.</div>
        <wp-link [href]="homeLink" [isUnderlined]="true" [fontSize]="'16px'"> Try again </wp-link>
      </div>
    </c-home-content>
    <c-page-footer></c-page-footer>
  `,
  styleUrls: ['./no-permissions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoPermissionsComponent implements OnInit {
  public homeLink: string = homeRoute.getHref();
  constructor() {}

  ngOnInit(): void {}
}
