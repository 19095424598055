import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RouterReducerState } from '@ngrx/router-store';
import { RouterStateUrl } from '../../routes/types';
import { routerFeatureKey } from './feature-key';

const getRouterState = createFeatureSelector<RouterReducerState<RouterStateUrl>>(routerFeatureKey);

export const getRouterNavigationState = createSelector<any, any, RouterStateUrl>(getRouterState, (state) => {
  return state ? state.state : {};
});
