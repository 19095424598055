import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { checkAuthRequested } from '../../store/auth/auth.actions';
import { selectIsHomeGuardResolved } from '../../store/home-guard.selectors';

@Injectable({
  providedIn: 'root',
})
export class HomeGuard {
  constructor(private store: Store) {}

  public canActivate(): Observable<boolean> {
    this.store.dispatch(checkAuthRequested());
    return this.store.select(selectIsHomeGuardResolved).pipe(
      filter((isResolved) => !!isResolved),
      take(1),
    );
  }
}
