import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'c-divider-vertical-line',
  templateUrl: './divider-vertical-line.component.html',
  styleUrls: ['./divider-vertical-line.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DividerVerticalLineComponent {
  @Input() public containerHeight = '20px';
  @Input() public height = '100%';
  @Input() public margin: string;
  @Input() public isWhite = false;
}
