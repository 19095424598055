import { DisplayDateTimeStamp } from './formatters/display-date-time';

/**
 *
 * @param yearStamp timestamp encoding of year, month date
 * @param hourStamp timestamp encoding of hours, minutes
 * @returns timestamp encoding year, month, date, hours and minutes
 */
export const mergeDisplayDateTimestamps = (
  yearStamp: DisplayDateTimeStamp,
  hourStamp: DisplayDateTimeStamp,
): number => {
  const yearDate = new Date(yearStamp);
  const hourDate = new Date(hourStamp);
  const result = Date.UTC(
    yearDate.getUTCFullYear(),
    yearDate.getUTCMonth(),
    yearDate.getUTCDate(),
    hourDate.getUTCHours(),
    hourDate.getUTCMinutes(),
    hourDate.getUTCSeconds(),
  );
  return result;
};
