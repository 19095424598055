<div
  class="wp-btn-secondary"
  [ngClass]="{ disabled: disabled, slim: slim, loading: loading }"
  [ngStyle]="{ width, height, padding }"
  (click)="onClick()"
  tabindex="{{tabIndex}}"
>
  <ng-container
    *ngIf="loading; then loadingTemp; else loadedTemp"
  ></ng-container>

  <ng-template #loadingTemp>
    <wp-btn-progress-green></wp-btn-progress-green>
  </ng-template>

  <ng-template #loadedTemp>
    <div class="btn-content-wrap">
      <ng-content select="wp-btn-icon"> </ng-content>
      <ng-content> </ng-content>
    </div>
  </ng-template>
</div>
