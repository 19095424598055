import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'c-popup-header',
  templateUrl: './popup-header.component.html',
  styleUrls: ['./popup-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupHeaderComponent implements OnInit {
  @Input() public padding = '30px 40px 10px 40px';
  @Input() public fontSize = '24px';
  @Input() public fontWeight = '600';
  @Input() public isCloseBtn = true;
  @Input() public isHorizontalLine = true;

  @Output() public closeRequested = new EventEmitter<void>();

  public horizontalLineMargin: string;

  public ngOnInit(): void {
    const marginX = this.padding.split(' ')[1];
    this.horizontalLineMargin = `0 ${marginX}`;
  }

  public close(): void {
    this.closeRequested.emit();
  }
}
