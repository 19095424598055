import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';

import { HHMMTimeString } from '@rootTypes';

interface Option {
  date: Date;
  time12: string;
  timestamp: number;
}

@Component({
  selector: 'wp-input-timepicker',
  templateUrl: './input-timepicker.component.html',
  styleUrls: ['./input-timepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DatePipe],
})
export class InputTimepickerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public label: string;
  @Input() public control: UntypedFormControl; // HHMMTimeString | null;
  @Input() public minSuggestedTime?: HHMMTimeString;
  @Input() public customErrorMessageKey?: string;

  @Output() public valueChangedByUser = new EventEmitter<HHMMTimeString | null>();

  public displayControl: UntypedFormControl; // 12 hour string
  public suggestedOptions: Option[];

  @ViewChild('timeInput')
  private input: ElementRef;

  private todayStamp: number;
  private options: Option[];
  // Contains indexes to suggest options against user input
  private hoursToOptionIndexTable: { [hours24: number]: number };
  private minSuggestedOptionIndex: number;
  private sub = new Subscription();

  constructor(
    private datePipe: DatePipe,
    private cdRef: ChangeDetectorRef,
  ) {}

  public ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.minSuggestedTime && !changes.minSuggestedTime.isFirstChange()) {
      this.setMinSuggestedOptionIndex();
      this.setSuggestedOptions();
    }
  }

  public ngOnInit(): void {
    this.todayStamp = new Date().setHours(0, 0, 0, 0);
    this.setOptions();
    this.setMinSuggestedOptionIndex();
    this.initDisplayControl();
    this.setSuggestedOptions();
  }

  public onOptionSelected(): void {
    this.updateControlValue();
  }

  public onBlur(): void {
    // Use timeout to trigger blur event after option selected event.
    // Otherwise, in case of incorrect input like "1" or "1;3" blur event that always fires firstly
    // sometimes prevents triggering of option selected event
    setTimeout(() => {
      this.updateControlValue();
    }, 200);
  }

  public onReset(event: MouseEvent): void {
    event.stopPropagation();
    this.displayControl.setValue('');
    this.input.nativeElement.focus();
  }

  private time12toHHMM(time12: string): HHMMTimeString | null {
    try {
      const parts: string[] = time12.split(':');
      const hours12 = parseInt(parts[0], 10);
      const mins = parseInt(parts[1], 10);

      if (hours12 > 12 || mins > 59) {
        return null;
      }

      const periodText = parts[1].toUpperCase();
      let isAM: boolean;
      if (periodText.includes('AM')) {
        isAM = true;
      } else if (periodText.includes('PM')) {
        isAM = false;
      } else {
        return null;
      }
      const hours24 = this.hours12ToHours24(hours12, isAM);

      return (hours24 + '').padStart(2, '0') + (mins + '').padStart(2, '0');
    } catch (err) {
      return null;
    }
  }

  private setOptions(): void {
    this.options = [];
    this.hoursToOptionIndexTable = {};

    const stepMin = 15;
    const stepMs = stepMin * 60 * 1000;
    let currentStamp = new Date(this.todayStamp).setHours(0, 0, 0, 0);
    const endStamp = new Date(this.todayStamp).setHours(23, 59, 0, 0);
    while (currentStamp < endStamp) {
      const option = this.createOption(currentStamp);
      this.options.push(option);
      if (option.date.getMinutes() === 0) {
        this.hoursToOptionIndexTable[option.date.getHours()] = this.options.length - 1;
      }
      currentStamp += stepMs;
    }

    const lastOption = this.createOption(new Date(this.todayStamp).setHours(23, 59, 0, 0));
    this.options.push(lastOption);
  }

  private createOption(timestamp: number): Option {
    const date = new Date(timestamp);
    return {
      date,
      timestamp,
      time12: this.dateToTime12(date),
    };
  }

  private initDisplayControl(): void {
    this.displayControl = new UntypedFormControl(this.hhMMToTime12(this.control.value));

    if (this.control.disabled) {
      this.displayControl.disable();
    }
    this.control.registerOnDisabledChange((disabled: boolean): void => {
      if (disabled) {
        this.displayControl.disable();
      } else {
        this.displayControl.enable();
      }
    });

    this.sub.add(
      this.control.valueChanges.pipe(distinctUntilChanged()).subscribe((hhMM) => {
        this.displayControl.setValue(this.hhMMToTime12(hhMM));
      }),
    );

    this.sub.add(
      this.control.statusChanges.subscribe((status) => {
        if (status === 'INVALID') {
          this.displayControl.setErrors(this.control.errors);
        } else {
          this.displayControl.setErrors(null);
        }
        this.displayControl.markAsTouched();
        this.cdRef.detectChanges();
      }),
    );

    this.sub.add(
      this.displayControl.valueChanges.pipe(distinctUntilChanged()).subscribe(() => {
        this.setSuggestedOptions();
      }),
    );
  }

  private hhMMToTime12(value: HHMMTimeString): string {
    const date = this.hhMMtoDate(value);
    return date ? this.dateToTime12(date) : '';
  }

  private dateToTime12(date: Date): string {
    return this.datePipe.transform(date, 'shortTime');
  }

  private hours12ToHours24(hours12: number, isAM: boolean): number {
    let hours24: number;
    if (isAM) {
      hours24 = hours12 === 12 ? 0 : hours12;
    } else {
      hours24 = hours12 === 12 ? 12 : 12 + hours12;
    }
    return hours24;
  }

  private hhMMtoDate(value: HHMMTimeString): Date | null {
    if (!value || value.length !== 4) {
      return null;
    }
    try {
      const hours = parseInt(value.slice(0, 2));
      const minutes = parseInt(value.slice(2));
      const date = new Date(this.todayStamp);
      date.setHours(hours);
      date.setMinutes(minutes);
      date.setSeconds(0);
      date.setMilliseconds(0);
      return date;
    } catch (err) {
      return null;
    }
  }

  private displayValueToOptionIndex(value = ''): number {
    const preparedValue = value.trim().toUpperCase();
    if (!preparedValue.length) {
      return this.minSuggestedOptionIndex;
    }
    const isAM = !preparedValue.includes('PM');
    const hours12 = parseInt(preparedValue, 10) || 0;
    let hours24 = this.hours12ToHours24(hours12, isAM);
    let optionIndex: number = this.hoursToOptionIndexTable[hours24];
    if (typeof optionIndex === 'number') {
      return optionIndex > this.minSuggestedOptionIndex ? optionIndex : this.minSuggestedOptionIndex;
    }
    // In case there is no match with AM options try to find a match with PM options
    hours24 = this.hours12ToHours24(hours12, false);
    optionIndex = this.hoursToOptionIndexTable[hours24];
    return typeof optionIndex === 'number' && optionIndex > this.minSuggestedOptionIndex
      ? optionIndex
      : this.minSuggestedOptionIndex;
  }

  private setSuggestedOptions(): void {
    const optionStartIndex = this.displayValueToOptionIndex(this.displayControl.value);
    this.suggestedOptions = this.options.slice(optionStartIndex);
  }

  private setMinSuggestedOptionIndex(): void {
    const minSuggestedDate = this.hhMMtoDate(this.minSuggestedTime);
    if (!minSuggestedDate) {
      this.minSuggestedOptionIndex = 0;
      return;
    }
    const minTimestamp = minSuggestedDate.getTime();
    const optionIndex = this.options.findIndex((opt) => opt.timestamp >= minTimestamp);
    this.minSuggestedOptionIndex = optionIndex === -1 ? 0 : optionIndex;
  }

  private updateControlValue(): void {
    const time = this.time12toHHMM(this.displayControl.value);
    if (time !== this.control.value) {
      this.control.setValue(time);
      this.valueChangedByUser.emit(time);
    }
  }
}
