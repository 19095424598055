import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'c-page-footer',
  templateUrl: './page-footer.component.html',
  styleUrls: ['./page-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageFooterComponent implements OnInit {
  public currYear: number;

  public ngOnInit(): void {
    this.currYear = new Date().getFullYear();
  }
}
