import * as moment from 'moment-timezone';
import { RangePickerDay } from '../entities/range-picker-day';
import { getMomentDayLabel } from './get-moment-day-label';

export const getDaysForMonth = (month: moment.Moment, isMondayFirstDay: boolean): RangePickerDay[] => {
  const startOfMonth = moment(month).startOf('month');
  const startOfWeek = isMondayFirstDay ? moment(startOfMonth).startOf('isoWeek') : moment(startOfMonth).startOf('week');
  const endOfMonth = moment(month).endOf('month');
  const endOfWeek = isMondayFirstDay ? moment(endOfMonth).endOf('isoWeek') : moment(endOfMonth).endOf('week');

  const result = [] as RangePickerDay[];

  for (let m = moment(startOfWeek); m.isSameOrBefore(endOfWeek); m = moment(m).add(1, 'day')) {
    const curr = moment(m);
    const isDisplayed = month.month() === m.month();
    const day = {
      moment: curr,
      isDisplayed,
      label: isDisplayed ? getMomentDayLabel(curr) : null,
    } as RangePickerDay;
    result.push(day);
  }
  return result;
};
