import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeContentComponent } from './home-content/home-content.component';
import { HomeContentFullHeightComponent } from './home-content-full-height/home-content-full-height/home-content-full-height.component';
import { HomeContentFullHeightTopComponent } from './home-content-full-height/home-content-full-height-top.component';
import { HomeContentFullHeightBodyComponent } from './home-content-full-height/home-content-full-height-body.component';
import { HomeContentFullHeightBottomComponent } from './home-content-full-height/home-content-full-height-bottom.component';

@NgModule({
  declarations: [
    HomeContentComponent,
    HomeContentFullHeightComponent,
    HomeContentFullHeightTopComponent,
    HomeContentFullHeightBodyComponent,
    HomeContentFullHeightBottomComponent,
  ],
  exports: [
    HomeContentComponent,
    HomeContentFullHeightComponent,
    HomeContentFullHeightTopComponent,
    HomeContentFullHeightBodyComponent,
    HomeContentFullHeightBottomComponent,
  ],
  imports: [CommonModule],
})
export class HomeContentModule {}
