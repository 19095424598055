import { TripEditorLeg, TripEditorLegStop } from '../entities';
import { isReadyForDirectionRequest } from './is-ready-for-direction-request';
import { resetDateAndTimeForStopsStartingFrom } from './reset-date-and-time-for-stops-starting-from';

export const updateTripEditorLegOnStopChanged = (leg: TripEditorLeg, updatedStop: TripEditorLegStop): TripEditorLeg => {
  const newStops = resetDateAndTimeForStopsStartingFrom(leg.stops, updatedStop.stopIndex + 1);
  newStops[updatedStop.stopId] = updatedStop;
  const newLeg: TripEditorLeg = {
    ...leg,
    stops: newStops,
    summary: { isLoading: false },
  };
  newLeg.isReadyForDirectionRequest = isReadyForDirectionRequest(newLeg);

  return newLeg;
};
