import { Injectable } from '@angular/core';
import { QueryParamsHandling } from '@angular/router';

import { CharterRoute, NavigationRequest, RouterStateUrl } from '../types';
import { createTripStopsRoute, CreateTripStopsRouteData } from '../create-trip/create-trip-stops.router-service';
import { RouterService } from '../router-service';

export const homeRoute: CharterRoute<CreateTripStopsRouteData> = {
  path: '',
  isOnRoute(state: RouterStateUrl): boolean {
    return createTripStopsRoute.isOnRoute(state);
  },
  parse(state: RouterStateUrl): CreateTripStopsRouteData {
    return createTripStopsRoute.parse(state);
  },
  request(
    data?: CreateTripStopsRouteData,
    queryParamStrategy?: QueryParamsHandling,
    replaceUrl?: boolean,
  ): NavigationRequest {
    return createTripStopsRoute.request(data, queryParamStrategy, replaceUrl);
  },
  getHref(data?): string {
    return createTripStopsRoute.getHref();
  },
};

@Injectable({ providedIn: 'root' })
export class HomeRouterService extends RouterService<CreateTripStopsRouteData> {
  route = homeRoute;
}
