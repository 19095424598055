<div
  #ellipsisWrap
  class="wp-ellipsis-wrap"
  [ngStyle]="{
textAlign: textAlign
}"
  [matTooltip]="tooltipText"
  [matTooltipPosition]="tooltipPosition"
  [matTooltipDisabled]="!isTooltip"
>
  <ng-content></ng-content>
</div>
