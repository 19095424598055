<footer>
  <span class="year">
    Copyright © {{currYear}} Zum Services, Inc. All rights reserved.
  </span>

  <c-divider-vertical-line
    [isWhite]="true"
    [containerHeight]="'20px'"
    [height]="'14px'"
    [margin]="'0 25px 0 15px'"
  ></c-divider-vertical-line>

  <wp-link
    class="link"
    [href]="'https://www.ridezum.com/terms-conditions/'"
    [isUnderlined]="false"
    [isWhite]="true"
    [fontSize]="'12px'"
    [openInNewTab]="true"
  >
    Terms & Conditions
  </wp-link>

  <wp-link
    class="link"
    [href]="'https://www.ridezum.com/privacy-policy/'"
    [isUnderlined]="false"
    [isWhite]="true"
    [fontSize]="'12px'"
    [openInNewTab]="true"
  >
    Privacy Policy
  </wp-link>
</footer>
