<div tabindex="0" style="outline: none">
  <wp-dropdown
    [triggerTemplate]="triggerTemplate"
    [dropdownTemplate]="dropdownTemplate"
    [dropdownVerticalOffset]="dropdownVerticalOffset"
    [alignDropdown]="alignDropdown"
    [alignDropdownVertical]="dropdownVerticalAlignment"
    (closedDropdown)="onCloseDropdown()"
    #dropdownEl
  ></wp-dropdown>
</div>

<ng-template #triggerTemplate>
  <div
    class="wrap"
    [ngStyle]="{
    width: widthStr
  }"
  >
    <div
      #maskEl
      class="mask"
      (click)="onTriggerClick($event)"
      [ngClass]="{ disabled: isDisabled$ | async }"
    ></div>
    <div class="focus-underline"></div>
    <div [ngClass]="{ highlighted: highlighted$ | async }" #inputElWrap>
      <wp-input
        [label]="label"
        [control]="displayDateControl"
        [tabIndex]="tabIndex"
      >
        <wp-input-suffix *ngIf="isIcon">
          <wp-icon-calendar [grey]="isDisabled$ | async"></wp-icon-calendar>
        </wp-input-suffix>
      </wp-input>
    </div>
  </div>
</ng-template>

<ng-template #dropdownTemplate>
  <wp-dropdown-pane>
    <div class="datepicker-wrap" (click)="$event.stopPropagation()">
      <wp-multi-date-picker
        [selected]="selectedDateArr$ | async"
        [isSingleSelect]="true"
        [monthCount]="monthCount"
        [disableDatesBefore]="disableDatesBefore"
        [disableDatesAfter]="disableDatesAfter"
        (selectedChanged)="onValueChangedByUserAction($event)"
      ></wp-multi-date-picker>
      <div *ngIf="messageText" class="message">
        <div class="icon">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM11 7V15H9V7H11ZM11 6V4H9V6H11Z"
              fill="#959597"
            />
          </svg>
        </div>
        <div>{{ messageText }}</div>
      </div>
      <div class="btns-row">
        <div class="btn">
          <wp-btn-secondary
            (clicked)="onClearInput()"
            *ngIf="false"
            [width]="'110px'"
            [height]="'37px'"
            [disabled]="!control.value"
          >
            Clear
          </wp-btn-secondary>
        </div>
        <div class="btn" *ngIf="!closeOnSelect">
          <wp-btn-primary
            (clicked)="onApplyClicked()"
            [width]="'110px'"
            [height]="'37px'"
          >
            Apply
          </wp-btn-primary>
        </div>
      </div>
    </div>
  </wp-dropdown-pane>
</ng-template>
