<div
  class="wp-dropdown-option"
  #optionElement
  [ngStyle]="{height: height}"
  [ngClass]="{ 'disabled': disabled, 'selected': selected, highlighted: highlighted, default: isDefaultOption }"
>
  <div class="wp-dropdown-option__text">
    <ng-content></ng-content>
  </div>
</div>
