import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'durationHrsMin',
})
export class DurationHrsMinPipe implements PipeTransform {
  public transform(durationMinutes?: number): string {
    if (!durationMinutes) {
      return '--';
    }
    if (durationMinutes <= 59) {
      return this.minutesToString(durationMinutes);
    }
    const hours = Math.trunc(durationMinutes / 60);
    const minutes = durationMinutes - hours * 60;
    if (minutes === 0) {
      return this.hoursToString(hours);
    }
    return `${this.hoursToString(hours)} ${this.minutesToString(minutes)}`;
  }

  private hoursToString(hours: number): string {
    return hours === 1 ? '1 hr' : `${hours} hrs`;
  }

  private minutesToString(minutes: number): string {
    return `${minutes} min`;
  }
}
