import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';

import { ButtonsModule } from 'src/app/shared/buttons/buttons.module';
import { PopupComponent } from './popup/popup.component';
import { ConfirmPopupComponent } from './confirm-popup/confirm-popup.component';
import { ComponentsModule } from '../../shared/components/components.module';
import { IconCloseModule } from '../../shared/icons/icon-close/icon-close.module';
import { ErrorPopupComponent } from './error-popup/error-popup.component';
import { PopupElementsModule } from '../../shared/popup-elements/popup-elements.module';

@NgModule({
  declarations: [PopupComponent, ConfirmPopupComponent, ErrorPopupComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    RouterModule,
    ButtonsModule,
    ComponentsModule,
    IconCloseModule,
    PopupElementsModule,
  ],
  exports: [PopupComponent],
})
export class PopupModule {}
