import { TripEditorLeg, TripEditorLegStop, TripEditorLegSummary } from '../entities';
import { GetCharterDirectionsResponse } from '@rootTypes/api';

export const updateTripEditorLegOnDirectionSuccess = (
  leg: TripEditorLeg,
  direction: GetCharterDirectionsResponse,
): TripEditorLeg => {
  let summary: TripEditorLegSummary;
  if (typeof direction.summary?.mileage === 'number') {
    const lastStop = direction.stops[direction.stops.length - 1];
    summary = {
      totalMiles: direction.summary.mileage,
      arrivalDate: lastStop.arrivalDate,
      arrivalTime: lastStop.arrivalTime,
      polyline: direction.summary.polyline,
    };
  }

  const newStops: { [stopId: string]: TripEditorLegStop } = {};
  Object.values(leg.stops).forEach((stop) => {
    if (stop.isFirst) {
      newStops[stop.stopId] = stop;
      return;
    }

    const stopEstimation = direction.stops[stop.stopIndex];
    newStops[stop.stopId] = {
      ...stop,
      data: {
        address: stop.data.address || null,
        departureTime: stopEstimation?.departureTime || null,
        arrivalDate: stopEstimation?.arrivalDate || null,
        arrivalTime: stopEstimation?.arrivalTime || null,
      },
    };
  });

  return {
    ...leg,
    stops: newStops,
    summary: {
      isLoading: false,
      entity: summary,
    },
  };
};
