import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { homeRoute } from '../../../routes';

@Component({
  selector: 'c-app-config-error',
  templateUrl: './app-config-error.component.html',
  styleUrls: ['./app-config-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppConfigErrorComponent implements OnInit {
  public homeLink: string;
  constructor() {}

  public ngOnInit(): void {
    this.homeLink = homeRoute.getHref();
  }
}
