<div class="wp-pill">
  <div
    class="wp-pill__wrap"
    [ngStyle]="{ width: width }"
    [ngClass]="{ readonly: readonly }"
  >
    <ng-content></ng-content>

    <div class="close-btn" *ngIf="!readonly" (click)="onClose()">
      <wp-icon-close [size]="'xsmall'"></wp-icon-close>
    </div>
  </div>
</div>
