import { TripQuotePriceCorrectors } from './trip-quote-price-correctors';
import { TripQuoteStatus } from './trip-quote-status';

export type TripQuote = TripQuotePriceCorrectors & {
  status: TripQuoteStatus;
  catalogsItemizedQuote: TripQuoteCatalogBill[];
  nonCatalogItemizedQuote: TripQuoteItemizedEntry[];
  totalCents?: number;
  outstandingCents?: number;
  cardFeeCents?: number;
  payments?: {
    label: string;
    cents: number;
    invoicePath?: string; // example: invoice?id=4QVEkt...
    receiptPath?: string;
  }[];
  refunds?: {
    label: string;
    cents: number;
    invoicePath?: string;
  }[];
  latestInvoicePath?: string;
  paymentLinkUrl?: string;
  invoiceId?: string;
};

export interface TripQuoteCatalogBill {
  catalogId: string;
  count: number;
  serviceLocationId: string;
  cents?: number;
  billingEntries?: TripQuoteBillingEntry[];
}

export interface TripQuoteBillingEntry {
  cents?: number;
  label: string;
  note?: string; // tooltip
  itemizedBillingEntries?: TripQuoteItemizedEntry[];
}

export interface TripQuoteItemizedEntry {
  cents?: number;
  label: string;
  type?: TripQuoteEntryType;
  tooltipText?: string;
}

export enum TripQuoteEntryType {
  CREDIT = 'CREDIT', // means minus
  DEBIT = 'DEBIT',
}
