import { EntitySearchResult, EntitySearchType } from '@rootTypes';

export const getEntityId = (source: EntitySearchResult): string => {
  switch (source.type) {
    case EntitySearchType.CAMPUS:
      return source.campusId;
    case EntitySearchType.DISTRICT:
      return source.districtId;
    case EntitySearchType.DRIVER:
      return source.driverId;
    case EntitySearchType.PARENT:
      return source.parentId;
    case EntitySearchType.STUDENT:
      return source.studentId;
    case EntitySearchType.VENDOR:
      return source.vendorId;
    case EntitySearchType.VEHICLE:
      return source.vehicleId;
    case EntitySearchType.YARD:
      return source.yardId;
    case EntitySearchType.ZUM_EMPLOYEE:
      return source.zumEmployeeId;
    case EntitySearchType.VENDOR_EMPLOYEE:
      return source.vendorEmployeeId;
    case EntitySearchType.SCHOOL_EMPLOYEE:
      return source.schoolEmployeeId;
    case EntitySearchType.RIDE:
      return source.rideId;
    case EntitySearchType.ROUTE:
      return source.routeId;
    case EntitySearchType.CHARTER_TRIPS:
      return source.tripId;
    default: {
      console.warn('Unknown entity type: ', source);
      return null;
    }
  }
};
