<header>
  <div class="content">
    <c-icon
      class="logo"
      [path]="logo"
      [widthStr]="'108px'"
      [heightStr]="'46px'"
      [alt]="'Zum'"
      (click)="goHome()"
    ></c-icon>
    <div class="header-right">
      <div class="blue-label" *ngIf="banner$ | async; let banner">
        {{banner}}
      </div>

      <wp-link
        *ngIf="supportLink$ | async; let supportLink"
        [href]="supportLink"
        [openInNewTab]="true"
        [isUnderlined]="false"
        class="link-right-margin"
        >Support
      </wp-link>

      <wp-link-btn [isUnderlined]="false" (clicked)="goTrips()"
        >Trips
      </wp-link-btn>
    </div>
  </div>
  <div class="horizontal-line"></div>
</header>
