import * as moment from 'moment-timezone';
/**
 *
 * @param source YYYYMMDDstring like 20200518
 * @param long display long format like August 2010, otherwise Aug 2016
 */
export const yyyymmddDisplay = (source: string, long?: boolean): string => {
  const m = moment(source, 'YYYYMMDD');
  if (long) {
    return m.format('MMMM DD, YYYY');
  }
  return m.format('MMM DD, YYYY');
};
