import { createReducer, on } from '@ngrx/store';
import * as createTripActions from '../create-trip/create-trip.actions';
import { clearStore } from '../../../../store/common/clear-store.actions';
import { validateTripItinerarySuccess } from '../trip-editor-stops/trip-editor-stops.actions';

export interface TripEditorIdsState {
  tripItineraryId: string;
  tripId?: string;
}

const createInitialState = (): TripEditorIdsState => {
  return {
    tripItineraryId: null,
  };
};

export const tripEditorIdsReducer = createReducer(
  createInitialState(),
  on(createTripActions.initializeNewTrip, createTripActions.createNewTripSuccess, clearStore, createInitialState),
  on(
    createTripActions.initializeNewTripVehicles,
    createTripActions.initializeNewTripReview,
    createTripActions.initializeNewTripReservation,
    (state, { tripItineraryId }): TripEditorIdsState => {
      return {
        tripItineraryId,
      };
    },
  ),
  on(validateTripItinerarySuccess, (state, { response }): TripEditorIdsState => {
    return {
      tripItineraryId: response.tripItineraryId,
    };
  }),
);
