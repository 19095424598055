import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { PageHeaderModule } from '../shared/page-header/page-header.module';
import { HomeContentModule } from '../shared/home-content/home-content.module';
import { PageFooterModule } from '../shared/page-footer/page-footer.module';
import { TypographyModule } from '../shared/typography/typography.module';
import { ComponentsModule } from '../shared/components/components.module';
import { SmartFormsModule } from '../shared/smart-forms/smart-forms.module';

import { HomeComponent } from './containers/home/home.component';
import { HomeHeaderComponent } from './components/home-header/home-header.component';
import { ApiService, BaseApiService, MockApiService } from './services';
import { CharterCatalogApiService } from './services/api/charter-catalog-api.service';
import { SnackbarModule } from './snackbar/snackbar.module';
import { NoPermissionsComponent } from './containers/no-permissions/no-permissions.component';
import { AppConfigErrorComponent } from './containers/app-config-error/app-config-error.component';
import { UnauthorizedComponent } from './containers/unauthorized/unauthorized.component';
import { LoginComponent } from './containers/login/login.component';
import { UserRoleService } from './services/api/user-role.service';
import { PromptConfirmationPopupComponent } from './containers/prompt-confirmation-popup/prompt-confirmation-popup.component';
import { StackLayoutModule } from '../shared/layouts/stack-layout/stack-layout.module';
import { ButtonsModule } from '../shared/buttons/buttons.module';
import { PopupElementsModule } from '../shared/popup-elements/popup-elements.module';

const apiServiceFactory = (
  baseApi: BaseApiService,
  catalogApi: CharterCatalogApiService,
  userRole: UserRoleService,
): ApiService => {
  if (cEnvironment.mockApiEnabled) {
    return new MockApiService(baseApi, catalogApi) as any as ApiService;
  }
  return new ApiService(baseApi, catalogApi, userRole);
};

@NgModule({
  declarations: [
    HomeComponent,
    HomeHeaderComponent,
    NoPermissionsComponent,
    AppConfigErrorComponent,
    UnauthorizedComponent,
    LoginComponent,
    PromptConfirmationPopupComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    PageHeaderModule,
    HomeContentModule,
    PageFooterModule,
    TypographyModule,
    RouterModule,
    ComponentsModule,
    SmartFormsModule,
    SnackbarModule,
    StackLayoutModule,
    ButtonsModule,
    PopupElementsModule,
  ],
  providers: [
    {
      provide: ApiService,
      useFactory: apiServiceFactory,
      deps: [BaseApiService, CharterCatalogApiService, UserRoleService],
    },
  ],
})
export class CoreModule {}
