import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { QueryParamsHandling, Router, UrlTree } from '@angular/router';
import { map, Observable } from 'rxjs';

import { CharterRoute, RouterStateUrl } from './types';
import { getRouterNavigationState } from '../store/router/router.selectors';
import { CustomRouterStateSerializer } from './custom-router-state-serializer';

@Injectable()
export abstract class RouterService<D> {
  public abstract route: CharterRoute<D>;
  private serializer = new CustomRouterStateSerializer();

  constructor(private store: Store, private router: Router) {}

  public isOnRoute$(): Observable<boolean> {
    return this.getCurrentRouterState$().pipe(map((state) => this.route.isOnRoute(state)));
  }

  public parse$(): Observable<D> {
    return this.getCurrentRouterState$().pipe(map((state) => this.route.parse(state)));
  }

  public snapshot(): D {
    const serialized = this.serializer.serialize(this.router.routerState.snapshot);
    return this.route.parse(serialized);
  }

  public getUrlTree(data: D, queryParamsHandling: QueryParamsHandling = 'merge', replaceUrl = false): UrlTree {
    const request = this.route.request(data, queryParamsHandling, replaceUrl);
    let preparedPath = request.path.join('/');
    if (preparedPath.startsWith('/')) {
      preparedPath = preparedPath.slice(1);
    }
    return this.router.createUrlTree(preparedPath.split('/'), {
      ...request.extras,
      queryParams: request.query || {},
    });
  }

  public navigate(data?: D, queryParamsHandling: QueryParamsHandling = 'merge', replaceUrl = false): void {
    const { path, query, extras } = this.route.request(data, queryParamsHandling, replaceUrl);
    this.router.navigate(path, { queryParams: query, ...extras });
  }

  public navigateWithQueryParamsFromUrl(data?: D): void {
    const url = this.route.getHref(data) + location.search;
    this.router.navigateByUrl(url);
  }

  protected getCurrentRouterState$(): Observable<RouterStateUrl> {
    return this.store.select(getRouterNavigationState);
  }
}
