import { createSelector } from '@ngrx/store';

import { SelectOption } from '../shared/form-controls';
import { Option } from '@rootTypes/entities/common/option';
import { getAuthFeature } from './auth/feature-selector';

const getAppConfigState = createSelector(getAuthFeature, (state) => state.appConfig);
export const getAppConfig = createSelector(getAppConfigState, (state) => state.entity);
export const isAppConfigLoading = createSelector(getAppConfigState, (state) => state.isLoading);

// region options
export const getRegionApiOptions = createSelector(getAppConfig, (state): Option[] => {
  if (!state) {
    return [];
  }
  return state.regions;
});
export const getRegionSelectOptions = createSelector(getRegionApiOptions, (state): SelectOption[] => {
  return state.map((s) => ({ value: s.id, displayLabel: s.label }));
});
// vehicle catalog options
export const getVehicleCatalogApiOptions = createSelector(getAppConfig, (state) => {
  if (!state) {
    return [];
  }
  return state.catalogs || [];
});
export const getVehicleCatalogSelectOptions = createSelector(getVehicleCatalogApiOptions, (state) => {
  return state.map((s) => ({ value: s.id, displayLabel: s.label })) as SelectOption[];
});

export const getDisplayVehicleCatalogOptionLabel = (catalogId) =>
  createSelector(getVehicleCatalogApiOptions, (options) => {
    return (options || []).find(({ id }) => id === catalogId)?.label;
  });

export const selectGeneralContractDistrictId = createSelector(
  getAppConfig,
  (state) => state?.generalContractDistrictId,
);

export const selectDefaultContractDistrict = createSelector(getAppConfig, (state) => state?.defaultContractDistrict);
export const selectDefaultContractCampus = createSelector(getAppConfig, (state) => state?.defaultContractCampus);

export const selectConfigMinDate = createSelector(getAppConfig, (state) => state?.bookingConstraints?.dates?.minDate);
export const selectConfigMaxDate = createSelector(getAppConfig, (state) => state?.bookingConstraints?.dates?.maxDate);

export const selectConfigDateSelectMessage = createSelector(
  getAppConfig,
  (state) => state?.bookingConstraints?.dates?.message,
);

export const selectSupportLink = createSelector(getAppConfig, (state) => state?.supportUrl);
