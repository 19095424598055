import { Component, ChangeDetectionStrategy } from '@angular/core';
import { AuthApiService } from '../../services/api/auth-api.service';

@Component({
  selector: 'c-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnauthorizedComponent {
  constructor(private authApi: AuthApiService) {}

  public signInToPortal(): void {
    this.authApi.redirectToPortalLogin();
  }
}
