import { UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { hasPermission } from '../../store/auth/auth.selectors';
import { CharterPermissions } from '@rootTypes/entities/auth/charter-permissions';
import { TripListRouterService } from '../../routes';

@Injectable({
  providedIn: 'root',
})
export class CreateTripGuard {
  constructor(private store: Store, private tripsRoute: TripListRouterService) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.store.select(hasPermission(CharterPermissions.CHARTER_TRIP_BOOKING_EDIT)).pipe(
      take(1),
      map((hasBookingFlowPermission) => {
        if (!hasBookingFlowPermission) {
          return this.tripsRoute.getUrlTree({});
        }
        return true;
      }),
    );
  }
}
