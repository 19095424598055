import { Component, ChangeDetectionStrategy, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'wp-btn-copy',
  templateUrl: './btn-copy.component.html',
  styleUrls: ['./btn-copy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BtnCopyComponent {
  @Input() public textToCopy: string;
  @Input() public tooltipText = 'Copied';
  @Input() public isIconBeforeText: boolean;
  @Input() public isIcon: boolean;
  @Input() public isBigIcon: boolean;

  @ViewChild('tooltip') private tooltip: MatTooltip;

  constructor(private cdRef: ChangeDetectorRef) {}

  public disableTooltipOnHover(event: Event): void {
    event.stopImmediatePropagation();
  }

  public copyText(): void {
    if (this.textToCopy) {
      this.copyToClipboard(this.textToCopy);
      this.tooltip.show();
      setTimeout(() => {
        this.tooltip.hide();
        this.cdRef.detectChanges();
      }, 1000);
    }
  }

  private copyToClipboard(text: string): void {
    const textarea = document.createElement('textarea');
    textarea.style.position = 'fixed';
    textarea.style.left = '0';
    textarea.style.top = '0';
    textarea.style.opacity = '0';
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.focus();
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
  }
}
