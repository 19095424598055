import { RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';
import { RouterStateUrl } from './types';

export class CustomRouterStateSerializer implements RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    let route = routerState.root;
    let params = {};
    while (route.firstChild) {
      params = { ...params, ...(route.params || {}) };
      route = route.firstChild;
    }
    params = { ...params, ...(route.params || {}) };
    const {
      url,
      root: { queryParams },
    } = routerState;
    return { url, params, queryParams };
  }
}
