import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { Vehicle } from '@rootTypes/entities/vehicles';

@Pipe({
  name: 'displayVehicleName',
})
export class DisplayVehicleNamePipe implements PipeTransform {
  constructor(private store: Store) {}

  transform(vehicle?: Vehicle): string {
    if (!vehicle) {
      return '';
    }
    return `${vehicle.make} ${vehicle.model} ${vehicle.year}`;
  }
}
