import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FadeInOutDirective } from './fade-in-out.directive';
import { ExpandDirective } from './expand.directive';

const animationDirectives = [FadeInOutDirective, ExpandDirective];

@NgModule({
  declarations: [...animationDirectives],
  imports: [CommonModule],
  exports: [...animationDirectives],
})
export class AnimationsModule {}
