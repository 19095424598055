import { Injectable } from '@angular/core';
import { QueryParamsHandling } from '@angular/router';
import { CharterRoute, NavigationRequest, RouterStateUrl } from '../types';
import { RouterService } from '../router-service';

export type LoginRouteData = {
  // google auth token for sign in
  token?: string;
  // defined by BE when user exists in Gsuite but does not exist in the DB
  error?: 'user-not-found';
  // destination page to enter the app after authentication is completed
  RelayState?: string;
};

const routePath = 'login';

export const loginRoute: CharterRoute<LoginRouteData> = {
  path: routePath,
  isOnRoute(state: RouterStateUrl): boolean {
    return state.url.startsWith(`/${routePath}`);
  },
  parse(state: RouterStateUrl): LoginRouteData {
    const data: LoginRouteData = {};
    if (state.queryParams.token) {
      data.token = state.queryParams.token;
    }
    if (state.queryParams.error) {
      data.error = state.queryParams.error;
    }
    if (state.queryParams.RelayState) {
      data.RelayState = state.queryParams.RelayState;
    }
    return data;
  },
  request(data?: LoginRouteData, queryParamStrategy?: QueryParamsHandling, replaceUrl?: boolean): NavigationRequest {
    const request: NavigationRequest = {
      path: [`/${routePath}`],
    };
    if (data?.RelayState) {
      request.query = {
        RelayState: data.RelayState,
      };
    }
    return request;
  },
  getHref(data?: LoginRouteData): string {
    return `/${routePath}`;
  },
};

@Injectable({ providedIn: 'root' })
export class LoginRouterService extends RouterService<LoginRouteData> {
  route = loginRoute;
}
