import { Injectable } from '@angular/core';
import { LocalStorageService } from '../storage.service';
import { UserRole } from '@rootTypes';

@Injectable({
  providedIn: 'root',
})
export class UserRoleService {
  private userRole: UserRole;
  private userRoleStorageKey = 'portalUserRole';

  constructor(private localStorage: LocalStorageService) {}

  public getUserRole(): UserRole {
    if (!this.userRole) {
      this.userRole = this.localStorage.get(this.userRoleStorageKey, false);
    }
    return this.userRole;
  }

  public setUserRole(role: UserRole): void {
    if (role) {
      this.localStorage.set(this.userRoleStorageKey, role);
      this.userRole = role;
    }
  }

  public isAdmin(): boolean {
    return this.userRole === 'admin';
  }

  public isEnterprise(): boolean {
    return this.userRole === 'enterprise';
  }

  public isVendor(): boolean {
    return this.userRole === 'vendor';
  }
}
