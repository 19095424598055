<c-popup-container>
  <c-popup-header [isHorizontalLine]="false" (closeRequested)="close()">
    <!-- Header icon-->
    <div
      *ngIf="!hideErrorHeader"
      style="display: flex; justify-content: center; margin-top: 10px"
    >
      <img
        src="/assets/icons/nonsystem-error.svg"
        width="59"
        height="42"
        alt="error icon"
      />
    </div>
    <!--    Header text-->
    <div
      *ngIf="!hideErrorHeader"
      style="font-size: 24px; font-weight: 600; margin: 18px 0 16px"
    >
      Hmm.. something’s gone awry
    </div>
  </c-popup-header>

  <c-popup-body>
    <!--    Messages-->
    <div
      *ngIf="errorStatements.generalErrorMessage"
      style="
        margin-bottom: 21px;
        white-space: pre-wrap;
        white-space: -moz-pre-wrap;
        white-space: -pre-wrap;
        white-space: -o-pre-wrap;
        word-wrap: break-word;
      "
    >
      {{errorStatements?.generalErrorMessage}}
    </div>
    <div
      *ngIf="errorStatements.specialErrorMessage"
      style="
        margin-bottom: 21px;
        white-space: pre-wrap;
        white-space: -moz-pre-wrap;
        white-space: -pre-wrap;
        white-space: -o-pre-wrap;
        word-wrap: break-word;
      "
    >
      {{errorStatements?.specialErrorMessage}}
    </div>
    <div
      *ngIf="errorStatements.errorReason"
      style="
        margin-bottom: 21px;
        white-space: pre-wrap;
        white-space: -moz-pre-wrap;
        white-space: -pre-wrap;
        white-space: -o-pre-wrap;
        word-wrap: break-word;
      "
    >
      {{errorStatements?.errorReason}}
    </div>
    <div
      *ngIf="errorStatements.correctiveAction"
      style="
        margin-bottom: 21px;
        white-space: pre-wrap;
        white-space: -moz-pre-wrap;
        white-space: -pre-wrap;
        white-space: -o-pre-wrap;
        word-wrap: break-word;
      "
    >
      {{errorStatements?.correctiveAction}}
    </div>
    <div
      *ngIf="errorStatements.contextId"
      style="
        margin-bottom: 21px;
        white-space: pre-wrap;
        white-space: -moz-pre-wrap;
        white-space: -pre-wrap;
        white-space: -o-pre-wrap;
        word-wrap: break-word;
      "
    >
      contextId: {{errorStatements?.contextId}}
    </div>
    <div class="button-wrap">
      <div>
        <wp-btn-copy
          [textToCopy]="errorToCopy"
          [tooltipText]="'Copied error to clipboard'"
        >
          Copy to clipboard
        </wp-btn-copy>
      </div>
      <div class="done-btn">
        <wp-btn-primary (clicked)="close()" [width]="'150px'">
          {{confirmButtonText}}
        </wp-btn-primary>
      </div>
    </div>
  </c-popup-body>
</c-popup-container>
