import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { homeInitialized, homeDestroyed } from '../../../store/auth/auth.actions';

@Component({
  selector: 'c-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeComponent implements OnInit, OnDestroy {
  constructor(private store: Store) {}

  public ngOnInit(): void {
    this.store.dispatch(homeInitialized());
  }

  public ngOnDestroy(): void {
    this.store.dispatch(homeDestroyed());
  }
}
