import * as moment from 'moment-timezone';
import { getWeekType } from './get-week-type';

/**
 * Returns an array of dates from the start of the current week till the end of the current week
 */
export const getWeekSpanForDate = (date: Date, isStartWeekFromMonday: boolean): Date[] => {
  const weekType = getWeekType(isStartWeekFromMonday);
  const mom = moment(date);
  const startOfWeek = moment(mom.startOf(weekType));
  const endOfWeek = moment(mom.endOf(weekType));
  const result: moment.Moment[] = [];
  for (let c = moment(startOfWeek); c.isSameOrBefore(endOfWeek); c = moment(c.add(1, 'day'))) {
    result.push(moment(c));
  }
  return result.map((m) => m.toDate());
};
