import { Injectable } from '@angular/core';
import { QueryParamsHandling } from '@angular/router';

import { CharterRoute, NavigationRequest, RouterStateUrl } from '../types';
import { RouterService } from '../router-service';
import { tripsPath } from './trips-path';
import { TripListParams, TripListQuickFilterType } from '../../features/trips/types/trip-list';
import { serializeBaseParamsToUrl } from '@rootTypes/utils/list-store-utils/serialize-base-params-to-url';
import { getInitialTripListState } from '../../features/trips/utils/get-initial-trip-list-state';
import { deserializeBaseParamsFromUrl } from '@rootTypes/utils/list-store-utils/deserialize-base-params-from-url';
import { deserializeQuickFilter, getTripListQuickFilter } from '../../features/trips/utils/get-trip-list-quick-filter';
import { deserializeLocationFilter } from '../../features/trips/utils/get-trip-list-location-filter';
import { deserializeStatusFilter } from '../../features/trips/utils/get-trip-list-status-filter';
import { deserializeTripListTypeFilter } from '../../features/trips/utils/get-trip-list-type-filter';
import { deserializePaymentStatusFilter } from '../../features/trips/utils/get-trip-list-payment-status-filter';
import { deserializeVehicleTypeFilter } from '../../features/trips/utils/get-trip-list-vehicle-type-filter';

export type TripListRouteData = Partial<TripListParams>;

export const tripListRoute: CharterRoute<TripListRouteData> = {
  path: '',
  isOnRoute(state: RouterStateUrl): boolean {
    return state.url.startsWith(`/${tripsPath}`);
  },
  parse(state: RouterStateUrl): TripListRouteData {
    const deserialized = deserializeBaseParamsFromUrl<TripListParams>(state.queryParams, 'tl', [
      deserializeQuickFilter,
      deserializeLocationFilter,
      deserializeStatusFilter,
      deserializeTripListTypeFilter,
      deserializePaymentStatusFilter,
      deserializeVehicleTypeFilter,
    ]);
    if (!deserialized.filters?.length) {
      deserialized.filters = [getTripListQuickFilter(TripListQuickFilterType.STARTING_TODAY)];
    }
    return deserialized;
  },
  request(data?: TripListRouteData, queryParamStrategy?: QueryParamsHandling, replaceUrl?: boolean): NavigationRequest {
    const params = data || {};
    if (!params.filters?.length) {
      params.filters = [getTripListQuickFilter(TripListQuickFilterType.STARTING_TODAY)];
    }
    const serialized = serializeBaseParamsToUrl(params, getInitialTripListState().params, 'tl');
    return {
      path: [`/${tripsPath}`],
      query: serialized,
    };
  },
  getHref(data?: TripListRouteData): string {
    return `/${tripsPath}`;
  },
};

@Injectable({ providedIn: 'root' })
export class TripListRouterService extends RouterService<TripListRouteData> {
  route = tripListRoute;
}
