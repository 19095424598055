import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageFooterComponent } from './page-footer/page-footer.component';
import { TypographyModule } from '../typography/typography.module';
import { ComponentsModule } from '../components/components.module';

@NgModule({
  declarations: [PageFooterComponent],
  imports: [CommonModule, TypographyModule, ComponentsModule],
  exports: [PageFooterComponent],
})
export class PageFooterModule {}
