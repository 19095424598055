import { AfterViewInit, Directive, ElementRef, Renderer2 } from '@angular/core';

const markSymbol = '#';

@Directive({
  selector: '[wpHardCoded]',
})
export class HardCodedDirective implements AfterViewInit {
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit() {
    const isDev = cEnvironment.envName === 'development';
    if (isDev) {
      const htmlEl: HTMLElement = this.el.nativeElement;
      const innerText = htmlEl.innerText || htmlEl.innerHTML || '';
      const updatedInnerText = `${markSymbol}${innerText}`;
      this.renderer.setProperty(htmlEl, 'innerText', updatedInnerText);
    }
  }
}
