import * as moment from 'moment-timezone';
import { RangePickerConfig } from '../entities/range-picker-config';

export interface RangePickerState {
  config: {
    entity: RangePickerConfig;
    readonly: boolean;
    updatedAt: number;
  };
  hovered: {
    date: moment.Moment | null;
    updatedAt: number;
  };
  currentView: {
    date: moment.Moment;
    updatedAt: number;
  };
  selected: {
    startDate: moment.Moment | null;
    endDate: moment.Moment | null;
    updatedAt: number;
  };
}

export const initialRangePickerStore: RangePickerState = {
  config: {
    readonly: false,
    entity: {
      numMonthsDisplayed: 2,
      isStartWeekFromMonday: false,
    },
    updatedAt: 0,
  },
  hovered: {
    date: null,
    updatedAt: 0,
  },
  currentView: {
    date: moment(),
    updatedAt: 0,
  },
  selected: {
    startDate: null,
    endDate: null,
    updatedAt: 0,
  },
};
