import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IconCloseModule } from '../icons/icon-close/icon-close.module';
import { ButtonsModule } from '../buttons/buttons.module';

import { PopupHeaderComponent } from './popup-header/popup-header.component';
import { PopupFooterComponent } from './popup-footer/popup-footer.component';
import { PopupContainerComponent } from './popup-container/popup-container.component';
import { PopupBodyComponent } from './popup-body/popup-body.component';

@NgModule({
  declarations: [PopupHeaderComponent, PopupFooterComponent, PopupContainerComponent, PopupBodyComponent],
  exports: [PopupHeaderComponent, PopupFooterComponent, PopupContainerComponent, PopupBodyComponent],
  imports: [CommonModule, IconCloseModule, ButtonsModule],
})
export class PopupElementsModule {}
