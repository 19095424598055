import { TripEditorQuoteState } from '../entities';

export const createInitialTripEditorQuoteState = (): TripEditorQuoteState => {
  return {
    catalogs: {
      isLoading: false,
    },
    selectedCatalogs: {},
    tripQuote: { isLoading: false },
  };
};
