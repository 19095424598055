import { TripEditorLegStop } from '../entities';
import { isTripEditorStopValid } from './is-trip-editor-stop-valid';

export const resetDateAndTimeForStopsStartingFrom = (
  stops: { [stopId: string]: TripEditorLegStop },
  stopIndex: number,
): { [stopId: string]: TripEditorLegStop } => {
  const result: { [stopId: string]: TripEditorLegStop } = {};
  Object.values(stops).forEach((stop) => {
    if (stop.stopIndex >= stopIndex) {
      const updatedStop: TripEditorLegStop = {
        ...stop,
        data: {
          ...stop.data,
          arrivalDate: null,
          arrivalTime: null,
          departureTime: null,
        },
      };
      updatedStop.isValid = isTripEditorStopValid(updatedStop);
      result[updatedStop.stopId] = updatedStop;
    } else {
      result[stop.stopId] = stop;
    }
  });
  return result;
};
