import {
  Component,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnInit,
  OnDestroy,
  EventEmitter,
  Output,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'wp-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputNumberComponent implements OnInit, OnDestroy {
  @Input() public control: UntypedFormControl;
  @Input() public controlStateChange?: Observable<void>;
  @Input() public label: string;
  @Input() public isPrefixDollar = false;

  // HTML input step attribute
  @Input() public step = 1;
  @Input() public min?: number;
  @Input() public max?: number;

  @Output() public valueChangedByUser = new EventEmitter<number>();

  public disabled: boolean;

  private sub = new Subscription();

  constructor(private cdRef: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.disabled = this.control.disabled;
    this.control.registerOnDisabledChange((disabled) => {
      this.disabled = disabled;
      this.cdRef.detectChanges();
    });
    if (this.controlStateChange) {
      const controlStateSub = this.controlStateChange.subscribe(() => {
        this.cdRef.detectChanges();
      });
      this.sub.add(controlStateSub);
    }
  }

  public ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  public onValueChanged(): void {
    this.valueChangedByUser.emit(this.control.value);
  }
}
