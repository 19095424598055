import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CanBeConfirmationToken, CanRequireConfirmation, ConfirmationOption, ConfirmationResponse } from './types';
import { ConfirmPopupConfig, PopupOptions } from '../../../popup/types';
import { PopupService } from '../../../popup/popup.service';
import { isConfirmationResponse } from './is-confirmation-response';
import { PromptConfirmationPopupComponent } from '../../../containers/prompt-confirmation-popup/prompt-confirmation-popup.component';
import { APIPromptDismissedByUserError } from './types/api-prompt-dismissed-by-user-error';

export type ConfirmationStream<T = any> = (response: CanRequireConfirmation<T>) => Observable<ConfirmationOption>;

const defaultPopupOptions: PopupOptions = {
  pageMaskZIndex: 14,
  contentZIndex: 15,
  closePrevious: false,
};

@Injectable({ providedIn: 'root' })
export class ConfirmationApiService {
  constructor(private popupableService: PopupService) {}

  public async handle<Request, Response>(
    apiRequestFn: (request: Request) => Promise<Response>,
    request: Request,
    overrideConfirmPopupConfig?: ConfirmPopupConfig,
    overrideConfirmPopupOptions?: PopupOptions,
    confirmationStream?: ConfirmationStream,
  ): Promise<Response> {
    const finalConfirmationStream =
      confirmationStream || this.getDefaultConfirmationStream(overrideConfirmPopupOptions);

    const response = await apiRequestFn(request);
    if (!isConfirmationResponse(response)) {
      return response;
    }

    const option = await finalConfirmationStream(response).toPromise();
    if (!option?.promptId || option?.action === 'dismiss') {
      throw new APIPromptDismissedByUserError('User dismissed api prompt');
    } else {
      return this.handle<any, Response>(
        apiRequestFn as (request: CanBeConfirmationToken<Request>) => Promise<Response>,
        {
          promptToken: response.apiPrompt.promptToken,
          promptId: option.promptId,
        },
        overrideConfirmPopupConfig,
        overrideConfirmPopupOptions,
        confirmationStream,
      );
    }
  }

  private getDefaultConfirmationStream(popupOptionsOverride: PopupOptions = {}): ConfirmationStream {
    const finalOptions: PopupOptions = {
      ...defaultPopupOptions,
      ...popupOptionsOverride,
    };
    return (response: CanRequireConfirmation<any>) => {
      return this.popupableService.openPopup<any, ConfirmationResponse, ConfirmationOption>(
        PromptConfirmationPopupComponent,
        response as ConfirmationResponse,
        { ...finalOptions },
      );
    };
  }
}
