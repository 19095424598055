import { isTripEditorStopValid } from './is-trip-editor-stop-valid';
import { HHMMTimeString } from '@rootTypes';
import { TripEditorLeg, TripEditorStopRef, TripEditorStopsState } from '../entities';
import { updateTripEditorLegOnStopChanged } from './update-trip-editor-leg-on-stop-changed';

export const onTripEditorTimeChanged = (
  state: TripEditorStopsState,
  property: 'arrivalTime' | 'departureTime',
  ref: TripEditorStopRef,
  value?: HHMMTimeString,
): TripEditorStopsState => {
  const leg: TripEditorLeg = state.form[ref.legName];
  const stop = leg.stops[ref.stopId];
  const updatedStop = {
    ...stop,
    data: {
      ...stop.data,
      [property]: value || null,
    },
  };
  if (property === 'arrivalTime') {
    // User should set departure time explicitly if arrival time was changed
    updatedStop.data.departureTime = null;
  }
  updatedStop.isValid = isTripEditorStopValid(updatedStop);

  return {
    ...state,
    form: {
      ...state.form,
      [ref.legName]: updateTripEditorLegOnStopChanged(leg, updatedStop),
    },
  };
};
