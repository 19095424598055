import { createSelector } from '@ngrx/store';
import { selectTripEditorState } from '../trip-editor-feature.selector';

const selectState = createSelector(selectTripEditorState, (state) => state.bookingContact);
export const selectBookingContactSelection = createSelector(selectState, (state) => state.selection);
export const selectBookingContactId = createSelector(selectBookingContactSelection, (state) => state?.entityId);
export const selectIsBookingContactSelected = createSelector(selectBookingContactId, (id) => !!id);

const selectDetailsState = createSelector(selectState, (state) => state.contactDetails);
export const selectBookingContactDetails = createSelector(selectDetailsState, (state) => state.entity);
export const selectBookingContactDetailsLoading = createSelector(selectDetailsState, (state) => state.isLoading);
export const selectBookingContactDetailsError = createSelector(selectDetailsState, (state) => state.error);

export const selectBookingContactOrganizationName = createSelector(
  selectBookingContactDetails,
  (state) => state?.organizationName,
);

export const selectIsChangeBookingContactAllowed = createSelector(selectState, (state) => state.isChangeContactAllowed);
