import { Injectable } from '@angular/core';

import { CharterRoute, NavigationRequest, RouterStateUrl } from '../types';
import { createTripPath } from './create-trip-path';
import { QueryParamsHandling } from '@angular/router';
import { RouterService } from '../router-service';

export type CreateTripReservationRouteData = {
  tripItineraryId: string;
};

const routePath = 'reserve';

export const createTripReservationRoute: CharterRoute<CreateTripReservationRouteData> = {
  path: `${routePath}/:tripItineraryId`,
  isOnRoute(state: RouterStateUrl): boolean {
    return state.url.startsWith(`/${createTripPath}/${routePath}`);
  },
  parse(state: RouterStateUrl): CreateTripReservationRouteData {
    return {
      tripItineraryId: state.params.tripItineraryId,
    };
  },
  request(
    data: CreateTripReservationRouteData,
    queryParamStrategy?: QueryParamsHandling,
    replaceUrl?: boolean,
  ): NavigationRequest {
    return {
      path: [`/${createTripPath}`, routePath, data.tripItineraryId],
    };
  },
  getHref(data: CreateTripReservationRouteData): string {
    return `/${createTripPath}/${routePath}/${data.tripItineraryId}`;
  },
};

@Injectable({ providedIn: 'root' })
export class CreateTripReservationRouterService extends RouterService<CreateTripReservationRouteData> {
  route = createTripReservationRoute;
}
