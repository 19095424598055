import * as moment from 'moment-timezone';
import { TimeNumber } from 'src/app/types/entities/common/time-number';

export const getDisplayTimeNumber = (hhMM?: TimeNumber): string | undefined => {
  if (typeof hhMM !== 'number') {
    return undefined;
  }
  const chars: string = hhMM + '';
  const hh: number = chars.length > 2 ? parseInt(chars.slice(0, -2), 10) : 0;
  const mm: number = parseInt(chars.slice(-2), 10);
  const hhStr = hh < 10 ? '0' + hh : hh + '';
  const mmStr = mm < 10 ? '0' + mm : mm + '';

  return moment(hhStr + mmStr, ['HHmm']).format('hh:mm A');
};
