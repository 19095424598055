import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';

@Pipe({
  name: 'displayHHMMTimeString',
})
export class DisplayHHMMTimeStringPipe implements PipeTransform {
  transform(value: string, format = 'h:mm a'): string | '' {
    if (typeof value !== 'string' || value.length !== 4) {
      return '--';
    }
    const hh = parseInt(value.slice(0, 2), 10);
    const mm = parseInt(value.slice(-2), 10);
    const date = new Date();
    date.setHours(hh);
    date.setMinutes(mm);
    return formatDate(date, format, 'en-US');
  }
}
