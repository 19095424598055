<header>
  <div class="content">
    <c-icon
      [path]="logo"
      [widthStr]="'108px'"
      [heightStr]="'46px'"
      [alt]="'Zum'"
    ></c-icon>
    <div class="header-right">
      <!--      <wp-link [isUnderlined]="false">Become a partner</wp-link>-->

      <!--      <c-divider-vertical-line-->
      <!--        [height]="'32px'"-->
      <!--        [containerHeight]="'32px'"-->
      <!--        [margin]="'0 24px'"-->
      <!--      ></c-divider-vertical-line>-->

      <!--      <wp-link [isUnderlined]="false" class="link-right-margin">-->
      <!--        Support-->
      <!--      </wp-link>-->
    </div>
  </div>
  <div class="horizontal-line"></div>
</header>
