import { PortalEntity, PortalEntityType } from '@rootTypes';
import { createReducer, on } from '@ngrx/store';
import { saveEntityData } from './actions';

export type EntitiesDataState = {
  [EntityType in PortalEntityType]?: {
    [entityId: string]: PortalEntity;
  };
};

export const getInitialEntitiesDataState = (): EntitiesDataState => {
  return {};
};

export const entitiesDataReducer = createReducer<EntitiesDataState>(
  getInitialEntitiesDataState(),
  on(saveEntityData, (state, action) => {
    const entity: PortalEntity = action.entity;
    if (!entity) {
      return { ...state };
    }
    return {
      ...state,
      [entity.type]: {
        ...(state[entity.type] || {}),
        [entity.entityId]: entity,
      },
    };
  }),
);
