<c-popup-container>
  <c-popup-header (closeRequested)="onCancel()"> {{title}} </c-popup-header>
  <c-popup-body>
    <div class="body">
      <div class="message" [innerHTML]="messageHTML"></div>
      <div class="buttons">
        <div *ngFor="let o of options" class="btn">
          <ng-container
            *ngIf="o.style === 'POSITIVE' || o.style === 'DESTRUCTIVE'; else dismissOptionTemplate"
          >
            <wp-btn-primary
              [color]="o.style === 'DESTRUCTIVE' ? 'red' : 'green'"
              [width]="'auto'"
              [padding]="'0 12px'"
              (clicked)="onOptionClick(o)"
            >
              {{ o.displayText }}
            </wp-btn-primary>
          </ng-container>
          <ng-template #dismissOptionTemplate>
            <wp-btn-secondary
              [width]="'auto'"
              [padding]="'0 12px'"
              (clicked)="onOptionClick(o)"
            >
              {{ o.displayText }}
            </wp-btn-secondary>
          </ng-template>
        </div>
      </div>
    </div>
  </c-popup-body>
</c-popup-container>
