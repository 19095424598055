import { createAction, props } from '@ngrx/store';
import { Address, HHMMTimeString, WpError, YYYYMMDDString } from '@rootTypes';
import { TripEditorLegName, TripEditorStopRef } from './types';
import {
  GetCharterDirectionsRequest,
  GetCharterDirectionsResponse,
  GetCharterTripItineraryResponse,
  GetCharterTripPurposeResponse,
  ValidateCharterTripItineraryRegionDetected,
} from '@rootTypes/api';

const legComponent = '[TripEditorStopsLeg]:';
export const tripEditorLegAddressChanged = createAction(
  `${legComponent} Address changed`,
  props<{ ref: TripEditorStopRef; value?: Address }>(),
);
export const tripEditorLegArrivalDateChanged = createAction(
  `${legComponent} Arrival date changed`,
  props<{ ref: TripEditorStopRef; value?: YYYYMMDDString }>(),
);
export const tripEditorLegArrivalTimeChanged = createAction(
  `${legComponent} Arrival time changed`,
  props<{ ref: TripEditorStopRef; value?: HHMMTimeString }>(),
);
export const tripEditorLegDepartureTimeChanged = createAction(
  `${legComponent} Departure time changed`,
  props<{ ref: TripEditorStopRef; value?: HHMMTimeString }>(),
);
export const tripEditorLegKeepDriverChanged = createAction(
  `${legComponent} Keep driver changed`,
  props<{ isKeepDriverForDuration: boolean }>(),
);
export const tripEditorLegStopAdded = createAction(
  `${legComponent} Stop added`,
  props<{ legName: TripEditorLegName }>(),
);
export const tripEditorLegStopRemoved = createAction(
  `${legComponent} Stop removed`,
  props<{ ref: TripEditorStopRef }>(),
);

const formComponent = '[TripEditorStops]:';
export const initTripEditorStops = createAction(`${formComponent} Initialize`);
export const destroyTripEditorStops = createAction(`${formComponent} Destroy`);
export const tripEditorStopsTripTypeChanged = createAction(
  `${formComponent} Trip type changed`,
  props<{ isRoundTrip: boolean }>(),
);
export const tripEditorStopsPassengerCountChanged = createAction(
  `${formComponent} Passenger count changed`,
  props<{ value?: number }>(),
);
export const tripEditorStopsWheelchairAccessChanged = createAction(
  `${formComponent} Wheelchair access changed`,
  props<{ value: boolean }>(),
);

export const validateTripItineraryRequested = createAction(`${formComponent} Validate trip itinerary requested`);
export const validateTripItinerarySuccess = createAction(
  `${formComponent} Validate trip itinerary success`,
  props<{ response: ValidateCharterTripItineraryRegionDetected }>(),
);
export const validateTripItineraryFailed = createAction(
  `${formComponent} Validate trip itinerary failed`,
  props<{ error: WpError }>(),
);

const effects = '[TripEditorStopsEffects]:';
export const tripEditorStopsGetDirectionRequested = createAction(
  `${effects} Get direction requested`,
  props<{ legName: TripEditorLegName; request: GetCharterDirectionsRequest }>(),
);
export const tripEditorStopsGetDirectionSuccess = createAction(
  `${effects} Get direction success`,
  props<{ legName: TripEditorLegName; direction: GetCharterDirectionsResponse }>(),
);
export const tripEditorStopsGetDirectionFailed = createAction(
  `${effects} Get direction failed`,
  props<{ legName: TripEditorLegName; error: WpError }>(),
);
export const tripEditorStopsSetFormFromItinerary = createAction(
  `${effects} Set form from itinerary`,
  props<{ itinerary: GetCharterTripItineraryResponse }>(),
);
export const tripEditorStopsReturnLegAutofilled = createAction(`${effects} Return leg autofilled`);

export const tripEditorStopsTopBarCollapseStopsRequested = createAction(
  '[TripEditorStopsTopBar] Collapse stops requested',
);

export const getTripPurposeRequested = createAction(`${effects} Get trip purpose requested`);
export const getTripPurposeSuccess = createAction(
  `${effects} Get trip purpose success`,
  props<{ response: GetCharterTripPurposeResponse }>(),
);
export const getTripPurposeFailed = createAction(`${effects} Get trip purpose failed`, props<{ error: WpError }>());
export const tripEditorStopsTripPurposeChanged = createAction(
  `${formComponent} Trip purpose changed`,
  props<{ tripPurpose: string }>(),
);
export const tripEditorStopsActivityDescriptionChanged = createAction(
  `${formComponent} Activity description changed`,
  props<{ activityDescription: string }>(),
);
