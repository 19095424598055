import { createSelector } from '@ngrx/store';
import { getAuthFeature } from './feature-selector';
import { CharterPermissions } from '@rootTypes/entities/auth/charter-permissions';
import { TripListQuickFilterType } from '../../features/trips/types/trip-list';

const getAccountState = createSelector(getAuthFeature, (state) => state.account);

export const isAccountLoading = createSelector(getAccountState, (state) => state?.isLoading || false);

export const getAccount = createSelector(getAccountState, (state) => state.entity);

const getSignedInDistrict = createSelector(getAccount, (state) => state?.district);
export const getSignedInSchoolEmployeeId = createSelector(getSignedInDistrict, (state) => state?.schoolEmployeeId);

export const isHomeInitialized = createSelector(getAuthFeature, (state) => state.isHomeInitialized);

export const getAccountPermissions = createSelector(getAccount, (state) => state?.permissions || []);

export const hasPermission = (permission: CharterPermissions) =>
  createSelector(getAccountPermissions, (permissions) => permissions.some((p) => p === permission));

export const isCharterTripBookingEditPermission = hasPermission(CharterPermissions.CHARTER_TRIP_BOOKING_EDIT);
export const isCharterTripCancelEditPermission = hasPermission(CharterPermissions.CHARTER_TRIP_CANCEL_EDIT);

const getCharterConfigState = createSelector(getAuthFeature, (state) => state.appConfig?.entity);

const getCharterConfigFilterState = createSelector(getCharterConfigState, (state) => state?.filters);

export const getShowAllTripsFilterLabel = createSelector(
  getCharterConfigFilterState,
  (state) => state?.showAllTripsFilterLabel,
);

export const getBannerLabel = createSelector(getCharterConfigState, (state) => state?.bannerLabel);

export const getTripListQuickFilterOptions = createSelector(getShowAllTripsFilterLabel, (showAllLabel) => {
  return [
    {
      displayLabel: 'Trips starting today & beyond',
      value: TripListQuickFilterType.STARTING_TODAY,
    },
    {
      displayLabel: showAllLabel || 'All trips',
      value: TripListQuickFilterType.MY_CAMPUS,
    },
    {
      displayLabel: 'All trips created by me',
      value: TripListQuickFilterType.MY_TRIPS,
    },
  ];
});
