<div class="btn-increment" tabindex="0">
  <div class="btn-increment-circle">
    <svg
      width="10px"
      height="10px"
      viewBox="0 0 10 10"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Page-1"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="05_edit_schedule_modal"
          transform="translate(-692.000000, -596.000000)"
          class="icon-path"
        >
          <g
            id="edit-schedule-modal"
            transform="translate(255.000000, 153.000000)"
          >
            <g
              id="route-time-entry"
              transform="translate(40.000000, 154.000000)"
            >
              <g
                id="Group-2-Copy-10"
                transform="translate(0.000000, 87.000000)"
              >
                <g
                  id="Group-5-Copy"
                  transform="translate(38.000000, 180.000000)"
                >
                  <g
                    id="2-element-/-field-/-stepper"
                    transform="translate(272.000000, 0.000000)"
                  >
                    <g transform="translate(4.000000, 16.000000)" id="add">
                      <g transform="translate(78.000000, 1.000000)">
                        <g
                          id="Group-4"
                          transform="translate(5.000000, 5.000000)"
                        >
                          <path
                            d="M6,0 L6,3.999 L10,4 L10,6 L6,6 L6,10 L4,10 L4,6 L0,6 L0,4 L4,4 L4,0 L6,0 Z"
                            id="Combined-Shape"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
</div>
