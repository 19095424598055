import { TripEditorLeg, TripEditorLegName } from '../entities';
import { generateTripEditorStopId } from './generate-trip-editor-stop-id';

export const createInitialTripEditorLeg = (isReturnLeg: boolean): TripEditorLeg => {
  const firstStopId = generateTripEditorStopId();
  const lastStopId = generateTripEditorStopId();
  return {
    isReturnLeg,
    legName: isReturnLeg ? TripEditorLegName.RETURN : TripEditorLegName.OUTBOUND,
    summary: { isLoading: false },
    isReadyForDirectionRequest: false,
    stops: {
      [firstStopId]: {
        stopId: firstStopId,
        stopIndex: 0,
        isFirst: true,
        isLast: false,
        isValid: false,
        data: {
          address: null,
          arrivalDate: null,
          arrivalTime: null,
          departureTime: null,
        },
      },
      [lastStopId]: {
        stopId: lastStopId,
        stopIndex: 1,
        isFirst: false,
        isLast: true,
        isValid: false,
        data: {
          address: null,
          arrivalDate: null,
          arrivalTime: null,
          departureTime: null,
        },
      },
    },
  };
};
