import {
  ValidateCharterTripItineraryInvalid,
  ValidateCharterTripItineraryRegionDetected,
  ValidateCharterTripItinerarySelectRegionRequired,
} from './index';
import { ValidateTripItineraryErrorType } from '@rootTypes';

export const validateTripItineraryRegionDetectedMock: ValidateCharterTripItineraryRegionDetected = {
  region: {
    id: 'SFBayArea',
    label: 'SF Bay Area',
  },
  tripItineraryId: 'tripItineraryId',
};

export const validateTripItinerarySelectRegionRequiredMock: ValidateCharterTripItinerarySelectRegionRequired = {
  regions: [
    {
      id: 'SFBayArea',
      label: 'SF Bay Area',
    },
    {
      id: 'LosAngeles',
      label: 'Los Angeles',
    },
    {
      id: 'Seattle',
      label: 'Seattle',
    },
  ],
  errorType: ValidateTripItineraryErrorType.REGION_UNAVILABLE,
};

export const validateTripItineraryInvalidMock: ValidateCharterTripItineraryInvalid = {
  error: 'Invalid itinerary',
  errorType: ValidateTripItineraryErrorType.INVALID_ITINERARY,
};
