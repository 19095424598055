import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';

import { loginWithTokenRequested } from '../../store/auth/auth.actions';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard {
  constructor(private store: Store) {}

  public async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const { token, target } = route.queryParams;
    this.store.dispatch(loginWithTokenRequested({ token, target }));
    await this.waitTillAuthEffectMakesRedirection();
    return false;
  }

  private waitTillAuthEffectMakesRedirection(): Promise<void> {
    return new Promise(() => {});
  }
}
