import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
  TrackByFunction,
  Output,
  EventEmitter,
} from '@angular/core';
import { DateRangePickerService } from '../date-range-picker.service';
import { Observable } from 'rxjs';
import * as fromTypes from '../types';
import { map, skip } from 'rxjs/operators';
import * as moment from 'moment-timezone';
import { DayConfigs } from '../types';

@Component({
  selector: 'wp-multi-date-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  providers: [DateRangePickerService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateRangePickerComponent implements OnInit, OnChanges {
  /**
   * YYYYMMDD[]
   */
  @Input() public selected: fromTypes.YYYYMMDDString[];
  @Input() public disableDatesAfter: fromTypes.YYYYMMDDString;
  @Input() public disableDatesBefore: fromTypes.YYYYMMDDString;

  /**
   * Allow to select only one date
   */
  @Input() public isSingleSelect: boolean;
  /**
   * Select dates by week
   */
  @Input() public isWeekSelect: boolean;
  /**
   * Disable whole week if the disabled range falls in the mid-week (only when isWeekSelect = true)
   */
  @Input() public isStrictDisableWeek: boolean;
  /**
   * Highlight days with colors, tooltips
   */
  @Input() public dayConfigs: DayConfigs;
  /**
   * Number of months displayed
   */
  @Input() public monthCount!: number;
  /**
   * Disable selecting dates
   */
  @Input() public readonly = false;

  /**
   * Start week from monday
   */
  @Input() public isStartWeekFromMonday = true;

  @Output() public selectedChanged: EventEmitter<fromTypes.YYYYMMDDString[]> = new EventEmitter<
    fromTypes.YYYYMMDDString[]
  >();

  public readonly$: Observable<boolean>;
  public months$!: Observable<fromTypes.RangePickerMonth[]>;
  public decades$!: Observable<fromTypes.RangePickerDecade[]>;
  public currentView$: Observable<fromTypes.ViewType>;
  public isSingleMonth$: Observable<boolean>;
  public viewTypes = fromTypes.ViewType;
  public trackByFn: TrackByFunction<fromTypes.RangePickerMonth> = (index: number, item: fromTypes.RangePickerMonth) => {
    return item.label;
  };

  constructor(private store: DateRangePickerService, private cd: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.dayConfigs) {
      this.store.setDayConfigs(changes.dayConfigs.currentValue || {});
    }
    if (changes && changes.readonly) {
      this.store.setReadonly(changes.readonly.currentValue || false);
    }
    if (changes && changes.isSingleSelect) {
      const val = changes.isSingleSelect.currentValue || false;
      this.store.setIsSingleSelect(val);
    }
    if (changes && changes.isWeekSelect) {
      const val = changes.isWeekSelect.currentValue || false;
      this.store.setIsWeekSelect(val);
    }
    if (changes && changes.isStrictDisableWeek) {
      const val = changes.isStrictDisableWeek.currentValue || false;
      this.store.setIsStrictDisableWeek(val);
    }
    if (changes && changes.disableDatesAfter) {
      const val = changes.disableDatesAfter.currentValue || null;
      const m = val ? this.getMomentFromString(val) : null;
      this.store.setDisableAfterDate(m);
    }
    if (changes && changes.disableDatesBefore) {
      const val = changes.disableDatesBefore.currentValue || null;
      const m = val ? this.getMomentFromString(val) : null;
      this.store.setDisableBeforeDate(m);
    }
    if (changes && changes.isStartWeekFromMonday) {
      const val = changes.isStartWeekFromMonday.currentValue || false;
      this.store.setIsStartWeekFromMonday(val);
      const selected = this.selected || [];
      this.store.setSelectedDates(selected.map((v) => this.getMomentFromString(v)));
    }
    if (changes && changes.selected) {
      const val = changes.selected.currentValue || ([] as fromTypes.YYYYMMDDString[]);
      this.store.setSelectedDates(val.map((v) => this.getMomentFromString(v)));
    }
  }

  ngOnInit(): void {
    this.init();
  }

  public onForwardClick(): void {
    this.store.forward();
  }

  public onBackwardClick(): void {
    this.store.backward();
  }

  public onMouseDown(): void {
    this.store.setIsMouseDown(true);
  }

  public onMouseUp(): void {
    this.store.setIsMouseDown(false);
  }

  private init(): void {
    if (this.monthCount) {
      this.store.setMonthView(this.monthCount);
    }
    if (this.selected && this.selected[0]) {
      this.store.setCurrentMonth(this.getMomentFromString(this.selected[0]));
    }
    this.readonly$ = this.store.isReadOnly();
    this.currentView$ = this.store.getCurrentViewType$();
    this.months$ = this.store.months$();
    this.isSingleMonth$ = this.store.getConfigs().pipe(map((config) => config.numMonthsDisplayed <= 1));
    this.decades$ = this.store.decades$();
    this.store
      .selectedChangedByUserAction$()
      .pipe(skip(1))
      .subscribe((val) => {
        this.selectedChanged.emit((val || []).map((s) => s.format('YYYYMMDD')));
      });
    this.cd.detectChanges();
  }

  private getMomentFromString(source: fromTypes.YYYYMMDDString): moment.Moment {
    return moment(source, 'YYYYMMDD');
  }
}
