import { GetCharterCatalogResponse } from './index';

export const getCarterCatalogMockResponse: GetCharterCatalogResponse = {
  serviceLocationId: 'serviceLocationId',
  catalogId: 'categoryId1',
  displayName: 'Yellow School Bus (Small)',
  description: 'Yellow School Bus description text',
  capacity: '5-12',
  availabilityCount: 7,
  priceCents: 80120,
  itemizedPrice: [],
  images: ['https://zum-third-party.s3.us-west-2.amazonaws.com/assets/small-bus.jpg'],
  features: {
    wifi: false,
    airConditioned: true,
    wheelChairAccessible: true,
  },
};
