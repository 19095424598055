import { Component, OnInit, ChangeDetectionStrategy, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'c-divider-horizontal-line',
  templateUrl: './divider-horizontal-line.component.html',
  styleUrls: ['./divider-horizontal-line.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DividerHorizontalLineComponent implements OnChanges {
  @Input() public width = 'auto';
  @Input() public margin: string;
  @Input() public accent: boolean;

  public styles: { [key: string]: string };

  constructor() {}

  public ngOnChanges(): void {
    this.styles = {
      width: this.width,
      margin: this.margin,
    };
  }
}
