import { BehaviorSubject, Observable, Subject } from 'rxjs';

export class PopupRef<D, R> {
  public data: D;
  public isOpen$: Observable<boolean>;

  private closeSubj: Subject<R> = new Subject<R>();
  private isOpen$$ = new BehaviorSubject(true);

  constructor() {
    this.isOpen$ = this.isOpen$$.asObservable();
  }

  public close(result?: R): void {
    this.closeSubj.next(result);
    this.isOpen$$.next(false);
  }

  public closed(): Observable<R> {
    return this.closeSubj.asObservable();
  }
}
