import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PopupComponent } from './popup/popup.component';
import { AbstractPopupComponent, ConfirmPopupConfig, PopupOptions } from './types';
import { ConfirmPopupComponent } from './confirm-popup/confirm-popup.component';
import { WpError } from '@rootTypes';
import { ErrorPopupComponent, ErrorPopupData } from './error-popup/error-popup.component';
import { ErrorParserService } from './error-parser.service';

@Injectable({
  providedIn: 'root',
})
export class PopupService {
  constructor(private errorParser: ErrorParserService) {}

  public openPopup<C extends AbstractPopupComponent<D, R>, D = any, R = any>(
    component: C,
    data: D,
    options?: PopupOptions,
  ): Observable<R> {
    return PopupComponent.open<C, D, R>(component, data, options);
  }

  public openConfirmPopup(confirmPopupConfig: ConfirmPopupConfig, options?: PopupOptions): Observable<boolean> {
    if (options && options.closePrevious) {
      PopupComponent.close();
    }
    return PopupComponent.open<any, ConfirmPopupConfig, boolean>(ConfirmPopupComponent, confirmPopupConfig, options);
  }

  public openErrorPopup(error: WpError, hideErrorHeader = false, closePrevious?: boolean): void {
    const errorStatements = this.errorParser.getErrorStatements(error);
    const data: ErrorPopupData = {
      errorStatements,
      hideErrorHeader,
      error,
      confirmBtnText: 'OK',
    };
    this.openPopup<any, any>(ErrorPopupComponent, data, {
      contentZIndex: 11,
      pageMaskZIndex: 10,
      closePrevious,
    });
  }

  public closeAll(): void {
    PopupComponent.close();
  }
}
