import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { InMemoryCache } from '@rootTypes/utils';
import { HttpResponseStatus } from './models/http-response-status';
import { SessionService } from './session.service';
import { RollbarApiError } from '../../../rollbar';
import { BaseApiService } from './base-api.service';
import { ImageApiUtilsService } from './models/image-api-utils.service';
import { UserRoleService } from './user-role.service';
import { DownloadImageSize } from '@rootTypes';

@Injectable({
  providedIn: 'root',
})
export class ImageApiService {
  private cache: InMemoryCache;

  constructor(
    private session: SessionService,
    private api: BaseApiService,
    private imageUtils: ImageApiUtilsService,
    private zone: NgZone,
    private userRoleService: UserRoleService,
  ) {
    this.cache = new InMemoryCache(this.zone, 120, 5);
  }

  public getImageBase64(imagePath: string, size?: DownloadImageSize): Promise<string> {
    const imageId = this.getPhotoId(imagePath, size);
    let imagePromise: Promise<string> = this.cache.get(imageId);
    if (!imagePromise) {
      const imageUrl = this.getPhotoUrl(imagePath, size);
      imagePromise = this.getDownloadImagePromise(imageUrl);
      this.cache.set(imageId, imagePromise);
    }
    return imagePromise;
  }

  /**
   * @return imagePath
   */
  public uploadImage(imageBase64: string): Observable<string> {
    return this.api
      .post('uploadImage', { data: imageBase64 }, `images/${this.userRoleService.getUserRole()}`)
      .pipe(map((res) => res?.imagePath));
  }

  public cleanCache(): void {
    this.cache.clear();
  }

  private async getDownloadImagePromise(imageUrl: string): Promise<string> {
    let imageBuffer: ArrayBuffer;
    try {
      imageBuffer = await this.imageUtils.getImageArrayBuffer(imageUrl);
    } catch (error) {
      const errorRes = error as HttpErrorResponse;
      if (errorRes.status === HttpResponseStatus.UNAUTHORIZED) {
        try {
          imageBuffer = await this.session.refreshSessionAndRetry(() => this.imageUtils.getImageArrayBuffer(imageUrl));
        } catch (retryError) {
          console.error(new RollbarApiError('downloadImage', retryError.status), retryError);
          throw retryError;
        }
      } else {
        console.error(new RollbarApiError('downloadImage', errorRes.status), errorRes);
        throw errorRes;
      }
    }
    // Source: https://medium.com/front-end-weekly/fetching-images-with-the-fetch-api-fb8761ed27b2
    const imageBase64 = 'data:image/jpeg;base64,' + this.imageUtils.arrayBufferToBase64(imageBuffer);
    return imageBase64;
  }

  private getPhotoId(imagePath: string, size?: DownloadImageSize): string {
    let id = imagePath;
    if (size) {
      id += size.width || '';
      id += size.height || '';
    }
    return id;
  }

  private getPhotoUrl(src: string, size?: DownloadImageSize): string {
    const imgWidth = size?.width ? `&width=${size.width}` : '';
    const imgHeight = size?.height ? `&height=${size.height}` : '';
    const imgSize = `${imgWidth}${imgHeight}`;
    const userRole = this.userRoleService.getUserRole();
    return `${cEnvironment.apiBaseUrl}/images/${userRole}/downloadImage?imagePath=${src}${imgSize}`;
  }
}
