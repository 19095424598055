import { UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { hasPermission } from '../../store/auth/auth.selectors';
import { CharterPermissions } from '@rootTypes/entities/auth/charter-permissions';
import { NoPermissionsRouterService } from '../../routes/core/no-permissions';

@Injectable({
  providedIn: 'root',
})
export class TripsViewGuard {
  constructor(private store: Store, private noPermissionsRoute: NoPermissionsRouterService) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.store.select(hasPermission(CharterPermissions.CHARTER_TRIP_VIEW)).pipe(
      take(1),
      map((hasTripViewPermissions) => {
        if (!hasTripViewPermissions) {
          return this.noPermissionsRoute.getUrlTree({});
        }
        return true;
      }),
    );
  }
}
