import { Injectable } from '@angular/core';

import { CharterRoute, NavigationRequest, RouterStateUrl } from '../types';
import { createTripPath } from './create-trip-path';
import { QueryParamsHandling } from '@angular/router';
import { RouterService } from '../router-service';

export interface CreateTripSuccessRouteData {
  tripId: string;
}

const routePath = 'all-set';

export const createTripSuccessRoute: CharterRoute<CreateTripSuccessRouteData> = {
  path: `${routePath}/:tripId`,
  isOnRoute(state: RouterStateUrl): boolean {
    return state.url.startsWith(`/${createTripPath}/${routePath}`);
  },
  parse(state: RouterStateUrl): CreateTripSuccessRouteData {
    return {
      tripId: state.params.tripId,
    };
  },
  request(
    data: CreateTripSuccessRouteData,
    queryParamStrategy?: QueryParamsHandling,
    replaceUrl?: boolean,
  ): NavigationRequest {
    const request: NavigationRequest = {
      path: [`/${createTripPath}`, routePath, data.tripId],
    };
    return request;
  },
  getHref(data: CreateTripSuccessRouteData): string {
    return `/${createTripPath}/${routePath}/${data.tripId}`;
  },
};

@Injectable({ providedIn: 'root' })
export class CreateTripSuccessRouterService extends RouterService<CreateTripSuccessRouteData> {
  route = createTripSuccessRoute;
}
