import { BaseDate, TimeFormat, TimezoneString } from './base-date';
import * as moment from 'moment-timezone';
/**
 * Like 20200818
 */
export type YYYYMMDDString = string;

export class YearMonthDay extends BaseDate {
  public static today(timezone: string = moment.tz.guess()): YYYYMMDDString {
    return moment(moment().tz(timezone).startOf('day')).format('YYYYMMDD');
  }

  public static tomorrow(timezone: string = moment.tz.guess()): YYYYMMDDString {
    return moment(moment().tz(timezone).add(1, 'day').startOf('day')).format('YYYYMMDD');
  }

  public static fromDate(date: Date): YYYYMMDDString {
    return moment(date).format('YYYYMMDD');
  }

  private tz: TimezoneString;

  constructor(value: YYYYMMDDString, tz?: TimezoneString) {
    super(value, TimeFormat.YEAR_MONTH_DAY);
    this.tz = tz || moment.tz.guess();
  }

  toMoment(tz: string = this.tz): moment.Moment {
    return moment.tz(this.value as string, 'YYYYMMDD', tz);
  }

  isAfterOrEqual(other: YYYYMMDDString, tz: string = this.tz): boolean {
    const otherMoment = moment.tz(other as string, 'YYYYMMDD', tz);
    return this.toMoment(tz).isSameOrAfter(otherMoment);
  }

  isBeforeOrEqual(other: YYYYMMDDString, tz: string = this.tz): boolean {
    const otherMoment = moment.tz(other as string, 'YYYYMMDD', tz);
    return this.toMoment(tz).isSameOrBefore(otherMoment);
  }

  formatTo(format = 'ddd MMM DD, YYYY'): string {
    return this.toMoment().format(format);
  }
}
