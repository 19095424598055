import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

/**
 * https://stackoverflow.com/questions/42878197/angular-2-how-to-set-animations-on-a-directive
 */
@Directive({
  selector: '[wpExpand]',
})
export class ExpandDirective implements AfterViewInit {
  @Input()
  set show(show: boolean) {
    this.isShown = show;
    if (!this.element) {
      return;
    }
    this.updateHeight(this.isShown);
    this.updateOpacity(this.isShown);
    this.isInitialized = true;
  }

  @Input()
  displayCollapsedContent = false;

  private isShown: boolean;
  private isInitialized: boolean;
  private element: HTMLElement;

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    this.element = this.el.nativeElement;
    this.element.style.overflow = 'hidden';
    this.element.style.height = '0px';
    this.element.style.opacity = this.getCollapsedContentOpacity();
    this.element.style.transition = 'height .2s ease-in, opacity .3s ease-in';
    if (!this.isInitialized && this.isShown) {
      this.updateHeight(this.isShown);
      this.updateOpacity(this.isShown);
      this.isInitialized = true;
    }
  }

  private updateHeight(visible: boolean): void {
    const delay = 0;
    this.element.style.overflow = 'hidden';
    setTimeout(() => {
      const prevHeight = visible ? '0px' : this.element.scrollHeight + 'px';
      let newHeight;
      if (visible) {
        this.element.style.height = 'auto';
        newHeight = this.element.scrollHeight + 'px';
      } else {
        newHeight = '0px';
      }
      this.element.style.height = prevHeight;
      setTimeout(() => {
        this.element.style.height = newHeight;
        if (visible) {
          setTimeout(() => {
            this.element.style.height = 'auto';
            this.element.style.overflow = 'visible';
          }, 200);
        }
      }, 50);
    }, delay);
  }

  private updateOpacity(visible: boolean): void {
    setTimeout(() => {
      this.element.style.opacity = visible ? '1' : this.getCollapsedContentOpacity();
    }, 100);
  }

  private getCollapsedContentOpacity(): string {
    return this.displayCollapsedContent ? '1' : '0';
  }
}
