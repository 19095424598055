import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { CharterRideDirection } from '@rootTypes/entities/ride-details';

@Pipe({
  name: 'displayCharterRideDirection',
})
export class DisplayCharterRideDirectionPipe implements PipeTransform {
  constructor(private store: Store) {}

  public transform(direction: CharterRideDirection): string {
    return direction === CharterRideDirection.RETURN ? 'Return' : 'Outbound';
  }
}
