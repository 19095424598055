import { Injectable } from '@angular/core';

import { CharterRoute, NavigationRequest, RouterStateUrl } from '../types';
import { createTripPath } from './create-trip-path';
import { QueryParamsHandling } from '@angular/router';
import { RouterService } from '../router-service';

export interface CreateTripStopsRouteData {}

const routePath = 'stops';

export const createTripStopsRoute: CharterRoute<CreateTripStopsRouteData> = {
  path: routePath,
  isOnRoute(state: RouterStateUrl): boolean {
    return state.url.startsWith(`/${createTripPath}/${routePath}`);
  },
  parse(state: RouterStateUrl): CreateTripStopsRouteData {
    return {};
  },
  request(
    data?: CreateTripStopsRouteData,
    queryParamStrategy?: QueryParamsHandling,
    replaceUrl?: boolean,
  ): NavigationRequest {
    return {
      path: [`/${createTripPath}`, routePath],
    };
  },
  getHref(data?: CreateTripStopsRouteData): string {
    return `/${createTripPath}/${routePath}`;
  },
};

@Injectable({ providedIn: 'root' })
export class CreateTripStopsRouterService extends RouterService<CreateTripStopsRouteData> {
  route = createTripStopsRoute;
}
