<div class="autocomplete">
  <mat-form-field [hideRequiredMarker]="true">
    <mat-label>{{label}}</mat-label>
    <input
      type="text"
      matInput
      [formControl]="control"
      [matAutocomplete]="autocomplete"
      #autocompleteInput
      (click)="$event.stopPropagation()"
    />
    <mat-autocomplete #autocomplete="matAutocomplete">
      <mat-option
        *ngFor="let option of searchedOptions$ | async"
        [value]="option.original"
      >
        <div class="option">{{ option.original }}</div>
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="control.hasError('required')">
      {{requiredErrorText}}
    </mat-error>
  </mat-form-field>
</div>
