import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'c-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent {
  @Input() public path: string;
  @Input() public widthStr = 'auto';
  @Input() public heightStr = 'auto';
  @Input() public alt: string;
}
