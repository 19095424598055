import { TripEditorLeg } from '../entities';
import { isReadyForDirectionRequest } from './is-ready-for-direction-request';
import { resetDateAndTimeForStopsStartingFrom } from './reset-date-and-time-for-stops-starting-from';

export const removeTripEditorStop = (leg: TripEditorLeg, stopId: string): TripEditorLeg => {
  const stopToRemove = leg.stops[stopId];
  const updatedStops = resetDateAndTimeForStopsStartingFrom(leg.stops, stopToRemove.stopIndex);
  delete updatedStops[stopId];

  const newLeg: TripEditorLeg = {
    legName: leg.legName,
    isReturnLeg: leg.isReturnLeg,
    isReadyForDirectionRequest: undefined,
    summary: { isLoading: false },
    stops: {},
  };
  const stopSequence = Object.values(updatedStops).sort((a, b) => a.stopIndex - b.stopIndex);
  stopSequence.forEach((stop, index) => {
    if (stop.isFirst) {
      newLeg.stops[stop.stopId] = stop;
      return;
    }
    newLeg.stops[stop.stopId] = {
      ...stop,
      stopIndex: index,
    };
  });
  newLeg.isReadyForDirectionRequest = isReadyForDirectionRequest(newLeg);

  return newLeg;
};
