import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { ShortWeekdayPipe } from './short-weekday.pipe';
import { DisplayQuantityPipe } from './display-quantity.pipe';
import { DisplayYYYYMMDDPipe } from './display-yyyymmdd.pipe';
import { DisplayTimeNumberPipe } from './display-time-number.pipe';
import { JoinPipe } from './join.pipe';
import { DisplayHHMMTimeStringPipe } from './display-hhmm-time-string.pipe';
import { PhoneFormatPipe } from './phone-format.pipe';
import { MillisDisplayDurationPipe } from './millis-display-duration.pipe';
import { UtcTimeFromNowPipe } from './utc-time-from-now.pipe';
import { DisplayDateTimeToUtcPipe } from './display-date-time-to-utc.pipe';
import { DisplayEntityTypePipe } from './display-entity-type.pipe';
import { DisplayYyyymmddMultiPipe } from './display-yyyymmdd-multi.pipe';
import { UtcStampToLocalDisplayDateTimePipe } from './utc-stamp-to-local-display-date-time.pipe';
import { UtcStampToLocalDisplayTimePipe } from './utc-stamp-to-local-display-time.pipe';
import { UtcStampToLocalDisplayDatePipe } from './utc-stamp-to-local-display-date.pipe';
import { CentsToDollarsPipe } from './cents-to-dollars.pipe';
import { DisplayArrayPipe } from './display-array.pipe';
import { FirstNSymbolsPipe } from './first-n-symbols.pipe';
import { PluralNounPipe } from './plural-noun.pipe';
import { DisplayDateRangePipe } from './display-date-range.pipe';
import { DurationHrsMinPipe } from './duration-hrs-min.pipe';
import { DistanceMilesPipe } from './distance-miles.pipe';
import { DisplayUnderscoreStringPipe } from './display-underscore-string.pipe';
import { DisplayRegionPipe } from './display-region.pipe';
import { DisplayEntityLabelPipe } from './display-entity-label.pipe';
import { DisplayCatalogLabelPipe } from './display-catalog-label.pipe';
import { DisplayCatalogOptionLabelPipe } from './display-catalog-option-label.pipe';
import { DisplayVehicleNamePipe } from './display-vehicle-name.pipe';
import { DisplayCharterRideDirectionPipe } from './display-charter-ride-direction.pipe';
import { LegStartDatePipe } from './leg-start-date.pipe';
import { PageItemDisplayNumberPipe } from './page-item-display-number.pipe';

const pipes = [
  ShortWeekdayPipe,
  DisplayDateTimeToUtcPipe,
  DisplayQuantityPipe,
  DisplayYYYYMMDDPipe,
  DisplayEntityTypePipe,
  DisplayTimeNumberPipe,
  JoinPipe,
  PhoneFormatPipe,
  DisplayHHMMTimeStringPipe,
  MillisDisplayDurationPipe,
  UtcTimeFromNowPipe,
  DisplayYyyymmddMultiPipe,
  UtcStampToLocalDisplayDateTimePipe,
  UtcStampToLocalDisplayDatePipe,
  UtcStampToLocalDisplayTimePipe,
  CentsToDollarsPipe,
  DisplayArrayPipe,
  FirstNSymbolsPipe,
  PluralNounPipe,
  DisplayDateRangePipe,
  DurationHrsMinPipe,
  DistanceMilesPipe,
  DisplayUnderscoreStringPipe,
  DisplayRegionPipe,
  DisplayEntityLabelPipe,
  DisplayVehicleNamePipe,
  DisplayCharterRideDirectionPipe,
];

@NgModule({
  declarations: [
    ...pipes,
    DisplayCatalogLabelPipe,
    DisplayCatalogOptionLabelPipe,
    LegStartDatePipe,
    PageItemDisplayNumberPipe,
  ],
  imports: [CommonModule],
  exports: [
    ...pipes,
    DisplayCatalogLabelPipe,
    DisplayCatalogOptionLabelPipe,
    LegStartDatePipe,
    PageItemDisplayNumberPipe,
  ],
  providers: [CurrencyPipe],
})
export class PipesModule {}
