import { Injectable } from '@angular/core';

import { CharterRoute, NavigationRequest, RouterStateUrl } from '../types';
import { createTripPath } from './create-trip-path';
import { QueryParamsHandling } from '@angular/router';
import { RouterService } from '../router-service';

export type CreateTripReviewRouteData = {
  tripItineraryId: string;
};

const routePath = 'review';

export const createTripReviewRoute: CharterRoute<CreateTripReviewRouteData> = {
  path: `${routePath}/:tripItineraryId`,
  isOnRoute(state: RouterStateUrl): boolean {
    return state.url.startsWith(`/${createTripPath}/${routePath}`);
  },
  parse(state: RouterStateUrl): CreateTripReviewRouteData {
    return {
      tripItineraryId: state.params.tripItineraryId,
    };
  },
  request(
    data: CreateTripReviewRouteData,
    queryParamStrategy?: QueryParamsHandling,
    replaceUrl?: boolean,
  ): NavigationRequest {
    return {
      path: [`/${createTripPath}`, routePath, data.tripItineraryId],
    };
  },
  getHref(data: CreateTripReviewRouteData): string {
    return `/${createTripPath}/${routePath}/${data.tripItineraryId}`;
  },
};

@Injectable({ providedIn: 'root' })
export class CreateTripReviewRouterService extends RouterService<CreateTripReviewRouteData> {
  route = createTripReviewRoute;
}
