import { Pipe, PipeTransform } from '@angular/core';
import { PortalEntityType } from '@rootTypes';

@Pipe({
  name: 'displayEntityType',
})
export class DisplayEntityTypePipe implements PipeTransform {
  transform(value: PortalEntityType): string {
    switch (value) {
      case PortalEntityType.CAMPUS:
        return 'Campus';
      case PortalEntityType.DISTRICT:
        return 'District';
      case PortalEntityType.PARENT:
        return 'Parent';
      case PortalEntityType.DRIVER:
        return 'Driver';
      case PortalEntityType.STUDENT:
        return 'Student';
      case PortalEntityType.SCHOOL:
        return 'School';
      case PortalEntityType.VENDOR:
        return 'Vendor';
      case PortalEntityType.ROUTE:
        return 'Route';
      case PortalEntityType.VEHICLE:
        return 'Vehicle';
      case PortalEntityType.RIDE:
        return 'Ride';
      case PortalEntityType.ZUM_EMPLOYEE:
        return 'Zum employee';
      case PortalEntityType.VENDOR_EMPLOYEE:
        return 'Vendor employee';
      case PortalEntityType.SCHOOL_EMPLOYEE:
        return 'Employee';
      case PortalEntityType.ROUTE_GROUP:
        return 'Route group';
      case PortalEntityType.CHARTER_TRIPS:
        return 'Charter trip';
      default: {
        console.warn('Unknown entity: ', value);
        return null;
      }
    }
  }
}
