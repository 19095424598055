import { PortalEntityType, SearchByRequestType } from '@rootTypes';
import { EntitySearchRequest } from '@rootTypes/api';

export const getSearchRequest = (
  query: string,
  types: PortalEntityType[],
  skip: number,
  limit: number,
): EntitySearchRequest => {
  const requestTypes = types.map((s) => portalTypeToSearchType(s));
  return {
    query,
    types: [...requestTypes],
    skip,
    limit,
  };
};

function portalTypeToSearchType(source: PortalEntityType): SearchByRequestType {
  switch (source) {
    case PortalEntityType.CAMPUS:
      return SearchByRequestType.CAMPUS;
    case PortalEntityType.DISTRICT:
      return SearchByRequestType.DISTRICT;
    case PortalEntityType.DRIVER:
      return SearchByRequestType.DRIVER;
    case PortalEntityType.PARENT:
      return SearchByRequestType.CUSTOMER;
    case PortalEntityType.SCHOOL:
      return SearchByRequestType.CAMPUS;
    case PortalEntityType.STUDENT:
      return SearchByRequestType.RIDER;
    case PortalEntityType.VENDOR:
      return SearchByRequestType.VENDOR;
    case PortalEntityType.VEHICLE:
      return SearchByRequestType.VEHICLE;
    case PortalEntityType.ZUM_EMPLOYEE:
      return SearchByRequestType.ZUM_EMPLOYEE;
    case PortalEntityType.VENDOR_EMPLOYEE:
      return SearchByRequestType.VENDOR_EMPLOYEE;
    case PortalEntityType.SCHOOL_EMPLOYEE:
      return SearchByRequestType.SCHOOL_EMPLOYEE;
    case PortalEntityType.CHARTER_TRIPS:
      return SearchByRequestType.CHARTER_TRIP;

    default: {
      console.log('Cannot search by this type: ', source);
      return null;
    }
  }
}
