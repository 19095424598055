import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  Renderer2,
  ViewChild,
} from '@angular/core';

// https://github.com/jackyr/multi-clamp
import MultiClamp from '../multi-clamp';

@Component({
  selector: 'wp-multi-clamp',
  styleUrls: ['./multi-clamp.component.scss'],
  template: `<div #clampElement class="clamp-el"></div>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiClampComponent implements AfterViewInit, OnChanges {
  private clampBox: MultiClamp;

  @Input() public sourceText: string;
  @Input() public expandedText: string;
  @Input() public collapsedText: string;
  @Input() public clamp = 2;

  @ViewChild('clampElement') clampElement?: ElementRef<HTMLElement>;

  constructor(private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    this.initClamp();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.sourceText && !changes.sourceText.isFirstChange()) {
      this.initClamp();
    }
  }

  private initClamp(): void {
    const element = this.clampElement.nativeElement;
    element.innerText = this.sourceText;
    this.clampBox = new MultiClamp(element, {
      splitByWords: true,
      clamp: this.clamp,
      ellipsis: this.createReadMoreBtn((event) => {
        event.stopPropagation();
        this.clampBox.reload({
          clamp: 'auto',
          useOriginalText: true,
        });
        this.createHideBtn();
      }),
    });
  }

  private createReadMoreBtn(clickHandler): HTMLElement {
    const readMoreButton = this.renderer.createElement('span');
    const readMoreTextButton = this.renderer.createText(this.expandedText || 'Read more');
    this.renderer.appendChild(readMoreButton, readMoreTextButton);
    this.renderer.addClass(readMoreButton, 'link-btn');
    readMoreButton.addEventListener('click', clickHandler, false);

    const ellipsisBlock = this.renderer.createElement('span');
    this.renderer.appendChild(ellipsisBlock, this.ellipsisElement());
    this.renderer.appendChild(ellipsisBlock, readMoreButton);
    return ellipsisBlock;
  }

  private createHideBtn(): void {
    const hideButton = this.renderer.createElement('div') as HTMLElement;
    const hideButtonText = this.renderer.createText(this.collapsedText || 'Read less');
    this.renderer.appendChild(hideButton, hideButtonText);
    this.renderer.addClass(hideButton, 'link-btn');
    hideButton.addEventListener('click', (event) => {
      event.stopPropagation();
      this.initClamp();
    });
    this.renderer.appendChild(this.clampElement.nativeElement, hideButton);
  }

  private ellipsisElement(): HTMLElement {
    const ellipsisButton = this.renderer.createElement('span');
    const ellipsisTextButton = this.renderer.createText('...');
    this.renderer.addClass(ellipsisButton, 'ellipsis');
    this.renderer.appendChild(ellipsisButton, ellipsisTextButton);
    return ellipsisButton;
  }
}
