import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';

import { FormControlsModule } from '../form-controls/form-controls.module';
import { ComponentsModule } from '../components/components.module';
import { DirectivesModule } from '../directives/directives.module';
import { IconCloseModule } from '../icons/icon-close/icon-close.module';
import { EntityFilterModule } from '../entity-filter/entity-filter.module';

import { SmartInputTextComponent } from './components/smart-input-text/smart-input-text.component';
import { SmartInputEmailComponent } from './components/smart-input-email/smart-input-email.component';
import { SmartInputPhoneComponent } from './components/smart-input-phone/smart-input-phone.component';
import { SmartInputAddressComponent } from './components/smart-input-address/smart-input-address.component';
import { SmartInputNumberComponent } from './components/smart-input-number/smart-input-number.component';
import { SmartTextareaComponent } from './components/smart-textarea/smart-textarea.component';
import { SmartInputDateComponent } from './components/smart-input-date/smart-input-date.component';
import { SmartInputDateRangeComponent } from './components/smart-input-date-range/smart-input-date-range.component';
import { SmartInputDateRangeFromComponent } from './components/smart-input-date-range-from/smart-input-date-range-from.component';
import { SmartInputDateRangeToComponent } from './components/smart-input-date-range-to/smart-input-date-range-to.component';
import { SmartCheckboxComponent } from './components/smart-checkbox/smart-checkbox.component';
import { SmartAutocompleteComponent } from './components/smart-autocomplete/smart-autocomplete.component';
import { SmartInputTimeComponent } from './components/smart-input-time/smart-input-time.component';
import { SmartSelectComponent } from './components/smart-select/smart-select.component';
import { SmartInputTimepickerComponent } from './components/smart-input-timepicker/smart-input-timepicker.component';
import { SmartInputCurrencyComponent } from './components/smart-input-currency/smart-input-currency.component';
import { SmartInputSearchComponent } from './components/smart-input-search/smart-input-search.component';
import { SmartAutocompleteTextComponent } from './components/smart-autocomplete-text/smart-autocomplete-text.component';

@NgModule({
  declarations: [
    SmartInputTextComponent,
    SmartInputEmailComponent,
    SmartInputPhoneComponent,
    SmartInputAddressComponent,
    SmartInputNumberComponent,
    SmartTextareaComponent,
    SmartInputDateComponent,
    SmartInputDateRangeComponent,
    SmartInputDateRangeFromComponent,
    SmartInputDateRangeToComponent,
    SmartCheckboxComponent,
    SmartAutocompleteComponent,
    SmartInputTimeComponent,
    SmartSelectComponent,
    SmartInputTimepickerComponent,
    SmartInputCurrencyComponent,
    SmartInputSearchComponent,
    SmartAutocompleteTextComponent,
  ],
  imports: [
    CommonModule,
    FormControlsModule,
    ComponentsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatSelectModule,
    DirectivesModule,
    IconCloseModule,
    EntityFilterModule,
  ],
  exports: [
    SmartInputTextComponent,
    SmartInputEmailComponent,
    SmartInputPhoneComponent,
    SmartInputAddressComponent,
    SmartInputNumberComponent,
    SmartTextareaComponent,
    SmartInputDateComponent,
    SmartInputDateRangeComponent,
    SmartInputDateRangeFromComponent,
    SmartInputDateRangeToComponent,
    SmartCheckboxComponent,
    SmartAutocompleteComponent,
    SmartInputTimeComponent,
    SmartSelectComponent,
    SmartInputTimepickerComponent,
    SmartInputCurrencyComponent,
    SmartInputSearchComponent,
    SmartAutocompleteTextComponent,
  ],
})
export class SmartFormsModule {}
