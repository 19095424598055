<div class="timepicker">
  <mat-form-field [hideRequiredMarker]="true" class="timepicker-form-field">
    <div matSuffix>
      <i
        class="timepicker-x"
        *ngIf="displayControl.value && !displayControl.disabled"
        (click)="onReset($event)"
      >
      </i>
    </div>

    <mat-label>{{label}}</mat-label>
    <input
      #timeInput
      type="text"
      matInput
      name="time-input"
      autocomplete="off"
      [formControl]="displayControl"
      [matAutocomplete]="autocomplete"
      (blur)="onBlur()"
    />
    <mat-autocomplete
      #autocomplete="matAutocomplete"
      (optionSelected)="onOptionSelected()"
    >
      <mat-option *ngFor="let opt of suggestedOptions" [value]="opt.time12">
        <div class="option">{{opt.time12}}</div>
      </mat-option>
    </mat-autocomplete>

    <mat-error *ngIf="displayControl.hasError('required')">
      Required field
    </mat-error>
    <mat-error
      *ngIf="customErrorMessageKey && displayControl.hasError(customErrorMessageKey)"
    >
      {{displayControl.getError(customErrorMessageKey)}}
    </mat-error>
  </mat-form-field>
</div>
