import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest, of, Subject } from 'rxjs';
import * as fromTypes from './types';
import { RangePickerDay } from './types/entities/range-picker-day';
import { map, distinctUntilChanged, shareReplay } from 'rxjs/operators';
import * as moment from 'moment-timezone';
import { DateRange } from '@rootTypes';
import { RangePickerState } from './types';
import { getWeekForMoment } from './types/utils/get-week-for-moment';
import { getWeekType } from '@rootTypes/utils/common/date/get-week-type';

@Injectable()
export class DateRangePickerService implements fromTypes.RangePickerStore {
  private hoveredDate$!: Observable<moment.Moment | null>;
  private startDateSelected$!: Observable<moment.Moment | null>;
  private endDateSelected$!: Observable<moment.Moment | null>;
  private userSelectedDateRange$$ = new Subject<DateRange>();

  private state$: BehaviorSubject<fromTypes.RangePickerState> = new BehaviorSubject<fromTypes.RangePickerState>(
    fromTypes.initialRangePickerStore,
  );

  constructor() {
    this.hoveredDate$ = this.state$.asObservable().pipe(
      map((state) => state.hovered),
      distinctUntilChanged((prev, curr) => prev.updatedAt === curr.updatedAt),
      map((state) => state.date),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.startDateSelected$ = this.state$.asObservable().pipe(
      map((state) => state.selected),
      distinctUntilChanged((prev, curr) => prev.updatedAt === curr.updatedAt),
      map((state) => state.startDate),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.endDateSelected$ = this.state$.asObservable().pipe(
      map((state) => state.selected),
      distinctUntilChanged((prev, curr) => prev.updatedAt === curr.updatedAt),
      map((state) => state.endDate),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    // this.state$.asObservable()
    //     .subscribe(val => {
    //       console.log('Store value:');
    //       console.log(val.currentView.date.format('MMMM YYYY'));
    //     })
  }
  selectedChanged$(): Observable<{
    startDate: moment.Moment | null;
    endDate: moment.Moment | null;
  }> {
    return this.state$.asObservable().pipe(
      map((state) => state.selected),
      distinctUntilChanged(
        (prev: fromTypes.RangePickerState['selected'], curr: fromTypes.RangePickerState['selected']) =>
          prev.updatedAt === curr.updatedAt,
      ),
    );
  }

  dayClicked(day: fromTypes.RangePickerDay): void {
    const state = this.state$.value;
    const prevStartDate = state.selected.startDate;
    const prevEndDate = state.selected.endDate;
    const clickedDate = day.moment;
    const newState: {
      startDate: moment.Moment | null;
      endDate: moment.Moment | null;
    } = { startDate: null, endDate: null };
    if (!prevStartDate && !prevEndDate) {
      newState.startDate = clickedDate;
    }
    if (prevStartDate && !prevEndDate) {
      if (clickedDate.isAfter(prevStartDate, 'day')) {
        newState.startDate = prevStartDate;
        newState.endDate = clickedDate;
      } else if (clickedDate.isBefore(prevStartDate, 'day')) {
        newState.startDate = clickedDate;
        newState.endDate = prevStartDate;
      }
    }
    if (prevStartDate && prevEndDate) {
      // reset range
      newState.startDate = clickedDate;

      // if (clickedDate.isBefore(prevEndDate, 'day')) {

      //   // click inside the selected range - reset selection to start from this date
      //   // if (clickedDate.isAfter(prevStartDate, 'day')) {

      //   // } else {
      //   //   newState.startDate = clickedDate;
      //   //   newState.endDate = prevEndDate;
      //   // }
      // }
      // if (clickedDate.isAfter(prevEndDate, 'day')) {
      //   newState.startDate = prevStartDate;
      //   newState.endDate = clickedDate;
      // }
    }
    this.state$.next({
      ...state,
      selected: {
        ...state.selected,
        startDate: newState.startDate,
        endDate: newState.endDate,
        updatedAt: new Date().getTime(),
      },
    });
    if (newState.startDate && newState.endDate) {
      this.userSelectedDateRange$$.next({
        startDate: newState.startDate.format('YYYYMMDD'),
        endDate: newState.endDate.format('YYYYMMDD'),
      });
    } else if (newState.startDate) {
      this.userSelectedDateRange$$.next(undefined);
      this.userSelectedDateRange$$.next({
        startDate: newState.startDate.format('YYYYMMDD'),
        endDate: newState.startDate.format('YYYYMMDD'),
      });
    } else {
      this.userSelectedDateRange$$.next(undefined);
    }
  }

  weekdays$(): Observable<fromTypes.RangePickerWeekday[]> {
    const isStartMonday$ = this.state$.pipe(
      map((state) => state.config.entity.isStartWeekFromMonday),
      distinctUntilChanged(),
    );
    return isStartMonday$.pipe(
      map((isStartMonday) => {
        return getWeekForMoment(moment(), isStartMonday).map((weekdayMom) => {
          return {
            moment: weekdayMom,
            label: fromTypes.utils.getMomentWeekdayLabel(weekdayMom),
          };
        });
      }),
    );
  }

  public forward(): void {
    const state = this.state$.value;
    const currentMonth = state.currentView.date;
    const nextMonth = moment(moment(currentMonth).add(1, 'month'));
    this.state$.next({
      ...state,
      currentView: {
        ...state.currentView,
        date: nextMonth,
        updatedAt: new Date().getTime(),
      },
    });
  }
  public backward(): void {
    const state = this.state$.value;
    const currentMonth = state.currentView.date;
    const prevMonth = moment(moment(currentMonth).subtract(1, 'month'));
    this.state$.next({
      ...state,
      currentView: {
        ...state.currentView,
        date: prevMonth,
        updatedAt: new Date().getTime(),
      },
    });
  }
  public months$(): Observable<fromTypes.RangePickerMonth[]> {
    const numMonthsDisplayed$ = this.state$.asObservable().pipe(
      map((state) => state.config),
      distinctUntilChanged((prev, curr) => prev.updatedAt === curr.updatedAt),
      map((state) => state.entity.numMonthsDisplayed),
    );
    const currentMonth$ = this.state$.asObservable().pipe(
      map((state) => state.currentView),
      distinctUntilChanged((prev, curr) => prev.updatedAt === curr.updatedAt),
      map((state) => state.date),
    );
    return combineLatest([currentMonth$, numMonthsDisplayed$]).pipe(
      map(([currMonth, numDisplayed]) => {
        const result = [] as fromTypes.RangePickerMonth[];
        for (let i = 0; i < numDisplayed; i++) {
          const m = moment(moment(currMonth).add(i, 'month'));
          result.push({
            moment: m,
            label: fromTypes.utils.getMomentMonthLabel(m, true),
          });
        }
        return result;
      }),
    );
  }

  public daysForMonth$(month: moment.Moment): Observable<RangePickerDay[]> {
    const startOfMonth = moment(month).startOf('month');
    const endOfMonth = moment(month).endOf('month');
    const isStartMonday$ = this.state$.pipe(
      map((state) => state.config.entity.isStartWeekFromMonday),
      distinctUntilChanged(),
    );
    return isStartMonday$.pipe(
      map((isStartMonday) => {
        const weekType = getWeekType(isStartMonday);
        const startOfWeek = moment(startOfMonth).startOf(weekType);
        const endOfWeek = moment(endOfMonth).endOf(weekType);
        const result = [] as RangePickerDay[];
        for (let m = moment(startOfWeek); m.isSameOrBefore(endOfWeek); m = moment(m).add(1, 'day')) {
          const curr = moment(m);
          const day = {
            moment: curr,
            isDisplayed: month.month() === m.month(),
            label: fromTypes.utils.getMomentDayLabel(curr),
          } as RangePickerDay;
          result.push(day);
        }
        return result;
      }),
    );
  }

  public dayChanges$(day: fromTypes.RangePickerDay): Observable<RangePickerDay> {
    return combineLatest([this.hoveredDate$, this.startDateSelected$, this.endDateSelected$]).pipe(
      map(([hovered, start, end]) => {
        return {
          ...day,
          css: this.getCssForDate(day.moment, hovered, start, end),
        };
      }),
    );
  }

  public dateRangeSelectedByUser(): Observable<DateRange> {
    return this.userSelectedDateRange$$.asObservable();
  }

  public setCurrentMonth(m: moment.Moment): void {
    this.state$.next({
      ...this.state$.value,
      currentView: {
        ...this.state$.value.currentView,
        date: m,
        updatedAt: new Date().getTime(),
      },
    });
  }

  public setHovered(m: moment.Moment | null): void {
    const state = this.state$.value;
    this.state$.next({
      ...state,
      hovered: {
        ...state.hovered,
        date: m,
        updatedAt: new Date().getTime(),
      },
    });
  }

  public setStartWeekFromMonday(isStartFromMonday: boolean): void {
    const state = this.state$.value;
    const newState: RangePickerState = {
      ...state,
      config: {
        ...state.config,
        entity: {
          ...state.config.entity,
          isStartWeekFromMonday: isStartFromMonday,
        },
      },
    };
    this.state$.next(newState);
  }

  public setStartDate(m: moment.Moment | null): void {
    const state = this.state$.value;
    const newState = {
      ...state,
      selected: {
        ...state.selected,
        startDate: m,
        updatedAt: new Date().getTime(),
      },
    };
    this.state$.next(newState);
  }

  public setEndDate(m: moment.Moment | null): void {
    const state = this.state$.value;
    this.state$.next({
      ...state,
      selected: {
        ...state.selected,
        endDate: m,
        updatedAt: new Date().getTime(),
      },
    });
  }

  public setMonthView(month: number): void {
    const state = this.state$.value;
    this.state$.next({
      ...state,
      config: {
        ...state.config,
        entity: { ...state.config.entity, numMonthsDisplayed: month },
      },
    });
  }

  public setReadonly(): void {
    const state = this.state$.value;
    this.state$.next({
      ...state,
      config: {
        ...state.config,
        readonly: true,
      },
    });
  }

  public initStore(): void {
    this.state$.next(fromTypes.initialRangePickerStore);
  }

  public isReadOnly(): Observable<boolean> {
    return this.state$.asObservable().pipe(map((state) => state.config.readonly));
  }

  private getCssForDate(
    current: moment.Moment,
    hovered: moment.Moment | null,
    start: moment.Moment | null,
    end: moment.Moment | null,
  ): string {
    let css = '';
    if (hovered && current.isSame(hovered, 'day')) {
      css += 'hovered';
    }
    if (start && current.isSame(start, 'day')) {
      css += ' selected';
    }
    if (end && current.isSame(end, 'day')) {
      css += ' selected';
    }
    const today = moment();
    if (today.isSame(current, 'day')) {
      css += ' today';
    }
    if (start && (end || hovered)) {
      if (current.isAfter(start, 'day') && current.isBefore(end || hovered, 'day')) {
        css += ' in-range';
      } else if (!end && hovered && current.isAfter(hovered, 'day') && current.isBefore(start, 'day')) {
        css += ' in-range';
      }
    }
    return css;
  }
}
