import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { AbstractPopupComponent, PopupRef, WpError } from '@rootTypes';
import { ErrorStatements } from '../error-parser.service';

export interface ErrorPopupData {
  errorStatements: ErrorStatements;
  error: WpError;
  hideErrorHeader: boolean;
  confirmBtnText?: string;
}

@Component({
  selector: 'c-error-popup',
  templateUrl: './error-popup.component.html',
  styleUrls: ['./error-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorPopupComponent implements OnInit, AbstractPopupComponent<ErrorPopupData, void> {
  public popupRef: PopupRef<ErrorPopupData, void>;
  public errorStatements: ErrorStatements;
  public errorToCopy: string;
  public confirmButtonText: string;
  public hideErrorHeader: boolean;
  constructor() {}

  public ngOnInit(): void {
    this.errorStatements = this.popupRef.data.errorStatements;
    this.hideErrorHeader = this.popupRef.data.hideErrorHeader;
    this.errorToCopy = JSON.stringify(this.popupRef.data.error);
    this.confirmButtonText = this.popupRef.data.confirmBtnText;
  }

  public close(): void {
    this.popupRef.close();
  }
}
