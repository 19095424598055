<wp-dropdown
  [triggerTemplate]="triggerTemplate"
  [dropdownTemplate]="dropdownTemplate"
  [alignDropdown]="alignDropdown"
  [dropdownWidthPx]="dropdownWidthPx"
  (closedDropdown)="onDropdownClose()"
></wp-dropdown>

<ng-template #triggerTemplate>
  <div
    class="quick-filter-trigger"
    [ngClass]="{
    'selected': isSelected,
    'open': isDropdownOpen,
    'disabled': disabled
  }"
    (click)="onTriggerClick()"
  >
    <div class="icon">
      <wp-portal-icon [path]="iconPaths.FILTER_GREEN"></wp-portal-icon>
    </div>
    <div class="label">{{ label }}</div>
  </div>
</ng-template>

<ng-template #dropdownTemplate>
  <div class="dropdown-wrap">
    <wp-dropdown-pane>
      <wp-dropdown-option
        *ngFor="let option of options"
        (click)="onOptionClick(option.value)"
      >
        <div class="option">
          <div class="option-label">{{ option.displayLabel }}</div>
          <div class="checked-icon">
            <wp-portal-icon
              *ngIf="selectedId === option.value"
              [path]="iconPaths.CHECK_SMALL_STROKE"
            ></wp-portal-icon>
          </div>
        </div>
      </wp-dropdown-option>
    </wp-dropdown-pane>
  </div>
</ng-template>
