import { Pipe, PipeTransform } from '@angular/core';

const pluralizations = {
  'transit bus': 'transit buses',
};

@Pipe({
  name: 'displayQuantity',
})
export class DisplayQuantityPipe implements PipeTransform {
  transform(value: number, entityName: string, customPluralForm?: string): string {
    if (entityName === undefined || value === undefined || value === null) {
      return '-';
    }
    const singular = entityName;
    const plural = customPluralForm
      ? customPluralForm
      : pluralizations[entityName]
      ? pluralizations[entityName]
      : `${entityName}s`;
    if (value === 1) {
      return `1 ${singular}`;
    }
    return `${value} ${plural}`;
  }
}
