enum EventKeyCodes {
  ARROW_DOWN = 40,
  ARROW_UP = 38,
  ENTER = 13,
}

enum EventKeys {
  ARROW_DOWN = 'ArrowDown',
  ARROW_UP = 'ArrowUp',
  ENTER = 'Enter',
}

export const isArrowDownEvent = (clickEvent: KeyboardEvent): boolean => {
  if (clickEvent.key) {
    return clickEvent.key === EventKeys.ARROW_DOWN;
  }
  if (clickEvent.keyCode) {
    return clickEvent.keyCode === EventKeyCodes.ARROW_DOWN;
  }
  return false;
};

export const isArrowUpEvent = (clickEvent: KeyboardEvent): boolean => {
  if (clickEvent.key) {
    return clickEvent.key === EventKeys.ARROW_UP;
  }
  if (clickEvent.keyCode) {
    return clickEvent.keyCode === EventKeyCodes.ARROW_UP;
  }
  return false;
};

export const isEnterEvent = (clickEvent: KeyboardEvent): boolean => {
  if (clickEvent.key) {
    return clickEvent.key === EventKeys.ENTER;
  }
  if (clickEvent.keyCode) {
    return clickEvent.keyCode === EventKeyCodes.ENTER;
  }
  return false;
};
