<div
  class="wp-btn-ghost"
  [ngClass]="{ disabled: disabled }"
  [ngStyle]="btnSize()"
  (click)="onClick()"
>
  <div class="ghost-btn-text">
    <ng-content> </ng-content>
  </div>
</div>
