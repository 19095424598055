import { BaseDate, TimeFormat, TimezoneString } from './base-date';
import * as moment from 'moment-timezone';
import { YYYYMMDDString } from './year-month-day';

/**
 * Regular UTC timestamp
 */
export type UTCTimestamp = number;

export class UTCTime extends BaseDate {
  private tz: TimezoneString;

  constructor(value: UTCTimestamp, tz?: TimezoneString) {
    super(value, TimeFormat.UTC_TIMESTAMP);
    this.tz = tz || moment.tz.guess();
  }

  toMoment(tz: string = this.tz): moment.Moment {
    return moment(this.value).tz(this.tz);
  }

  public getDisplayTimeStr(format = 'h:mm A z'): string {
    return this.toMoment().format(format);
  }

  public getHHMMString(): string {
    return this.toMoment().format('HHmm');
  }

  public getDisplayDateStr(format = 'MMM D, YYYY'): string {
    return this.toMoment().format(format);
  }

  public getDisplayWeekdayAndDateStr(format = 'ddd MMM D, YYYY'): string {
    return this.toMoment().format(format);
  }

  public getDisplayWeekdayDateAndTimeStr(format = 'ddd MMM D, YYYY h:mm A'): string {
    return this.toMoment().format(format);
  }

  getYYYYMMDDString(): YYYYMMDDString {
    return this.toMoment().format('YYYYMMDD');
  }

  formatTo(pattern: string): string {
    return this.toMoment().format(pattern);
  }
}
