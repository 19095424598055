import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';

@Component({
  selector: 'wp-ellipsis',
  templateUrl: './ellipsis.component.html',
  styleUrls: ['./ellipsis.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EllipsisComponent implements AfterViewInit, OnChanges {
  @Input() public tooltipText: string;
  @Input() public tooltipPosition: TooltipPosition = 'above';
  @Input() public textAlign = 'auto';
  @ViewChild('ellipsisWrap') public ellipsisWrap: ElementRef;
  public isTooltip: boolean;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tooltipText && !!this.tooltipText && !changes.tooltipText.isFirstChange()) {
      this.init();
    }
  }

  ngAfterViewInit(): void {
    this.init();
  }

  init() {
    const textEl = this.ellipsisWrap.nativeElement as HTMLDivElement;
    textEl.style.width = '100%';
    setTimeout(() => {
      const actualWidth = textEl.clientWidth;
      const offsetWidth = textEl.offsetWidth;
      const scrollWidth = textEl.scrollWidth;
      setTimeout(() => {
        this.isTooltip = offsetWidth < scrollWidth;
        textEl.style.width = `${actualWidth + 1}px`;
        this.cd.detectChanges();
      }, 0);
    }, 0);
  }
}
